
import { throwError as observableThrowError, of as observableOf } from 'rxjs';
import { EventEmitter, Injectable } from '@angular/core';
import { Site } from '../models/site.model';
import { BaseDjangoService } from './base-django.service';
import { AuthenticationService } from './authentication.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SiteService extends BaseDjangoService {

  siteId: string;
  siteDefaults: Site;
  siteDefaultsUpdated: EventEmitter<Site> = new EventEmitter();
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) {
    super(authenticationService);
    this.apiUrl += '/site/';

  }

  setSiteId(site_id: string): void {
    this.siteId = site_id;
  }

  getSite(site_name: string): Promise<Site> {

    if (!this.siteDefaults) {
      const options = this.getOptions();

      return this.http.get(this.apiUrl, options)
        .toPromise()
        .then((res) => {
          const sites = res as Site[];
          if (sites && sites.length > 0) {
            this.siteDefaults = sites.find(s => s.site_name === site_name);
          } else {
            this.siteDefaults = new Site;
          }
          return this.siteDefaults;
        });
    } else {
      return observableOf(this.siteDefaults).toPromise();
    }

  }

  getSiteById(): Promise<Site> {
    const id = this.siteId;
    if (!this.siteDefaults) {
      if (this.siteId) {

        const options = this.getOptions();

        return this.http.get(this.apiUrl + id, options)
          .toPromise()
          .then((res) => {
            const sites = res as Site[];
            if (sites && sites.length > 0) {
              this.siteDefaults = sites[0];
            } else {
              this.siteDefaults = new Site;
            }
            this.siteDefaultsUpdated.emit(this.siteDefaults);
            return this.siteDefaults;
          })
          .catch((err) => observableThrowError(err).toPromise());
      } else {
        return observableThrowError('no site id').toPromise();
      }

    } else {
      return observableOf(this.siteDefaults).toPromise();
    }

  }
}
