import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QueueService } from './queue.service';
import { PollService } from './poll/poll.service';
import { SocketModule } from './socket/socket.module';
import { PollModule } from './poll/poll.module';

@NgModule({
  imports: [
    CommonModule,
    PollModule,
    SocketModule
  ],
  declarations: [],
  providers: [
    { provide: QueueService, useClass: PollService }
  ]
})
export class QueueModule { }
