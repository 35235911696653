import { Component, Inject, OnInit } from '@angular/core';
import { OperatorService } from '../core/services/operator.service';
import { TokenService } from '../core/services/token.service';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StorageService } from 'app/core/services/storage.service';

export interface DialogData {
  status: boolean;
  timeLeft: number;
  showCountDown: true;
  count: number;
  maxRetries: number;
}

@Component({
  selector: 'app-offline-popup',
  templateUrl: './offline-popup.component.html',
  styleUrls: ['./offline-popup.component.css']
})
export class OfflinePopupComponent {

  constructor(
    private operatorService: OperatorService,
    private tokenService: TokenService,
    private router: Router,
    private storageService : StorageService,
    public dialogRef: MatDialogRef<OfflinePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { 
  }

  // private logout(): void {
  //   event.preventDefault();
  //   this.operatorService.logout()
  //     .then(
  //       (res) => {
  //         if (res) {
  //           this.tokenService.remove();
  //           this.router.navigate(['login']);
  //           return;
  //         }
  //       })
  //     .catch((err) => {
  //       this.router.navigate(['login']);
  //     });
  // }
  //
  // onClick(event: any): void {
  //   this.logout()
  // }
  ngOnInit() {
    localStorage.clear();
  }
  
}

