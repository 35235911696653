import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { MessageService } from '../core/services/message.service';
import { OperatorService } from '../core/services/operator.service';
import { Stream } from '../core/models/stream.model';
import { ContactService } from '../core/services/contact.service';
import { Contact } from '../core/models/contact.model';
import { ContactForAllReq } from 'app/core/models/contactAllReq.model';
import { OperatorViewService } from '../operator/operator-view.service';
import { OperatorApi } from '../core/models/operator.model';
import { User } from '../shared/user.model';
import { UserService } from '../core/services/user.service';
import { HotelService } from '../core/services/hotel.service';
import { BaseComponent } from '../shared/base/base.component';
import { ContactExport } from '../core/shared/enum';
import { environment as env } from '../../environments/environment';
import { StreamsUpdated } from '../core/models/streams-updated.model';
import { Howl } from 'howler'
import * as moment from 'moment';
import { Subscription } from "rxjs";
import { Hotel } from '../core/models/hotel.model';
import { Router } from '@angular/router';
import { TokenService } from '../core/services/token.service';
import { HelperService } from "../shared/helper.service";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '../core/services/authentication.service';
import { QueueService } from 'app/core/queue/queue.service';
import { error } from 'console';
import { element } from 'protractor';
import { TabService } from 'app/core/services/tab.service';
import { AllReqStream } from 'app/core/models/all-req-stream.model';
import { StreamsUpdatedAllReq } from 'app/core/models/streams-updated-all-req.model';
import { PaginationService } from 'app/core/services/pagination.service';
import { OperatorLogin } from 'app/core/models/operator-login.model';
import { ToastyService } from 'ng2-toasty';
@Component({
  selector: 'app-message-queue',
  templateUrl: './message-queue.component.html',
  styleUrls: ['./message-queue.component.css']
})
export class MessageQueueComponent extends BaseComponent implements OnInit, OnDestroy {
  dateDiffval: number = env.dateDiffVal;
  loading: boolean;
  operators: OperatorApi[];
  currentStreamId: string;
  sound: Howl;
  hotels: Hotel[];
  currentHotel: Hotel;
  private subscriptions: Array<Subscription> = [];
  logoIconPath = '';
  apiUrl: string;
  _authenticationService: AuthenticationService;
  maskContactObject: any = {};
  streamLength: number;
  pendingRequest: boolean;
  tabKey: string = "My Request"
  replaySoundTimer: number = (5 * 60 * 1000); // replay sound after 5 minutes of not reponding
  @Input() streamItems: Stream[] = [];
  @Input() allReqStreams: AllReqStream[] = [];
  @Input() allCompletedReqStream: AllReqStream[] = []

  currentOrganization: Hotel;
  isLoading = false;
  page: number = 1;
  pageSize: number = 1;
  limit: number = 2;
  operatorLogin: OperatorLogin;
  loginOperatorData: any;
  loadingForAllReq: boolean = false;
  pendingForAllReq: boolean = false;

  // 10/12/24 - Vikas Gupta - A
  loadingForCompleteReq: boolean = false;
  pendingForCompleteReq: boolean = false;
  // 10/12/24 - Vikas Gupta - A



  constructor(
    private messageService: MessageService,
    private operatorService: OperatorService,
    contactService: ContactService,
    private operatorViewService: OperatorViewService,
    private hotelService: HotelService,
    private userService: UserService,
    private router: Router,
    private tokenService: TokenService,
    private helperService: HelperService,
    private http: HttpClient,
    private queueService: QueueService,
    authenticationService: AuthenticationService,
    private tabService: TabService,
    private paginationService: PaginationService,
    private toastyService: ToastyService,
  ) {
    super(contactService);
    this.apiUrl = env.api;
    this._authenticationService = authenticationService;
    this.onSaveLoginOperatorData();
  }

  private getHeaders(): HttpHeaders {
    const headers = {
      Accept: 'application/json'
    };

    const token = this._authenticationService.getToken();
    if (token) {
      headers['Authorization'] = token.token_type + ' ' + token.access_token;
    }
    return new HttpHeaders(headers);
  }

  getOptions(): object {
    const headers = this.getHeaders();
    return {
      headers: headers
    };
  }

  getCurrentOrganization(): void {
    this.subscriptions.push(
      this.hotelService.hotel$
        .subscribe(hotel => {
          this.currentOrganization = hotel;
        }, err => {
          console.log(err);
          console.log('user is not logged in, redirecting...');
          this.router.navigate(['login']);
        })
    );
  }

  setStreamId(id: string, version: string, contact_number: string, importantFlag?: boolean): void {
    // see if we can get the contact for the stream if it doesn't exist
    // console.log('setStreamId()');
    this.operatorViewService.setStreamId(id, version, importantFlag);
    this.operatorViewService.setShowMessage(true);
    this.currentStreamId = this.operatorViewService.currentStreamId;

    // console.log('#### => '+this.currentStreamId);

    const idx = this.getContactIndex(contact_number);
    if (idx > -1) {
      if (this.streamItems[idx].contact) {
        this.contactService.setCurrentContact(this.streamItems[idx].contact);
      } else {
        this.contactService.setCurrentContact(contact_number);
      }
    } else {
      this.contactService.setCurrentContact(contact_number);
    }
  }

  private getContactIndex(id: string, curr_index?: number): number {
    if (!id) {
      return -1;
    }
    if (curr_index == null) {
      curr_index = 0;
    }
    return this.streamItems.findIndex((s, idx) => s.source_channel_subscriber
      .resource_identifier.replace('tel://+', '')
      .replace('+', '') === id.replace('tel://+', '')
        .replace('+', '') && idx >= curr_index);
  }

  private getContactIndexForAllReq(id: string, curr_index?: number): number {
    if (!id) {
      return -1;
    }
    if (curr_index == null) {
      curr_index = 0;
    }
    return this.allReqStreams.findIndex((s, idx) => s.source_channel_subscriber
      .resource_identifier.replace('tel://+', '')
      .replace('+', '') === id.replace('tel://+', '')
        .replace('+', '') && idx >= curr_index);
  }


  // completed req tab - 10/12/24 - Vikas Gupta - A

  private getContactIndexForCompReq(id: string, curr_index?: number): number {
    if (!id) {
      return -1;
    }
    if (curr_index == null) {
      curr_index = 0;
    }
    return this.allCompletedReqStream.findIndex((s, idx) => s.source_channel_subscriber
      .resource_identifier.replace('tel://+', '')
      .replace('+', '') === id.replace('tel://+', '')
        .replace('+', '') && idx >= curr_index);
  }
  // completed req tab - 10/12/24 - Vikas Gupta - A
  

  private updateStream(contact: Contact): void {
    const ident = contact.channel_subscription.resource_identifier;
    if (!ident) {
      return;
    }
    // now let's get all occurences
    let idx = this.getContactIndex(ident);
    this.streamItems[idx].contact = contact;


    if (idx > -1) {
      while (idx < this.streamItems.length && idx !== -1) {
        this.streamItems[idx].contact = contact;
        idx = this.getContactIndex(ident, idx + 1);
      }
    }
  }
  private updateStreamForAllReq(contact: ContactForAllReq): void {
    const ident = contact.channel_subscription.resource_identifier;
    if (!ident) {
      return;
    }
    // now let's get all occurences
    let idx = this.getContactIndexForAllReq(ident);
    this.allReqStreams[idx].contact = contact;


    if (idx > -1) {
      while (idx < this.streamItems.length && idx !== -1) {
        this.allReqStreams[idx].contact = contact;
        idx = this.getContactIndexForAllReq(ident, idx + 1);
      }
    }
  }

  // completed req tab - 10/12/24 - Vikas Gupta - A

  private updateStreamForCompReq(contact: ContactForAllReq): void {
    console.log("contact",contact);
    
    const ident = contact.channel_subscription.resource_identifier;
    if (!ident) {
      return;
    }
    // now let's get all occurences
    let idx = this.getContactIndexForCompReq(ident);
    this.allCompletedReqStream[idx].contact = contact;


    if (idx > -1) {
      while (idx < this.streamItems.length && idx !== -1) {
        this.allCompletedReqStream[idx].contact = contact;
        idx = this.getContactIndexForCompReq(ident, idx + 1);
      }
    }
  }

  // completed req tab - 10/12/24 - Vikas Gupta - A


  getContactName(contact: Contact): string {
    if (contact == null) return ''
    let name = contact.first_name
    if (!contact.last_name) return name
    return `${name} ${contact.last_name}`
  }

  getContactNameForAllReq(contact: Contact): string {
    console.log("inside getContactNameForAllReq ",contact)
    if (contact == null) return ''
    let name = contact.first_name
    if (!contact.last_name) return name
    return `${name} ${contact.last_name}`
  }

  getContactNumberWithMask(contact: Contact, maskContact): string {
    if (maskContact === true && contact != undefined) {
      return `${contact.channel_subscription.resource_identifier.replace(/^.{6}/g, '******')}`
    }
    if (contact != undefined) {
      return `${contact.channel_subscription.resource_identifier}`;
    }
  }

  getContactsForStream(): void {
    this.pendingRequest = false;
    this.operatorService.streamsUpdated.subscribe(
      (streamsUpdated: StreamsUpdated) => {

        var streamItemsLength = Object.keys(this.streamItems).length
        this.streamLength = streamItemsLength;
        if (this.streamLength > 0) {
          this.loading = false;
          this.pendingRequest = false;
        }
        if (streamsUpdated && (streamsUpdated.hasUpdated || !this.streamItems || this.streamItems.length === 0)) {

          this.streamItems = streamsUpdated.streams || [];
          const promises = [];

          for (const stream of this.streamItems) {
            if (stream && !stream.contact && stream.source_channel_subscriber) {
              const ident = stream.source_channel_subscriber.resource_identifier.replace('tel://+', '')
                .replace('+', '');
              promises.push(this.contactService.getByParamPromise(ident));
            }
            // // checking if stream hasn't had a response in the last 5 minutes
            // const now = moment();
            // const diff = moment(stream.last_update_date).diff(now, 'ms', true);
            // if (diff >= this.replaySoundTimer) {
            //   debugger;
            //   this.sound.play();
            // }
          }
          if (!streamsUpdated.streams.length && !promises.length) {
            this.pendingRequest = true;
          }
          // would use promise.all but IE has a finite limit on number of concurrent promises it can handle
          // Promise.all(promises).then((res) => res[0])
          if (promises.length > 0) {
            const p = Promise.resolve();
            const final_promise = promises.reduce((prev, curr) => {
              return prev.then((res) => {
                if (res) {
                  const retVal = res as Contact[];
                  if (retVal && retVal.length > 0) {
                    retVal.sort((left: Contact, right: Contact) => {
                      let diff: number;
                      const left_date = new Date(left.last_update_date);
                      const right_date = new Date(right.last_update_date);
                      diff = left_date < right_date ? -1 : left_date > right_date ? 1 : 0;
                      return diff;
                    })
                      .reverse();
                    this.updateStream(retVal[0]);
                  }
                }

                return curr;
              });
            }, p);

            if (final_promise) {
              final_promise.then((res) => {
                if (res) {
                  if (res.hasOwnProperty('length') && res.length > 0) {
                    res.sort((left: Contact, right: Contact) => {
                      let diff: number;
                      const left_date = new Date(left.last_update_date);
                      const right_date = new Date(right.last_update_date);
                      diff = left_date < right_date ? -1 : left_date > right_date ? 1 : 0;
                      return diff;
                    })
                      .reverse();

                    this.updateStream(res[0]);
                  }
                }
              }).catch((err) => {
                console.log(err);
                this.loading = false;
              });
            }
          }

          // here we sort the streams, but we have to check both the last_message_in_date and last_message_out_date to compare
          this.streamItems.sort((left: Stream, right: Stream) => {
            let diff: number;
            // we have to get both dates before we can compare
            const leftMessageToCompare = left.last_message_in_date > left.last_message_out_date ?
              left.last_message_in_date : left.last_message_out_date;
            const rightMessageToCompare = right.last_message_in_date > right.last_message_out_date ?
              right.last_message_in_date : right.last_message_out_date;

            const leftDate = moment(leftMessageToCompare);
            const rightDate = moment(rightMessageToCompare);
            diff = leftDate < rightDate ? -1 : leftDate > rightDate ? 1 : 0;
            return diff;
          })
            .reverse();
          // this.streamItems = streamItems;
          console.log("out of");

          console.log('streamsUpdated.hasUpdated', streamsUpdated.hasUpdated,);
          console.log('this.user.playing_notification_sound', this.user.playing_notification_sound);
          console.log('streamsUpdated.statusImpFlag', streamsUpdated.statusImpFlag);
          console.log('streamsUpdated.streams.length', streamsUpdated.streams.length);



          if (streamsUpdated.hasUpdated && this.user.playing_notification_sound && !streamsUpdated.statusImpFlag && streamsUpdated.streams.length != 0) {
            console.log("in  of 1");

            this.sound.play();
          }
        } else {
          for (const stream of this.streamItems) {


            // checking if stream hasn't had a response in the last 5 minutes
            const now = moment();
            const diff = moment(stream.updated_at).diff(now, 'ms');
            console.log("out of 2");

            if ((diff % this.replaySoundTimer) == 0 && this.user.playing_notification_sound) {
              console.log("in of 2");

              this.sound.play();
            }
          }
        }
      });


  }

  getAllReqOpen(): void {
    this.loadingForAllReq = true
    this.operatorService.streamUpdatedAllReq.subscribe((streamUpdatedAllReq: StreamsUpdatedAllReq) => {
      if (streamUpdatedAllReq && (streamUpdatedAllReq.hasUpdated || !this.streamItems || this.streamItems.length === 0)) {
        this.loadingForAllReq = false

        this.allReqStreams = streamUpdatedAllReq.allRequestStreams || []
        this.loadingForAllReq = false
        if (!this.allReqStreams.length) {
          this.pendingForAllReq = true
        }
        else {
          this.loadingForAllReq = false
          this.pendingForAllReq = false
        }

        const promises = [];
        for (const stream of this.allReqStreams) {
          if (stream && !stream.contact && stream.source_channel_subscriber) {
            const ident = stream.source_channel_subscriber.resource_identifier.replace('tel://+', '')
              .replace('+', '');
            promises.push(this.contactService.getByParamPromiseAllReq(ident));
          }

        }
        if (promises.length > 0) {
          const p = Promise.resolve();
          const final_promise = promises.reduce((prev, curr) => {
            return prev.then((res) => {
              if (res) {
                const retVal = res as ContactForAllReq[];
                if (retVal && retVal.length > 0) {
                  retVal.sort((left: ContactForAllReq, right: ContactForAllReq) => {
                    let diff: number;
                    const left_date = new Date(left.last_update_date);
                    const right_date = new Date(right.last_update_date);
                    diff = left_date < right_date ? -1 : left_date > right_date ? 1 : 0;
                    return diff;
                  })
                    .reverse();
                  this.updateStreamForAllReq(retVal[0]);
                }
              }

              return curr;
            });
          }, p);

          if (final_promise) {
            final_promise.then((res) => {
              if (res) {
                if (res.hasOwnProperty('length') && res.length > 0) {
                  res.sort((left: ContactForAllReq, right: ContactForAllReq) => {
                    let diff: number;
                    const left_date = new Date(left.last_update_date);
                    const right_date = new Date(right.last_update_date);
                    diff = left_date < right_date ? -1 : left_date > right_date ? 1 : 0;
                    return diff;
                  })
                    .reverse();

                  this.updateStreamForAllReq(res[0]);
                }
              }
            }).catch((err) => {
              console.log(err);
              this.loadingForAllReq = false;
            });
          }
        }

        //here we sort the streams, but we have to check both the last_message_in_date and last_message_out_date to compare
        // this.allReqStreams.sort((left: Stream, right: Stream) => {
        //   let diff: number;
        //   // we have to get both dates before we can compare
        //   const leftMessageToCompare = left.last_message_in_date > left.last_message_out_date ?
        //     left.last_message_in_date : left.last_message_out_date;
        //   const rightMessageToCompare = right.last_message_in_date > right.last_message_out_date ?
        //     right.last_message_in_date : right.last_message_out_date;

        //   const leftDate = moment(leftMessageToCompare);
        //   const rightDate = moment(rightMessageToCompare);
        //   diff = leftDate < rightDate ? -1 : leftDate > rightDate ? 1 : 0;
        //   return diff;
        // })
        //   .reverse();
      }

    });

  }

  // Completed tab - 10/12/24 - Vikas Gupta - A 

  getCompReqOpen(): void {

    this.loadingForCompleteReq = true
    // console.log(first)

    this.operatorService.streamUpdatedCompleteReq.subscribe((streamUpdatedCompleteReq: StreamsUpdatedAllReq) => {
      console.log(streamUpdatedCompleteReq,"streamUpdatedCompleteReq")
      if (streamUpdatedCompleteReq && (streamUpdatedCompleteReq.hasUpdated || !this.streamItems || this.streamItems.length === 0)) {
    console.log("inside get comp open")
        this.loadingForCompleteReq = false
        this.allCompletedReqStream = streamUpdatedCompleteReq.allRequestStreams || []
        this.loadingForCompleteReq = false
        if (!this.allCompletedReqStream.length) {
          this.pendingForCompleteReq = true
        }
        else {
          this.loadingForCompleteReq = false
          this.pendingForCompleteReq = false
        }

        const promises = [];
        for (const stream of this.allCompletedReqStream) {
          if (stream && !stream.contact && stream.source_channel_subscriber) {
            const ident = stream.source_channel_subscriber.resource_identifier.replace('tel://+', '')
              .replace('+', '');
            promises.push(this.contactService.getByParamPromiseAllReq(ident));
          }

        }
        if (promises.length > 0) {
          const p = Promise.resolve();
          const final_promise = promises.reduce((prev, curr) => {
            return prev.then((res) => {
              if (res) {
                const retVal = res as ContactForAllReq[];
                if (retVal && retVal.length > 0) {
                  retVal.sort((left: ContactForAllReq, right: ContactForAllReq) => {
                    let diff: number;
                    const left_date = new Date(left.last_update_date);
                    const right_date = new Date(right.last_update_date);
                    diff = left_date < right_date ? -1 : left_date > right_date ? 1 : 0;
                    return diff;
                  })
                    .reverse();
                  this.updateStreamForCompReq(retVal[0]);
                }
              }

              return curr;
            });
          }, p);

          if (final_promise) {
            final_promise.then((res) => {
              if (res) {
                if (res.hasOwnProperty('length') && res.length > 0) {
                  res.sort((left: ContactForAllReq, right: ContactForAllReq) => {
                    let diff: number;
                    const left_date = new Date(left.last_update_date);
                    const right_date = new Date(right.last_update_date);
                    diff = left_date < right_date ? -1 : left_date > right_date ? 1 : 0;
                    return diff;
                  })
                    .reverse();

                  this.updateStreamForCompReq(res[0]);
                }
              }
            }).catch((err) => {
              console.log(err);
              this.loadingForCompleteReq = false;
            });
          }
        }

        //here we sort the streams, but we have to check both the last_message_in_date and last_message_out_date to compare
        // this.allReqStreams.sort((left: Stream, right: Stream) => {
        //   let diff: number;
        //   // we have to get both dates before we can compare
        //   const leftMessageToCompare = left.last_message_in_date > left.last_message_out_date ?
        //     left.last_message_in_date : left.last_message_out_date;
        //   const rightMessageToCompare = right.last_message_in_date > right.last_message_out_date ?
        //     right.last_message_in_date : right.last_message_out_date;

        //   const leftDate = moment(leftMessageToCompare);
        //   const rightDate = moment(rightMessageToCompare);
        //   diff = leftDate < rightDate ? -1 : leftDate > rightDate ? 1 : 0;
        //   return diff;
        // })
        //   .reverse();
      }

    });

  }


  // Completed tab - 10/12/24 - Vikas Gupta - A 



  getOperators(): void {
    this.operatorService.operatorsUpdated.subscribe(
      (operators: OperatorApi[]) => {
        if (operators) {
          this.operators = operators;
        }
      });
  }

  logout(event?: any): void {
    if (event) {
      event.preventDefault();
    }
    this.tabService.resetTabKey()
    this.operatorService.logout$()
      .subscribe(
        (res) => {
          if (res) {
            this.tokenService.remove();
            this.router.navigate(['login']);
            return;
          }
        },
        (err) => {
          let error_text: string;
          try {
            error_text = err;
          } catch (e) {
            error_text = 'Something went wrong';
          }
          if ((typeof error_text === 'string' && error_text.toLowerCase().indexOf('server') > -1)
            || err.status === 404 || (err.message && err.message.toLowerCase() === 'unauthorized')) {
            // something happened in the backend so let's force a logout
          }
          // if we errored from the logout here then we want to keep it so that we can try later
          this.operatorService.keepLogin = false;
          this.router.navigate(['login']);
        });
  }

  subscribeToUserUpdate(): void {
    if (this.userService.current_user) {
      // get whatever is there as this component might be initialized after we get the user
      this.user = this.userService.current_user;
    }
    this.userService.getCurrentUserUpdated().subscribe(
      (user: User) => {
        console.log(user);
        this.user = user;
      });

  }

  subscribeToCurrentContact(): void {
    this.contactService.currentContactUpdated.subscribe(
      (key: ContactExport) => {
        if (key != null) {
          if (key === ContactExport.contact) {
            this.updateStream(this.contactService.currentContact);
          }
        }
        // this.contact = contact;
      });
  }

  subscribeToCurrentDeletedStream(): void {
    this.operatorViewService.currentDeletedStreamIdUpdated.subscribe(
      (streamId: string) => {
        if (streamId != null) {
          const idx = this.streamItems.findIndex(si => si.stream_id === streamId);
          if (idx > -1) {
            this.streamItems.splice(idx, 1);
          }
        }
        // this.contact = contact;
      });
  }

  subscribeToHotels(): void {
    this.subscriptions.push(
      this.hotelService.hotels$
        .subscribe(hotels => {
          // console.log(hotels);
          this.hotels = hotels;
        }, err => {
          console.warn(err);
          console.log('could not get the list of organisations');
        })
    );
  }

  subscribeToCurrentHotel(): void {
    this.subscriptions.push(
      this.hotelService.hotel$
        .subscribe(hotel => {
          this.currentHotel = hotel

          if (this.currentHotel.logo) this.logoIconPath = this.currentHotel.logo
          else this.logoIconPath = this.helperService.getLogoIconPath(this.currentHotel.company_code)
        }, err => {
          console.warn(err);
          console.log('could not get the list of organisations');
        })
    );
  }

  getMaskContact(): void {
    this.subscriptions.push(
      this.hotelService.hotel$
        .subscribe(hotel => {
          this.currentOrganization = hotel;
          const getOrganizationPhone = JSON.parse(JSON.stringify(this.currentOrganization));
          const shortcode = getOrganizationPhone.phone;
          const Url = this.apiUrl + "/mask";
          const options = this.getOptions();
          const payload = {
            "shortcode": shortcode
          };

          return this.http.post(Url, payload, options)
            .toPromise()
            .then((maskContactObject) => {
              this.maskContactObject = JSON.parse(JSON.stringify(maskContactObject));
            });
        }, err => {
          console.log(err);
          console.log('user is not logged in, redirecting...');
          this.router.navigate(['login']);
        })
    );
  }

  // getOperatorStreams(){
  //   var assigned = 0;
  //   var unassigned = 0;
  //   var abandoned = 0;
  //   this.subscriptions.push(
  //     this.hotelService.hotel$
  //       .subscribe(hotel => {
  //         this.currentHotel = hotel
  //         const hotelId = this.currentHotel.id;
  //         const serviceName = this.currentHotel.name;
  //         const phone = this.currentHotel.phone;
  //         const operatorData = JSON.parse( localStorage.getItem("channel_id"))
  //         const site_id = operatorData.site_id
  //         const Url = this.apiUrl+"/channel/operator/streamsession";
  //         const options = this.getOptions();
  //         const payload = {
  //           "site_id": site_id
  //         };
  //         this.http.post(Url, payload, options)
  //           .toPromise()
  //           .then((response) => {
  //             var objectLength = Object.keys(response).length;
  //             // console.log('response length '+ objectLength);
  //             if(objectLength > 0){
  //               // console.log('if');
  //               assigned = response[0].Assigned;
  //               unassigned = response[0].UnAssigned;
  //               abandoned = response[0].Abandoned;
  //               if(assigned > 0 && unassigned == 0 && abandoned == 0){
  //                 this.loading = false;
  //                 this.pendingRequest = true;
  //               }
  //               if(assigned == 0 && unassigned > 0 && abandoned == 0){
  //                 this.loading = true;
  //                 this.pendingRequest = false;
  //               }
  //               if(assigned == 0 && unassigned == 0 && abandoned > 0){
  //                 this.loading = true;
  //                 this.pendingRequest = false;
  //               }
  //               if(assigned == 0 && unassigned > 0 && abandoned > 0){
  //                 this.loading = true;
  //                 this.pendingRequest = false;
  //               }
  //               if(assigned == 0 && unassigned == 0 && abandoned == 0){
  //                 this.loading = false;
  //                 this.pendingRequest = true;
  //               }
  //             }else{
  //               // console.log('else');
  //               this.loading = false;
  //               this.pendingRequest = true;
  //             }
  //         }).catch(
  //           (response) => {
  //         });
  //       }, err => {
  //         console.warn(err);
  //       })
  //   );
  // }

  ngOnInit() {
    this.queueService.pendingRequest.subscribe((res: any) => {
      // console.log('pendingRequest',res);
      this.pendingRequest = false;
      if (res === true) {
        this.pendingRequest = true;
      }
    });

    this.streamItems = [];
    this.subscriptions = [];
    // now we subscribe to the stream
    this.operatorService.getStreams();

    this.subscribeToCurrentHotel()
    this.getCurrentOrganization();
    this.getContactsForStream();
    this.getOperators();
    this.subscribeToUserUpdate();
    // this.subscribeToCurrentContact();
    this.subscribeToHotels();
    this.subscribeToCurrentDeletedStream();
    this.getMaskContact();
    // this.getOperatorStreams();
    this.currentStreamId = this.operatorViewService.currentStreamId;
    this.getAllReqOpen();
    this.getCompReqOpen()

    this.sound = new Howl({
      src: ['../assets/audio/Jeeves.wav'],
      html5: true
    });
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      for (const sub of this.subscriptions) {
        sub.unsubscribe();
      }
    }
  }

  onTabChange(key: string) {
    this.tabKey = key
    this.tabService.setTabKey(key)

  }

  async onReasign(id: string) {
    const operatorData = JSON.parse(localStorage.getItem("channel_id"))
    const operatorEndpoint = operatorData.endpoint_id
    this.operatorService.reAssign(id).then((res: any) => {
      this.operatorService.reassiginReqCall("reassign")
      this.addToast("Successfully updated", '', 'success')
    },
      (err) => {
        this.addToast("failed to updated", '', 'error')
      }
    )


  }


  // Completed tab - 10/12/24 - Vikas Gupta - A 

  async onReopen(id: string) {
    const operatorData = JSON.parse(localStorage.getItem("channel_id"))
    const operatorEndpoint = operatorData.endpoint_id
    this.operatorService.reOpen(id).then((res: any) => {
      this.operatorService.reassiginReqCall("reassign")
      this.addToast("Successfully updated", '', 'success')
    },
      (err) => {
        this.addToast("failed to updated", '', 'error')
      }
    )
  }
  // Completed tab - 10/12/24 - Vikas Gupta - A 



  onSaveLoginOperatorData() {
    const operatorData = JSON.parse(localStorage.getItem("channel_id"))
    this.loginOperatorData = operatorData
  }

  addToast(message: string, title: string, type: string) {
    // Or create the instance of ToastOptions
    const toastOptions = this.getToastOptions(message, title);

    switch (type.toLowerCase()) {
      case 'error':
        this.toastyService.error(toastOptions);
        break;
      case 'success':
        this.toastyService.success(toastOptions);
        break;
    }
  }

}