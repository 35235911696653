import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'humanReadable'
})
export class HumanReadablePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (typeof value !== 'string') {
      return value;
    }
    const words = value.match(/[A-Za-z][^_\-A-Z]*/g);

    return words.map((word) => {
      return word.charAt(0).toUpperCase() + word.substring(1);
    }).join(' ');
  }

}
