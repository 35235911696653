import { Contact } from "./contact.model";

export class RoomDetail {
  active: boolean;
  room_number: string;
  checkin_date: string;
  checkout_date: string;
  static dateObjectList: string[] = ['checkin_date', 'checkout_date'];

  public static fromRoomDetail(obj: any): RoomDetail {
    const keys = Object.keys(obj);
    const roomDetail = new RoomDetail();
    for (const key of keys) {
      if ((roomDetail[key] instanceof Date && typeof obj[key] === 'string') ||
        this.dateObjectList.indexOf(key) > -1) {
        roomDetail[key] = new Date(obj[key]).getTime();
      } else {
        roomDetail[key] = obj[key];
      }
    }
    return roomDetail;
  }

  constructor(
    active?: boolean,
    room_number?: string,
    checkin_date?: string,
    checkout_date?: string
  ) {
    this.active = active;
    this.room_number = room_number;
    this.checkout_date = checkout_date;
    this.checkin_date = checkin_date;
  }
}

export class Registration {
  primary_contact: Contact;
  secondary_contact: Contact[];
  room_details: RoomDetail;
  // ident_key: string;

  constructor(
    primary_contact?: Contact,
    secondary_contact?: Contact[],
    room_details?: RoomDetail,
    // ident_key?: string
  ) {
    this.primary_contact = primary_contact || new Contact();
    this.secondary_contact = secondary_contact || [new Contact()];
    this.room_details = room_details || new RoomDetail();
    // this.ident_key = ident_key;
  }
}
