import { Channel } from '../core/models/channel.model';
import { RoutingInformation } from '../core/models/routing-information.model';
import { Content } from '../core/models/site.model';
import { Tag } from "../core/models/contact.model";
import { sourceChannelSubscriber } from 'app/core/models/source_channel_subscriber'
export class Message {
  message_id: string;
  source_channel: Channel;
  destination_channel: Channel;
  direction : string;
  source_channel_subscriber : sourceChannelSubscriber;
  routing_information: RoutingInformation;
  content: Content[];
  create_date: number;
  last_update_date: number;
  tags: string[];
  tag: Tag[];
  priority: string;
  posted_by: string;
  user: string;
  // property to hold the index of the original message, as this is different for an operator and customer
  origIndex: number;
  // translated index
  transIndex: number;

  constructor(
    message_id?: string,
    content?: Content[],
    source_channel?: Channel,
    destination_channel?: Channel,
    routing_information?: RoutingInformation,
    direction ?: string,
    source_channel_subscriber ?: sourceChannelSubscriber,
    create_date?: number,
    tags?: string[],
    tag?: Tag[],
    priority?: string,
    posted_by?: string
  ) {
    this.message_id = message_id;
    this.content = content || [new Content()];
    this.source_channel = source_channel || new Channel();
    this.destination_channel = destination_channel || new Channel;
    this.routing_information = routing_information || new RoutingInformation();
    this.direction = direction;
    this.source_channel_subscriber = source_channel_subscriber || new sourceChannelSubscriber() ;
    this.create_date = create_date;
    this.tag = tag || [];
    this.tags = tags || [];
    this.priority = priority;
    this.posted_by = posted_by;
  }
}
