import { throwError as observableThrowError, Observable, BehaviorSubject } from 'rxjs'

import { map } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { BaseDjangoService } from './base-django.service'
import { AuthenticationService } from './authentication.service'
import { Hotel, Organization } from '../models/hotel.model'
import { Message } from '../../shared/message.model'
import { HttpClient } from '@angular/common/http'
import * as moment from 'moment'
import { Note } from '../../shared/note.model'
import { Tag } from '../models/contact.model'
import { RoomContact } from '../models/room-assignment.model'
import { SelectOption } from '../models/select-option.model'

@Injectable()
export class HotelService extends BaseDjangoService {

  hotel$: BehaviorSubject<Hotel>
  icon$: BehaviorSubject<string>
  hotels: Hotel[]
  hotels$: BehaviorSubject<Hotel[]> = new BehaviorSubject<Hotel[]>([])
  constructor(
    private http: HttpClient,
    authenticationService: AuthenticationService
  ) {
    super(authenticationService)

    this.apiUrl = this.usersUrl
    this.sendEmailUrl = this.usersUrl
    this.apiUrl += '/web.hotel/'
    this.sendEmailUrl += '/send.conversation/'
    this.hotel$ = new BehaviorSubject<Hotel>(new Hotel())
    this.icon$ = new BehaviorSubject<string>('')
  }

  getAll(): Promise<Hotel[]> {
    const options = this.getOptions()

    return this.http.get(this.apiUrl, options)
      .toPromise()
      .then((res) => {
        this.hotels = res as Hotel[]
        this.hotels$.next(this.hotels)
        return this.hotels
      })
      .catch((err) => observableThrowError(err).toPromise())
  }

  getAllObservable(): Observable<Hotel[]> {
    const options = this.getOptions()

    return this.http.get(this.apiUrl, options).pipe(
      map((res) => {
        this.hotels = res as Hotel[]
        return this.hotels
      }))

    // .map((res) => res as Contact[]);
  }

  sendEmail(messages: any[], email: string, phone: string, customer_email: string, linked: RoomContact[], notes?: Note[], attributes?: string[]): Promise<Message> {
    const url = this.sendEmailUrl

    const options = this.getOptions()
    for (const message of messages) {
      message['created_date'] = moment(message['create_date']).format('YYYY-MM-DD HH:mm:ss')
    }
    const noteText = notes && notes.length > 0 ? notes.map(n => n.note_text.data) : []
    const attributesList = attributes || []

    return this.http.post(url, {
      conversation: messages,
      send_to: email,
      notes: noteText,
      tags: attributesList,
      customer_phone: phone,
      customer_email: customer_email,
      linked_customers: linked
    }, options)
      .toPromise()
      .then(
        (res) => {
          // post to this api does not return anything at the moment
          // assume any 'success' response means message was sent
          // we should be getting the message_id, just transfrom it to a message object
          return res as Message
        })
      .catch((err) => observableThrowError(err).toPromise())
  }

  sendConversation(messages: any[], randomNumber?: string, baseURL?: string, apiKey?: string, activeCampaignId?: number): Promise<Message> {
    const url = 'https://webapi.jeeves.plus/active-campaign/send-conversation'
    const options = this.getOptions()
    for (const message of messages) {
      message['created_date'] = moment(message['create_date']).format('YYYY-MM-DD HH:mm:ss')
    }
    return this.http.post(url, {
      conversation: messages,
      contactId: activeCampaignId,
      randomNumber: randomNumber,
      baseURL: baseURL,
      apiKey: apiKey
    }, options)
      .toPromise()
      .then(
        (res) => {
          return res as Message
        })
      .catch((err) => observableThrowError(err).toPromise())
  }

  getOrganisationByCode(companyCode: string): Observable<Organization> {
    const options = this.getOptions()
    const url = `${this.usersUrl}/web.organisation/get_organisation/?company_code=${companyCode}`

    return this.http.get(url, options).pipe(
      map((res) => {
        const organization: Organization = res as Organization
        return organization
      }))
  }

  getOrganisationByOrgNo(orgNo: number): Observable<Organization> {
    const options = this.getOptions()
    const url = `${this.usersUrl}/web.organisation/get_organisation/?org_no=${orgNo}`

    return this.http.get(url, options).pipe(
      map((res) => {
        const organization: Organization = res as Organization
        return organization
      }))
  }

}
