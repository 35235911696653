import { Component, EventEmitter, forwardRef, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Organization } from '../core/models/hotel.model'
import { HotelService } from '../core/services/hotel.service'
import { StylePicker } from '../shared/style-picker'
import { Login } from './login.model';
import { TokenService } from '../core/services/token.service';
import { ShowNavService } from '../shared/show-nav.service';
import { Router } from "@angular/router";
import { AuthenticationService } from "../core/services/authentication.service";
import { OperatorService } from "../core/services/operator.service";
import { OperatorLogin } from "../core/models/operator-login.model";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Site } from "../core/models/site.model";
import { SiteService } from "../core/services/site.service";
import { from, of, Subscription } from 'rxjs'
import { catchError, delay } from 'rxjs/operators';
import { environment as env } from "../../environments/environment";
import { HelperService } from "../shared/helper.service";
import { UserIdleService } from 'angular-user-idle';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { OperatorLoginSessionPopupComponent } from '../operator-login-session-popup/operator-login-session-popup.component'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  // loginForm: FormGroup;
  loading: boolean = false;
  error: string = '';
  logging_user_in = false;
  button_text = '';
  loginUser: Login = new Login();
  loginForm: FormGroup;
  clientName = '';
  logo = '';
  defaultNameKey = 'defaultClient';
  logoPath = '';
  organization: Organization
  subscriptions: Subscription[]
  hideLogin = false
  _authenticationService: AuthenticationService;
  OperatorLoginSessionPopup: any

  formErrors = {
    username: '',
    password: ''
  };

  validationMessages = {
    username: {
      required: 'Username is required.'
    },
    password: {
      required: 'Password is required.'
    }
  };

  @Output() showNavChange = new EventEmitter<boolean>();

  constructor(
    private tokenService: TokenService,
    private showNavService: ShowNavService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private operatorService: OperatorService,
    private siteService: SiteService,
    private formBuilder: FormBuilder,
    private helperService: HelperService,
    private hotelService: HotelService,
    private stylePicker: StylePicker,
    private userIdle: UserIdleService,
    private http: HttpClient,
    private dialog: MatDialog
  ) {this._authenticationService = authenticationService;}

  private getHeaders(): HttpHeaders {
    const headers = {
      Accept: 'application/json'
    };

    const token = this._authenticationService.getToken();
    if (token) {
      headers['Authorization'] = token.token_type + ' ' + token.access_token;
    }
    return new HttpHeaders(headers);
  }

  getOptions(): object {
    const headers = this.getHeaders();
    return {
      headers: headers
    };
  }

  enterSubmit(event): void {
    if (event.keyCode === 13) this.login()
  }
  async login() {
    const username = this.loginForm.get('username').value;
    const password = this.loginForm.get('password').value;
    if (username && password) {
      this.loading = true;
      this.logging_user_in = true;
      this.error = '';

      /** START: LOGOUT OPERATOR IF SESSION EXIST ALREADY */
     // const url = 'https://webapi.jeeves.plus/channel/operator/operatorendpoint?username='+username;
     const url = `${env.api}/channel/operator/operatorendpoint?username=${username}`
      const options = this.getOptions();
      // console.log('1');
      await this.http.post(url, options)
      .toPromise()
      .then( async (response) => {
        // console.log('2');
        // console.log('LOGGED IN USER: '+response['Count']);
        if(response['Count'] > 0){
          let operatorCount = response['Count'];
          let endpointId = response['Items'][0]['endpoint_id'];
          let myOpID = response['Items'][0]['endpoint_description_link'];
          
            const httpOptions = {
              headers: new HttpHeaders({
                'Content-Type': 'application/json'
              }),
            };
            const endpointUrl = `${env.api}/channel/operator/reset`
            const body = {OpID : myOpID};
            await this.http.post(endpointUrl, body, httpOptions)
            .toPromise()
            .then((response) => {
              let result = JSON.stringify(response);
              console.log('DELETE OPERATOR SESSION RESULT => '+result)
            }).catch(
              (error) => {
                console.log('RESET OPERATOR: '+error);
            });
        }
      }).catch(
        (error) => {
          console.log('OPERATOR ENDPOINT: '+error);
      });
      /** END */
      // console.log('3');
      // now we need to check if we hava the login still
      const keepLogin = this.operatorService.keepLogin;
      let promise: any;
      if (keepLogin) {
        // TODO: get the below delay from somehwere generically
        promise = this.operatorService.logout$()
          .pipe(
            delay(2000),
            catchError(err => {
              console.log(err);
              console.log('Something happened while trying to delete the stream, checking if it is a 404, so we have to go to the login');
              debugger;
              if (err.status == 404) {
                // if the error is 404, we can delete or change the retry login
                this.operatorService.keepLogin = false;
              }
              throw err;
            })
          )
          .toPromise()
          .then(res => {
            console.log('deleted the stream');
            return this.tokenService.obtain(username, password)
          });
      } else {
        promise = this.tokenService.obtain(username, password);
      }
      promise
        .then((res) => {
          // need to do something on successful login
          return this.operatorService.login();
        }).then((operator_info: OperatorLogin) => {
          // now we get the site defaults
          // if we have the operator login from a previous session, let's set it now
          if (operator_info) {
            this.siteService.setSiteId(operator_info.site_id);
          }
          return this.siteService.getSiteById();
        }).then((site: Site) => {
          this.operatorService.getStreams();
          // this.loading = false;
          // this.logging_user_in = false;
          // add the id when it's worked out what's going on
          this.hideLogin = true
          this.router.navigate(['operator']);
        })
        .catch((error) => {
          this.loading = false;
          this.logging_user_in = false;
          console.log(error);
          if (typeof error === 'string') {
            if (error === 'invalid_grant') {
              this.error = 'Username or password was incorrect, please try again...';
            } else {
              this.error = 'Something went wrong, please contact your Systems Administrator.';
              throw error;
            }
          } else {
            const err = error;
            if (err.error && err.error.error_description) {
              this.error = err.error.error_description;
            } else {
              this.error = 'Something went wrong, please contact your systems administrator';
              throw error;
            }
          }
        });
    }
  }

  onValueChange(data?: any) {
    if (!this.loginForm) return;

    const form = this.loginForm;
    for (let field in this.formErrors) {
      this.formErrors[field] = '';
      const control = form.get(field);
      if (control && control.dirty && control.invalid) {
        let message = this.validationMessages[field];
        for (let key in control.errors) {
          this.formErrors[field] += message[key] + ' ';
        }
      }
    }
  }

  ngOnInit() {
    let companyCode = this.helperService.getCompanyCodeFromUrl(window.location.host)
    const retVal = this.helperService.getClientMapping(companyCode)
    this.subscriptions = []

    // we reset the company code to whatever comes back from the mapping, since we might have a blank one or one that doesn't exist
    companyCode = retVal.companyCode
    this.stylePicker.loadStyle(companyCode + '.css')
    this.logoPath = this.helperService.getLogoPath(companyCode);
    this.loginForm = this.formBuilder.group({
      username: [this.loginUser.username, [Validators.required]],
      password: [this.loginUser.password, [Validators.required]]
    });
    this.loginForm.valueChanges.subscribe(data => this.onValueChange(data))
    this.button_text = '';

    this.clientName = retVal.clientName;
    this.logoPath = retVal.logoPath;
    this.hotelService.icon$.next(retVal.faviconPath)

    if (this.authenticationService.isAuthenticated()) {
      // console.log('USER LOGIN: '+this.authenticationService.isAuthenticated());
      // first we refresh the token
      this.logging_user_in = true;
      // this.tokenService.refresh()
      //   .then(refresh_token => {
      //     let promise: any;
      //     const operator_login = this.operatorService.getChannelTokens();
      //     if (!operator_login) {
      //       promise = this.operatorService.login();
      //     } else {
      //       // if (operator_login.site_id) {
      //       //   this.siteService.setSiteId(operator_login.site_id);
      //       // }
      //       promise = of(null).toPromise();
      //     }
      //     return from(promise).pipe(
      //       delay(5000)
      //     ).toPromise();
      //   }).then((operatorLogin: any) => {
      //     // if operatorLogin is null, then the operator is already logged in
      //     // this.operatorService.getStreams();
      //     // this.logging_user_in = false;
      //     this.button_text = 'Redirecting...';

          this.router.navigate(['operator']);

      //     // since re are redirecting, we now show the main area
      //     this.operatorService.loggedInEvent.emit(true);
      //   }).catch((err) => {
      //     // TODO: we just stay on the login page if something went wrong
      //     console.log(err);
      //     this.logging_user_in = false;
      //     throw err;
      //   });
    } else {
      /** STOP CHECKING INACTIVITY IF USER LOGGED OUT */
      this.userIdle.stopWatching();
      // if we get here then the user is not logged in and we need to hide the loader
      this.operatorService.loggedInEvent.emit(true);
      this.logging_user_in = false;
    }
    this.showNavService.set(false);
  }

  ngOnDestroy() {
    this.showNavService.set(true);
  }
}
