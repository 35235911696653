import { AfterViewChecked, Component, Input, OnDestroy, OnInit, ElementRef, EventEmitter, Output } from '@angular/core';
import { Contact } from '../../core/models/contact.model';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Message } from '../../shared/message.model';
import { FilterByPipe } from '../../core/pipes/filter-by.pipe';
import { OperatorViewService } from '../../operator/operator-view.service';
import { ContactService } from '../../core/services/contact.service';
import { Hotel } from '../../core/models/hotel.model';
import { User } from '../../shared/user.model';
import { Room, RoomContact, RoomAssignment, RoomAssignmentStatus } from '../../core/models/room-assignment.model'
import { BaseComponent } from '../../shared/base/base.component';
import { ToastyService } from 'ng2-toasty';
import { RoomAssignmentService } from '../../core/services/room-assignment.service';
import { debounceTime, switchMap } from 'rxjs/operators';
import { SearchResult } from '../../core/models/search-result.model';
import { StreamsUpdated } from '../../core/models/streams-updated.model';
import { Stream } from '../../core/models/stream.model';
import { OperatorService } from '../../core/services/operator.service';
import { MatDialog } from '@angular/material/dialog';
import { FaqModal } from '../faq-modal/faq-modal.component';
import { SmsModal } from '../sms-modal/sms-modal.component';

import { HotelService } from "../../core/services/hotel.service";
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '../../core/services/authentication.service';
import { environment as env } from '../../../environments/environment';
import { QueueService } from 'app/core/queue/queue.service';

@Component({
  selector: 'app-message-actions',
  templateUrl: './message-actions.component.html',
  styleUrls: ['./message-actions.component.css']
})
export class MessageActionsComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewChecked {
  _contact: BehaviorSubject<Contact>;
  operatorMessageDir = 'out';
  previousSearch: any;
  message_search: string;
  searchResults = [];
  searchSubscription: Subscription;
  subscriptions = [];
  streams = [];
  loadingResults: boolean;
  numMessages = 0;
  o8Info: any;
  loading: boolean;
  pendingRequest: boolean;
  guestDetails: RoomAssignment = new RoomAssignment();

  currentOrganization: Hotel;
  apiUrl: string;
  _authenticationService: AuthenticationService;
  maskContactObject: any = { };
  acEnabled: boolean;
  acBaseURL: string;
  acAPIKey: string;
  
  @Input() contactNumber: string;
  @Input() contactNumberWithoutMask: string;
  @Input() contact: Contact;
  @Input() roomNumbers: string;
  @Input() messageHistory: any[];
  @Input() sending_messages: boolean;
  @Input() sending_to_ac: boolean;
  @Input() completingRequest: boolean;
  @Input() stream_id: string;
  @Input() currentHotel: Hotel;
  @Input() users: User[];
  @Input() messages: any[];
  @Input() rooms: Room[];
  @Output() roomContactsEmitter = new EventEmitter<Room[]>();

  // @Input()
  // set contact(c: any) {
  //   this._contact.next(c);
  // };
  //
  // get contact() {
  //   return this._contact.getValue();
  // }

  constructor(private filterByPipe: FilterByPipe,
    contactService: ContactService,
    private operatorViewService: OperatorViewService,
    private roomAssignmentService: RoomAssignmentService,
    private toastyService: ToastyService,
    private operatorService: OperatorService,
    private elementRef: ElementRef,
    private hotelService: HotelService,
    private router: Router,
    private http: HttpClient,
    authenticationService: AuthenticationService,
    private queueService: QueueService,
    public dialog: MatDialog) {
    super(contactService);
    this.apiUrl = env.api;
    this._authenticationService = authenticationService;
  }

  private getHeaders(): HttpHeaders {
    const headers = {
      Accept: 'application/json'
    };

    const token = this._authenticationService.getToken();
    if (token) {
      headers['Authorization'] = token.token_type + ' ' + token.access_token;
    }
    return new HttpHeaders(headers);
  }

  getOptions(): object {
    const headers = this.getHeaders();
    return {
      headers: headers
    };
  }

  openFaq(): void {
    const dialogRef = this.dialog.open(FaqModal, {
      hasBackdrop: true,
      // position: { 'top': '0', 'left': '0' }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Closed Dialog");
    });
  }

  sendToActiveCampaign(baseURL: string, apiKey: string): void {
    this.sending_to_ac = true;
    var phone = this.contact.channel_subscription.resource_identifier;
    const contact_id = this.contact.contact_id;

    const rooms = this.rooms;
    const mainRoom = rooms.length > 0 ? rooms[0] : new Room();
    const email = mainRoom.room_number;

    const Url = this.apiUrl+"/contact/"+contact_id;
    const options = this.getOptions();
    this.http.get(Url, options).subscribe(
      (response) => {
        if(response[0].ActiveCampaign && response[0].ActiveCampaign.id != undefined){
          console.log('IF');
          const activeCampaignId = response[0].ActiveCampaign.id;
          for (const message of this.messageHistory) {
            if (message.routing_information != null && message.routing_information.direction != this.operatorMessageDir) {
              message.user = this.contact == null || this.contact.last_name == null ? this.contactNumber : this.contact.first_name + ' ' +
                this.contact.last_name;
            } else {
              message.user = this.filterByPipe.transform(message.posted_by, this.users, 'id');
            }
          } 
          // var phone = this.contact.channel_subscription.resource_identifier;
          this.operatorViewService.sendConversationToActiveCampaign(this.messageHistory, phone, baseURL, apiKey, activeCampaignId)
            .then((res) => {
              const response = JSON.parse(JSON.stringify(res));
              const response1 = response.body;
              this.sending_to_ac = false;
              this.addToast(response1, '', 'success')
            })
            .catch((err) => {
              this.sending_to_ac = false;
              this.addToast('Something went wrong. Please try again after sometime.',
                '', 'error');
              throw err;
            });
        }else{
          console.log('ELSE');
          const url = this.apiUrl+'/active-campaign/search-contact'
          this.http.post(url,{
            baseURL: baseURL,
            apiKey: apiKey,
            phone: phone,
            email: email,
            contact_id: contact_id
          }, options).subscribe(
            (response) => {
              const response1 = JSON.parse(JSON.stringify(response));
              /** */
              if(response1.body.activeCampaignIdFound === false){
                this.sending_to_ac = false;
                this.addToast(response1.body.message, '', 'error')
              }

              if(response1.body.activeCampaignIdFound === true){
                this.operatorViewService.sendConversationToActiveCampaign(this.messageHistory, phone, baseURL, apiKey, response1.body.id)
                .then((res) => {
                  this.sending_to_ac = false;
                  this.addToast('CSV sent to Active Campaign', '', 'success')
                })
                .catch((err) => {
                  this.sending_to_ac = false;
                  console.log(err);
                  this.addToast('Something went wrong. Please try again after sometime.',
                    '', 'error');
                  throw err;
                });
              }
              /** */
            },
            (error) => {
              console.error('Error occurred while making POST request:', error);
              this.sending_to_ac = false;
            }
          );
        }
      },
      (error) => {
          console.error('Error occurred while fetching data:', error);
      }
  );

    
  }

  emailMessages(): void {
    this.sending_messages = true;
    for (const message of this.messageHistory) {
      if (message.routing_information != null && message.routing_information.direction != this.operatorMessageDir) {
        message.user = this.contact == null || this.contact.last_name == null ? this.contactNumber : this.contact.first_name + ' ' +
          this.contact.last_name;
      } else {
        message.user = this.filterByPipe.transform(message.posted_by, this.users, 'id');
      }
    }
    const phone = this.contact.channel_subscription.resource_identifier;

    const rooms = this.rooms;
    console.log('rooms found', rooms)
    const mainRoom = rooms.length > 0 ? rooms[0] : new Room();
    const email = mainRoom.room_number;
    const mainContact = mainRoom.room_contacts.length ? mainRoom.room_contacts[0] : new RoomContact()
    let room_contacts = [].concat.apply([], rooms.map((r: Room) => r.room_contacts))
    // now we splice off the first room contact since we don't need it if the email is the same as the main contact
    if (room_contacts[0].contact_id === mainContact.contact_id) room_contacts.shift()

    this.operatorViewService.sendCsvEmail(this.messageHistory, this.currentHotel.default_email, this.contact, phone, email, room_contacts)
      .then((res) => {
        // do something on message sent

        // if (res) {
        //   this.contactService.setCurrentContact(null);
        //   this.operatorViewService.setStreamId(null, null);
        //   this.message = new Message();
        //   this.contactService.setCurrentContact(null);
        // }
        this.sending_messages = false;
        this.addToast('Successfully sent email.', 'Send Email', 'success')
      })
      .catch((err) => {
        this.sending_messages = false;
        console.log(err);
        this.addToast('Error occurred while sending csv to Client.',
          'Send Email', 'error');
        throw err;
      });
  }

  addToast(message: string, title: string, type: string) {
    // Or create the instance of ToastOptions
    const toastOptions = this.getToastOptions(message, title);
    // // Add see all possible types in one shot
    // this.toastyService.info(toastOptions);
    // this.toastyService.success(toastOptions);
    // this.toastyService.wait(toastOptions);
    // this.toastyService.error(toastOptions);
    // this.toastyService.warning(toastOptions);

    switch (type.toLowerCase()) {
      case 'error':
        this.toastyService.error(toastOptions);
        break;
      case 'success':
        this.toastyService.success(toastOptions);
        break;
    }
  }



  completeMessage(): void {
    if(confirm("Finished conversation? The Complete Request action will remove the selected conversation from Requests.")) {
    // this.message = this.transformTags(this.message, true);
    this.completingRequest = true;
    // if we have a stream id, we can send a message
    this.operatorViewService.completeStream()
      .then((res) => {
        // do something on message sent
        if (res) {

          /** */
          if(res == true){
            // console.log('yes response is true');
            console.log("complete ss");
            
            var assigned = 0;
            var unassigned = 0;
            var abandoned = 0;
            this.subscriptions.push(
              this.hotelService.hotel$
                .subscribe(hotel => {
                  this.currentHotel = hotel
                  const hotelId = this.currentHotel.id;
                  const serviceName = this.currentHotel.name;
                  const phone = this.currentHotel.phone;
                  const operatorData = JSON.parse( localStorage.getItem("channel_id"))
                  const site_id = operatorData.site_id
                  const Url = this.apiUrl+"/channel/operator/streamsession";
                  const options = this.getOptions();
                  const payload = {
                    "site_id": site_id
                  };
                  this.http.post(Url, payload, options)
                    .toPromise()
                    .then((response) => {
                      var objectLength = Object.keys(response).length;
                      // console.log('response type => '+ typeof response);
                      // console.log('response length '+ objectLength);
                      if(objectLength > 0){
                        // console.log('jump into if condition');
                        assigned = response[0].Assigned;
                        unassigned = response[0].UnAssigned;
                        abandoned = response[0].Abandoned;
                        if(assigned == 0 && unassigned == 0 && abandoned == 0){
                          // console.log('yes now there is no pending request');
                          this.loading = false;
                          this.queueService.pendingRequest.next(true);
                          // this.pendingRequest = true;
                        }
                      }else{
                        // console.log('jump into else condition');
                        this.loading = false;
                        // this.pendingRequest = true;
                        this.queueService.pendingRequest.next(true)
                      }
                  }).catch(
                    (response) => {
                  });
                }, err => {
                  console.warn(err);
                })
            );
          }
          /** */
          
          this.contactService.setCurrentContact(null);
          this.operatorViewService.setStreamId(null, null);
          this.contactService.setCurrentContact(null);
        }
        this.completingRequest = false;
      })
      .catch((err) => {
        this.completingRequest = false;
        console.log(err);
        throw err;
      });
    }
  }

   sendSMS(): void {
    const dialogRef = this.dialog.open(SmsModal, {
      hasBackdrop: true,
      width: '600px'
      // position: { 'top': '0', 'left': '0' }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Closed Dialog");
    });
  }

  openProgram(OpInfo): void {
    window.open(
      OpInfo.LinkAddress,
      '_blank' 
    );
  }

  search(searchText: any): void {
    this.loadingResults = true;
    // if there is a '+' or a number then check if its a number or room
    this.searchResults = [];
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    // this.errorMessage = '';
    this.searchSubscription = this.contactService.getByParam(searchText).pipe(
      debounceTime(600),
      switchMap(
        (contacts) => {
          if (contacts && contacts.hasOwnProperty('length') && contacts.length > 0) {

            for (const contact of contacts) {
              let display_name: string;
              if (contact != null && (contact.first_name || contact.last_name)) {
                display_name = (contact.first_name || '') + ' ' + (contact.last_name || '');
              } else {
                display_name = contact.channel_subscription.resource_identifier;
              }

              // check if we have the same display name - and since we order the contacts
              // by last update date then we should have the correct one here
              const idx = this.searchResults.findIndex(sr => sr.text === display_name);
              if (idx === -1) {
                this.searchResults.push(new SearchResult(contact.contact_id, display_name, 'contact'));
              }
            }
          }
          return this.roomAssignmentService.getByParam(searchText);
        }),
      switchMap(
        (roomAssignments) => {
          if (roomAssignments && roomAssignments.hasOwnProperty('length') && roomAssignments.length > 0) {
            for (const roomAssignment of roomAssignments) {
              let rooms = '';
              let contact: Contact;
              const contact_id = roomAssignment.primary_contact_hint;
              if (contact_id) {

                contact = this.contactService.contacts.find(c => c.contact_id === contact_id);
              }
              // take the first room number
              if (roomAssignment.rooms && roomAssignment.rooms[0]) {
                rooms = roomAssignment.rooms[0].room_number;
              }
              // for (const room of roomAssignment.rooms) {
              //   rooms += room.room_number + ', ';
              // }
              if (contact != null && (contact.first_name || contact.last_name)) {
                rooms += (contact.first_name || '') + ' ' + (contact.last_name || '');
              } else if (contact != null) {
                rooms += contact.channel_subscription.resource_identifier;
              }
              const idx = this.searchResults.findIndex(sr => sr.text === rooms);
              if (idx === -1) {
                // this.searchResults.push(new SearchResult(contact.contact_id, display_name, 'contact'));
                this.searchResults.push(new SearchResult(roomAssignment.roomassignment_id, rooms, 'roomassignment'));
              }
            }
          }
          return this.contactService.getByParam(searchText, 'last_name', true);
        }),
      switchMap(
        (contacts) => {
          if (contacts && contacts.hasOwnProperty('length') && contacts.length > 0) {
            for (const contact of contacts) {
              const full_name = contact.first_name + ' ' + contact.last_name;
              const idx = this.searchResults.findIndex(sr => sr.value === contact.contact_id);
              if (idx === -1) {
                this.searchResults.push(new SearchResult(contact.contact_id, full_name, 'contact'));
              }
            }
          }
          return this.contactService.getByParam(searchText, 'first_name', true);
        }))
      .subscribe(
        (contacts) => {
          // last one loadded
          if (contacts && contacts.hasOwnProperty('length') && contacts.length > 0) {
            for (const contact of contacts) {
              const full_name = contact.first_name + ' ' + contact.last_name;
              const idx = this.searchResults.findIndex(sr => sr.value === contact.contact_id);
              if (idx === -1) {
                this.searchResults.push(new SearchResult(contact.contact_id, full_name, 'contact'));
              }
            }
          }
          this.loadingResults = false;
        });
  }


  searchChange(search: any): void {
    // we know it should be an id in the list of items returned, so we can search for it by that
    // const obj = this.searchResults.find(sr => sr.value === searchText);
    // because we changed to a combobox, we get the whole object back
    // this.errorMessage = '';
    let contact: Contact;

    if (search) {
      this.operatorViewService.setStreamId('', '');
      // we have a result
      let contactDoesntExist = false;
      switch (search.endpoint) {
        case 'roomassignment':
          const roomAssignment = this.roomAssignmentService.roomAssignments
            .find(ra => ra.roomassignment_id === search.value);
          if (roomAssignment) {
            const contact_id = roomAssignment.primary_contact_hint;
            contact = this.contactService.contacts.find(c => c.contact_id === contact_id);
            if (contact) {
              this.contactService.setCurrentContact(contact);
            } else {
              contactDoesntExist = true;
            }
          }
          break;
        case 'contact':
          contact = this.contactService.contacts
            .find(ra => ra.contact_id === search.value);
          if (contact) {
            this.contactService.setCurrentContact(contact);
          } else {
            contactDoesntExist = true;
          }
          break;
      }
      if (contact) {
        this.operatorViewService.setShowMessage(false);
        const stream = this.streams.find(
          s => s.source_channel_subscriber.resource_identifier === contact.channel_subscription.resource_identifier
        );
        if (stream) {
          this.operatorViewService.setStreamId(stream.stream_id, stream.stream_id);
        } else {
          // otherwise we see if we can get it from somewhere

        }
      }
      if (contactDoesntExist) {
        // this.errorMessage = 'Could not find contact related to search, please contact your Systems Administrator.';
        this.addToast('Could not find messages related to your search, please contact your Systems Administrator.', 'Error', 'error');
      }
    } else {
      // otherwise we are clearing the search
      if (this.previousSearch) {
        const currentContact = this.contactService.currentContact;

        if (currentContact && this.previousSearch.endpoint == 'contact' &&
          currentContact.contact_id == this.previousSearch.value) {
          this.operatorViewService.setStreamId('', '');
          this.contactService.setCurrentContact(null);
        }
      }
    }
    this.previousSearch = search;
    // if there is a '+' or a number then check if its a number or room
  }

  ngAfterViewChecked(): void {
    this.numMessages = (this.messages ? this.messages.length : 0) + (this.messageHistory ? this.messageHistory.length : 0);
  }

  getOperatorInfo(): void {
    let o8InfoData = this.operatorService.getoperatorinfo();
    this.o8Info = o8InfoData.siteinfo.ProgramLink ? o8InfoData.siteinfo.ProgramLink: {};
  }

  getMaskContact(): void{
    this.subscriptions.push(
      this.hotelService.hotel$
        .subscribe(hotel => {
          this.currentOrganization = hotel;
          const getOrganizationPhone = JSON.parse(JSON.stringify(this.currentOrganization));
          const shortcode = getOrganizationPhone.phone;
            const Url = this.apiUrl+"/mask";
            const options = this.getOptions();
            const payload = {
              "shortcode": shortcode
            };

            return this.http.post(Url, payload, options)
              .toPromise()
              .then((maskContactObject) => {
              this.maskContactObject = JSON.parse(JSON.stringify(maskContactObject));  
            });
        }, err => {
          console.log(err);
          console.log('user is not logged in, redirecting...');
          this.router.navigate(['login']);
        })
    );
  }

  get08Object(): void{
    this.subscriptions.push(
      this.hotelService.hotel$
        .subscribe(hotel => {
          this.currentOrganization = hotel;
          const getOrganizationPhone = JSON.parse(JSON.stringify(this.currentOrganization));
          const shortcode = getOrganizationPhone.phone;
            const Url = this.apiUrl+"/O8";
            const options = this.getOptions();
            const payload = {
              "shortcode": shortcode
            };

            return this.http.post(Url, payload, options)
              .toPromise()
              .then((result) => {
              const O8Object = JSON.parse(JSON.stringify(result));
              if(O8Object.Items[0].ActiveCampaign){
                this.acEnabled = O8Object.Items[0].ActiveCampaign.enabled;
                this.acBaseURL = O8Object.Items[0].ActiveCampaign.BaseURL;
                this.acAPIKey = O8Object.Items[0].ActiveCampaign.APIKey;
              }
            });
        }, err => {
          console.log(err);
          console.log('user is not logged in, redirecting...');
          this.router.navigate(['login']);
        })
    );
  }

  getContactNumberWithMask(contactNumber, maskContact): string {
    if( maskContact === true && contactNumber != undefined){
      return `${contactNumber.replace(/^.{6}/g, '******')}`
    }

    if(contactNumber != undefined){
      return `${contactNumber}`;
    }
  }

  saveDetails(firstName, lastName, eMail): void {
    console.log('first name => '+firstName);
    console.log('last name => '+lastName);
    console.log('email => '+eMail);
    if(eMail){
      console.log('CONTACT - '+ JSON.stringify(this.contact));
      this.guestDetails.rooms[0].room_number = eMail
      this.contact.first_name = firstName;
      this.contact.last_name = lastName;
      this.contact.channel_subscription.shopifyVerified = true;
      this.contact.channel_subscription.shopify = true;
    }

    // need to save contact first so we have the id
    if (!this.guestDetails.roomassignment_status) {
      this.guestDetails.roomassignment_status = RoomAssignmentStatus.checkedin;
    }

    if (this.guestDetails.is_primary) {
      // if we are on the primary guest details (i.e. we are not editing the additional guest info
      // automatically looks at: this.room_contacts
      this.guestDetails = this.addContactsToRooms(this.guestDetails);
    }

    this.operatorViewService.save(this.contact)
      .then(
        (res) => {
          let promise: Promise<RoomAssignment | {}>;
          if (res) {
            this.contact = res;
            promise = this.operatorViewService.saveRoomAssignment(res, this.guestDetails);
          } else {
            promise = Promise.resolve({});
          }
          return promise;
        })
      .then(
        (res) => {
          // room assignment could be null or undefined since PUT response returns
          // an empty response object
          if (res) {
            this.contactService.setCurrentContact(this.contact);
          }
          this.roomContactsEmitter.emit(this.guestDetails.rooms)

          if(eMail){
            this.addToast('Status updated successfully','', 'success')
          }else{
            this.addToast('Successfully saved guest details.',
            'Saving guest details.', 'success')
          }
        })
      .catch(
        (err) => {
          // do something on error
        });
  }

  ngOnInit() {
    this.getOperatorInfo();
    this.getMaskContact();
    this.get08Object();
    this.subscriptions.push(this.operatorService.streamsUpdated.subscribe(
      (streamsUpdated: StreamsUpdated) => {
        if (streamsUpdated) {
          this.streams = streamsUpdated.streams;
        }
      }));
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      for (const sub of this.subscriptions) {
        sub.unsubscribe();
      }
    }
  }

}
