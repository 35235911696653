
import { of as observableOf, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { QueueService } from '../queue.service';
import { AuthenticationService } from '../../services/authentication.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SocketService extends QueueService {
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) {
    super(authenticationService);
  }

  pull(url: string): Observable<any> {
    return observableOf([]);
  }

  push(obj: any) {
    console.log(Error("Method not implemented."));
  }

}
