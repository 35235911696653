import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { MatDialogModule } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { BaseComponent } from '../../shared/base/base.component';
import { OperatorService } from '../../core/services/operator.service';
import { ContactService } from '../../core/services/contact.service';
import { HotelService } from '../../core/services/hotel.service';
import { Hotel } from '../../core/models/hotel.model';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService } from '../../core/services/user.service';
import { User } from '../../shared/user.model';
import { ToastOptions, ToastyService } from 'ng2-toasty';

@Component({
  selector: 'faq-modal',
  templateUrl: 'faq-modal.component.html'
})
export class FaqModal extends BaseComponent implements OnInit, OnDestroy {
  constructor(private operatorService: OperatorService,
    public dialogRef: MatDialogRef<FaqModal>,
    public hotelService: HotelService,
    public contactService: ContactService,
    public userService: UserService,
    public router: Router,
    public toastyService: ToastyService
  ) {
    super(contactService);
  }

  currentHotel: Hotel;
  private subscriptions: Array<Subscription> = [];
  message: string;
  isUrgent: boolean = false

  close() {
    this.dialogRef.close();
  }

  sendMessage() {
    if (this.userService.current_user) {
      this.user = this.userService.current_user;
    }

    const userName = this.user.username;
    const contact = this.currentHotel.phone;

    let res: Promise<any>;

    if (this.isUrgent) res = this.operatorService.sendUrgentMessage(userName, contact, this.message);
    else res = this.operatorService.sendNonUrgentMessage(userName, contact, this.message);
    const ops = (h: string, t: string): ToastOptions => super.getToastOptions(h, t);
    res.then((data) => {
      this.dialogRef.close();
      this.toastyService.success(ops("Successfully sent message, we will get back to you soon.", "Message Sent"));
    }).catch((err) => {
      this.dialogRef.close();

      this.toastyService.error(ops("Your message could not be sent at this time. Please contact support if this persists.", "Error"));
    });
  }


  getCurrentHotel() {
    this.subscriptions.push(
      this.hotelService.hotel$
        .subscribe(hotel => {
          this.currentHotel = hotel;
        }, err => {
          console.log(err);
          console.log('user is not logged in, redirecting...');
          this.router.navigate(['login']);
        }));
  }

  ngOnInit() {
    this.subscriptions = [];
    this.getCurrentHotel();
  }
  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      for (const sub of this.subscriptions) {
        sub.unsubscribe();
      }
    }
  }

}
