import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { MatDialogModule } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { BaseComponent } from '../../shared/base/base.component';
import { OperatorService } from '../../core/services/operator.service';
import { ContactService } from '../../core/services/contact.service';
import { HotelService } from '../../core/services/hotel.service';
import { Hotel } from '../../core/models/hotel.model';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService } from '../../core/services/user.service';
import { User } from '../../shared/user.model';
import { ToastOptions, ToastyService } from 'ng2-toasty';


@Component({
  selector: 'sms-modal',
  templateUrl: 'sms-modal.component.html'
})
export class SmsModal extends BaseComponent implements OnInit, OnDestroy {
  constructor(
    public dialogRef: MatDialogRef<SmsModal>,
    public hotelService: HotelService,
    public contactService: ContactService,
    public userService: UserService,
    public router: Router,
    public toastyService: ToastyService,
    private OperatorService: OperatorService,

  ) {
    super(contactService);
  }

  currentHotel: Hotel;
  private subscriptions: Array<Subscription> = [];
  messageContent = '';
  maxChars = 160;
  MSISDN: string;
  clicked: boolean = false;
  errorMessage = '';

  close() {
    this.dialogRef.close();
  }

  sendMessage(): void {

    this.clicked = true;

    this.OperatorService.sendSeparateMessage(this.messageContent, this.MSISDN, this.currentHotel.name)
      .then((res) => {
        // do something on message sent
        if (res) {
          this.dialogRef.close();
          const ops = (h: string, t: string): ToastOptions => super.getToastOptions(h, t);
          this.toastyService.success(ops("Successfully", "Message Sent"));
          this.messageContent = '';
          this.MSISDN = '';
        }
      })
      .catch((err) => {
        //this.dialogRef.close();
        this.clicked = false;
        const ops = (h: string, t: string): ToastOptions => super.getToastOptions(h, t);
        this.errorMessage = "Please enter correct mobile number including country code eg 61419371555";
        //this.toastyService.error(ops("Something Wrong", "Phone number is not in correct format"));
        throw err;
      });


   
  }



  getCurrentHotel() {
    this.subscriptions.push(
      this.hotelService.hotel$
        .subscribe(hotel => {
          this.currentHotel = hotel;
        }, err => {
          console.log(err);
          console.log('user is not logged in, redirecting...');
          this.router.navigate(['login']);
        }));
  }

  ngOnInit() {
    this.subscriptions = [];
    this.getCurrentHotel();
  }
  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      for (const sub of this.subscriptions) {
        sub.unsubscribe();
      }
    }
  }

}
