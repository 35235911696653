import { Component, OnInit } from '@angular/core';
import { ResetPassword } from '../core/models/reset-password.model';
import { Location } from '@angular/common';
import { ShowNavService } from '../shared/show-nav.service';
import { UserService } from '../core/services/user.service';
import { TokenService } from '../core/services/token.service';
import { ActivatedRoute, Router } from '@angular/router';
import { VerifyPassword } from "../core/models/verify-password.model";
import { HelperService } from "../shared/helper.service";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  resetPassword: ResetPassword;
  loading = false;
  uidb36: string;
  token: string;
  callback_url: string;
  message: string;
  error: string = '';
  success: string = '';
  password: VerifyPassword;
  changeSuccessful: boolean = false;
  logoPath = '';
  clientName = '';

  constructor(
    private location: Location,
    private showNavService: ShowNavService,
    private userService: UserService,
    private tokenService: TokenService,
    private router: Router,
    private route: ActivatedRoute,
    private helperService: HelperService
  ) { }

  cancel(): void {
    this.location.back()
  }

  goBack(): void {
    this.tokenService.remove();
    this.router.navigate(['/login']);
  }

  reset(): void {
    if (this.resetPassword.email) {
      this.loading = true;
      this.error = '';
      this.success = '';
      this.userService.resetPassword(this.resetPassword.email, this.callback_url)
        .then((res) => {
          // need to do something on successful login
          console.log('THEN: '+res);
          this.success = 'Successfully sent password reset, please check your email ';
          this.loading = false;
        })
        .catch((error) => {
          
          let errors: any;
          try {
            errors = error;
          } catch (e) {
            console.log('CATCH: '+e);

          }
          if (errors && typeof errors === 'object') {
            const keys = Object.keys(errors);
            // there should be only one key - if there's more... then something went wrong
            for (const key of keys) {
              this.error = errors[key];
              this.error = 'Invalid Username';
            }
          } else {
            this.error = 'An error occurred when resetting your password, please try again..';
          }
          this.loading = false;
        });
    }
  }

  changePassword(): void {
    this.message = '';
    this.changeSuccessful = false;
    this.userService.setPassword(this.password.newPassword).then(
      (successful) => {
        this.changeSuccessful = successful;
        if (successful) {
          this.message = 'Successfully changed password, redirecting to login...';
          setTimeout(() => {
            this.tokenService.remove();
            this.router.navigate(['/login']);
          }, 2000);
        }
      }).catch(
        (error) => {
          // there was an error loading the link, redirect back to login
          // this.router.navigate(['/login']);
          this.changeSuccessful = false;
          this.message = 'Could not update your password, please try again...';
        });
  }

  checkToken(): void {
    this.tokenService.obtain(this.uidb36, this.token).then(
      (access_token) => {
        if (access_token) {
          // this.message = 'su';
        }
      }).catch(
        (error) => {
          // there was an error loading the link, redirect back to login
          this.router.navigate(['/login']);
        });
  }

  ngOnInit() {
    const companyCode = this.helperService.getCompanyCodeFromUrl(window.location.host)
    const retVal = this.helperService.getClientMapping(companyCode);
    this.clientName = retVal.clientName;
    this.logoPath = retVal.logoPath;
    this.resetPassword = new ResetPassword;
    this.password = new VerifyPassword();
    this.showNavService.set(false);
    let test_route = this.location.path(true);
    this.callback_url = document.location.origin + this.router.createUrlTree(['/resetpassword']).toString();
    // this.route.paramMap
    //   .switchMap((params: ParamMap) => )
    this.uidb36 = this.route.snapshot.paramMap.get('uidb36');
    this.token = this.route.snapshot.paramMap.get('token');

    if (this.uidb36 && this.token) {
      this.checkToken();
    }
  }

}
