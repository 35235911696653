import { Component, Input, OnInit, Renderer2, ViewChild, AfterViewChecked, ElementRef, ChangeDetectorRef, OnChanges } from '@angular/core';
import anchorme from 'anchorme'
import { Message } from '../shared/message.model';
import { MessageService } from '../core/services/message.service';
import { ConstantsService } from '../core/services/constants.service';
import { OperatorService } from '../core/services/operator.service';
import { OperatorViewService } from '../operator/operator-view.service';
import { ContactService } from '../core/services/contact.service';
import { Contact } from '../core/models/contact.model';
import { ContactExport } from '../core/shared/enum';
import { UserService } from '../core/services/user.service';
import { User } from '../shared/user.model';
import { SelectOption } from '../core/models/select-option.model';
import { BaseComponent } from '../shared/base/base.component';
import { RoomAssignmentService } from '../core/services/room-assignment.service';
import { Room, RoomAssignment } from '../core/models/room-assignment.model'
import { SiteService } from '../core/services/site.service';
import { Content, SiteBulkMessageTemplate } from '../core/models/site.model';
import { TagService } from '../core/services/tag.service';
import * as _ from 'lodash';
import { StreamsUpdated } from '../core/models/streams-updated.model';
import { HotelService } from '../core/services/hotel.service';
import * as moment from 'moment';
import { Subscription } from "rxjs";
import { Hotel } from "../core/models/hotel.model";
import { ToastyService } from "ng2-toasty";
import { FilterByPipe } from "../core/pipes/filter-by.pipe";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '../core/services/authentication.service';
import { environment as env } from '../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-message-details',
  templateUrl: './message-details.component.html',
  styleUrls: ['./message-details.component.css']
})
export class MessageDetailsComponent extends BaseComponent implements OnInit {
  languages: SelectOption[];
  priorities: SelectOption[];
  loading: boolean;
  message: Message = new Message();
  user: User;
  returnedMessageIds: string[] = [];
  messageIds: string[] = [];
  stream_id: string;
  stream_version: string;
  contact: Contact;
  contactNumber: string;
  newTag: string;
  keyCodes = [9, 13];
  operatorMessageDir = 'out';
  operatorContentIdx = 0;
  guestDetails: RoomAssignment;
  roomNumbers = '';
  @Input() messages: Message[];
  gettingMessage = false;
  completingRequest = false;
  operatorResponses: SiteBulkMessageTemplate[];
  messageHistory: Message[];
  fullMessageHistory: Message[];
  currentAndPastMessageHistory: Message[];
  historyPageSize = 10
  users: User[];
  defaultTags: string[];
  tags: string[];
  isSending = false;
  autoTranslate: boolean;
  stream_updated = false;
  stream_history_updated = false;
  sending_messages = false;
  subscriptions: Subscription[] = [];
  currentHotel: Hotel;
  _content: string = '';

  searchTemplate = '';
  searchTemplateData: SiteBulkMessageTemplate[];
  showMessage = true;
  rooms: Room[] = [];
  textToSpeech: boolean;

  currentOrganization: Hotel;
  apiUrl: string;
  _authenticationService: AuthenticationService;
  maskContactObject: any = { };
  contactNumberWithMask: string;

  @ViewChild('tagAutocomplete') public tagAutocomplete: any;
  @ViewChild('templateDropdown') public templateDropdown: any;
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  forOutboundMessage: Message[];

  constructor(
    private messageService: MessageService, private constantsService: ConstantsService,
    private operatorViewService: OperatorViewService, private operatorService: OperatorService,
    contactService: ContactService, private userService: UserService, private roomAssignmentService: RoomAssignmentService,
    private siteService: SiteService, private tagService: TagService, private renderer: Renderer2, private hotelService: HotelService,
    private toastyService: ToastyService, private filterByPipe: FilterByPipe, private cdRef: ChangeDetectorRef,
    authenticationService: AuthenticationService,
    private http: HttpClient,
    private router: Router
  ) {
    super(contactService);
    this.apiUrl = env.api;
    this._authenticationService = authenticationService;
  }

  private getHeaders(): HttpHeaders {
    const headers = {
      Accept: 'application/json'
    };

    const token = this._authenticationService.getToken();
    if (token) {
      headers['Authorization'] = token.token_type + ' ' + token.access_token;
    }
    return new HttpHeaders(headers);
  }

  getOptions(): object {
    const headers = this.getHeaders();
    return {
      headers: headers
    };
  }

  set content(val: any) {
    this._content = val;
  }

  enterPressedHandler() {
    if (this.user.enter_to_send_message) {
      this.message.content[0].data = this.message.content[0].data.trim()
      this.sendMessage();
    }
  }

  formatUrlLinks(text: string): string {
    const options = {
      attributes: {
        target: '_blank'
      },
    }
    return anchorme({
      input: text,
      options: options
    })
  }

  /**
   * since there are only 2 indexes, we only ever show either the 0 or 1 index
   * if the direction is 'out' then show the '0' index as the translated (since this is for the operator)
   * if the direction is 'in' then show the '1' index as the translated (since this if for the customer and the
   *     operator should see the translated text (since it could be in another language)
   *
   * @param msg
   * @param val
   */
  setMessageTranslatedIdx(msg: Message, val: any) {
    msg.transIndex = val;
    msg.origIndex = val == 0 ? 1 : 0;
  }

  // addTag(event: any): void {
    addTag(value: any): void {
      if (value && value.trim()) {
        console.log(this.message.tags.indexOf(value),"testttttt");
        
        if(this.message.tags.indexOf(value)==-1)
          {
            this.message.tags.push(value);
          }
      }
      this.newTag = '';
      console.log(this.message.tags,"tagsssssssss");
      if (this.tagAutocomplete) {
        this.tagAutocomplete.clearValue(); // Clear the Kendo component's value
        this.tagAutocomplete.reset(); // Reset the state if required
      }
    }

  filterTemplates(): void {
    this.searchTemplateData = this.operatorResponses
      .filter(or => or.message.data.toLowerCase().indexOf(this.searchTemplate.toLowerCase()) !== -1);
  }

  removeTag(idx: number): void {
    if (this.message && this.message.tags && this.message.tags.length > idx) {
      this.message.tags.splice(idx, 1);
    }
  }

  getLanguages(): void {
    this.constantsService.get().then((choices) => {
      this.choices = choices;
      this.languages = choices.languages;
      this.priorities = choices.priorities;
    }).catch((error) => {
      this.languages = [];
      console.log(error);
    });
  }

  // sendMessage(): void {
  async sendMessage() {
    // if we don't have any text or a stream to send it to, don't send the message
    if (!this.message.content[0].data || !this.stream_id) {
      return;
    }

    var isAudioEnabled = document.getElementById("audioCheked") as HTMLInputElement;
    if(isAudioEnabled && isAudioEnabled.checked) {
      this.isSending = true;
      console.log('isAudioEnabled => ' +isAudioEnabled.value);

        const textMessage = this.message.content[0].data;
        const Url = this.apiUrl+"/textspeech/makeaudio";
        const options = this.getOptions();
        const payload = {
          "textMessage": textMessage,
          "streamId": this.stream_id,
          "randomNumber": Math.random()
        };

        await this.http.post(Url, payload, options)
          .toPromise()
          .then((result) => {
            var audioLink = JSON.parse(JSON.stringify(result));
            // const string2 = audioLink.Location;
            const string2 = audioLink.shortURL;
            console.log("url",this.message.content[0].data.concat('  Voice File: '+string2));
            
            this.message.content[0].data = this.message.content[0].data.concat('  Voice File: '+string2);
        });
    }
    console.log('Audio Link after'+ this.message.content[0].data);

    this.isSending = true;
    this.message = this.transformTags(this.message, true);
    if (this.messages && this.messages.length > 0) {
      // const len = this.messages.length - 1;
      // // get the previous pi value
      if (this.autoTranslate || this.messages.length > 1) {
        console.log(this.messages[0].content[0]);
        // since the messages are now from top to bottom, we need to get the last message in the list
        this.message.content[0].pi = this.messages[this.messages.length - 1].content[0].pi || Content.DEFAULT_PI_VAL;
      } else {
        this.message.content[0].pi = Content.DEFAULT_PI_VAL;
      }
    }

    if (this.user) {
      console.log("exist user");
      
      this.message.posted_by = this.user.id.toString();
    }

    // if we have a stream id, we can send a message
   
    let outBoundData :object;
    
    // if (this.forOutboundMessage && this.forOutboundMessage.length > 0) {
    //     const lastMessage = this.forOutboundMessage[this.forOutboundMessage.length - 1];
    
    //     if (lastMessage && lastMessage.message_id && lastMessage.source_channel_subscriber && lastMessage.content) {
    //         const lastMessageId = lastMessage.message_id;
    //         const msisdn = lastMessage.source_channel_subscriber.resource_identifier;
    //         const pi = lastMessage.content[0].pi;
    
    //         outBoundData = {
    //             direction: lastMessage.direction,
    //             message_id: lastMessageId,
    //             pi,
    //             msisdn
    //         };
    
    //         console.log("outbound", outBoundData);
    
    //     } else {
    //         console.error("Incomplete lastMessage object:", lastMessage);
    //     }
    // } else {
    //     console.error("No messages available or messages array is undefined.");
    // }
    const operatorData = JSON.parse( localStorage.getItem("channel_id"))
    const site_id = operatorData.site_id
    const posted_by = operatorData.endpoint_description_link
    const resource_ident = this.contactNumber || this.contact.channel_subscription.resource_identifier;
    outBoundData = {
       site_id,
       msisdn : resource_ident,
       posted_by   
    }


    this.operatorViewService.sendMessage(this.message , outBoundData)
      .then((res) => {
        // do something on message sent
        if (res) {
          this.message = new Message();
          // now we need to go and get the message that was just sent

          // this.messages = this.messageService.currentMessages;
        }
        this.isSending = false;
        // now scrolling to the bottom if the user has scrolled up
        this.scrollToBottom();
      })
      .catch((err) => {
        console.log(err);
        this.isSending = false;
        throw err;
      });
  }

  subscribeToCurrentContact(): void {
    this.contactService.currentContactUpdated.subscribe(
      (key: ContactExport) => {
        this.contactNumber = null;
        this.contact = null;
        this.messageHistory = [];
        this.fullMessageHistory = []
        this.currentAndPastMessageHistory = []
        
        if (key != null) {
          if (key === ContactExport.contact) {
            this.contact = this.contactService.currentContact;
            if (!this.contact.last_name) {
              this.contactNumber = this.contact.channel_subscription.resource_identifier;
              this.contactNumberWithMask = this.contact.channel_subscription.resource_identifier.replace(/^.{6}/g, '******');
            }
          } else {
            this.contactNumber = this.contactService.currentNumber;
          }
          if (!this.stream_history_updated) {
            this.getMessageHistory();
          }
        }
        // this.loadFullHistory();

        // this.contact = contact;
      }, (err) => {
        throw err;
      });
  }

  subscribeToCurrentRoomAssignment(): void {
    this.roomAssignmentService.roomAssignmentUpdated.subscribe(
      (roomAssignment: RoomAssignment) => {
        this.roomNumbers = '';
        if (roomAssignment != null) {
          this.guestDetails = roomAssignment;
          if (this.guestDetails.rooms) {
            for (const room of this.guestDetails.rooms) {
              this.roomNumbers += room.room_number + ', ';
            }
            this.roomNumbers = this.roomNumbers.substring(0, this.roomNumbers.length - ', '.length);
          }
        }
        // this.contact = contact;
      }, (err) => {
        throw err;
      });
  }

  subscribeToUserUpdate(): void {
    if (this.userService.current_user) {
      // get whatever is there as this component might be initialized after we get the user
      this.user = this.userService.current_user;
    }
    this.userService.getCurrentUserUpdated().subscribe(
      (user: User) => {
        this.user = user;
      }, (err) => {
        throw err;
      });
  }

  // TODO: test the 2 functions below as there could be a race condition that occurs
  subscribeToStreamUpdated(): void {
    this.operatorService.streamsUpdated.subscribe(
      (streamsUpdated: StreamsUpdated) => {
        if (this.stream_id != null && streamsUpdated.hasUpdated) {
          const streamItems = streamsUpdated.streams;
          for (const stream of streamItems) {
            if (stream.stream_id === this.stream_id && stream.version !== this.stream_version) {
              this.getMessages();
            }
          }
        }
      }, (err) => {
        throw err;
      });
  }

  subscribeToShowMessageUpdated(): void {
    this.operatorViewService.showMessageUpdated.subscribe(
      (showMessage: boolean) => {
        this.showMessage = showMessage;
      }, (err) => {
        throw err;
      });
  }

  subscribeToMessageId(): void {
    this.operatorViewService.currentStreamIdUpdated.subscribe(
      (value: any) => {
        if (value != null && value !== '') {
          this.gettingMessage = true;
          this.loading = true;
          this.messages = [];
          this.messageIds = [];
          this.messageHistory = [];
          this.returnedMessageIds = [];
          this.stream_id = value.stream_id;
          this.stream_version = value.version;
          this.stream_updated = true;

          // getting messages
          this.getMessages();
        } else if (value === '' || value == null) {
          this.stream_updated = false;
          this.gettingMessage = false;
          this.loading = false;
          this.messages = [];
          this.messageIds = [];
          this.messageHistory = [];
          this.returnedMessageIds = [];
          this.stream_id = '';
          this.stream_version = '';
        }
        if (this.cdRef.hasOwnProperty('destroyed') && !this.cdRef['destroyed']) {
          this.cdRef.detectChanges();
        }
      }, (err) => {
        throw err;
      });
  }

  subscribeToCurrentHotel(): void {
    this.subscriptions.push(
      this.hotelService.hotel$.subscribe(hotel => {
        this.currentHotel = hotel;
      }, (err) => {
        throw err;
      })
    );
  }

  private updateFullHistory(): void {
    const historyIds = this.currentAndPastMessageHistory.map(fmh => fmh.message_id)
    const missingMessageIds = this.messageIds.filter(mi => !historyIds.includes(mi))
    const messages = this.messages.filter(m => missingMessageIds.includes(m.message_id))
    this.currentAndPastMessageHistory = this.currentAndPastMessageHistory.concat(messages)
  }

  private getMessages(): void {
    this.loading = true;
    // console.log('loading true');
    this.operatorService.getStreamById(this.stream_id)
      .then(
        stream_response => {
          const promises = [];
          if (stream_response && stream_response.length > 0) {
            for (const s of stream_response) {
              this.returnedMessageIds.push(s.message_id);
              promises.push(this.messageService.getById(s.message_id))
            }
          }

          if (promises.length > 0) {
            const p = Promise.resolve();
            const finalPromise = promises.reduce((prev, curr) => {
              // promises.reduce((prev, curr) => {
              return prev.then((res) => {
                if (res && res.message_id) {
                  // all messages should have a message id, if we already have the message then skip it
                  const idx = this.messageIds.findIndex(id => id === res.message_id);

                  if (idx === -1) {
                    const message = this.transformTags(res);
                    // get user for message if we have it, otherwise use current user

                    if (message && !message.posted_by) {
                      message.posted_by = this.user.id.toString();
                    }
                    // now we check if the user has loaded a new stream or not by check the list of streams returned
                    // from the pull requests
                    const stream_message_idx = this.returnedMessageIds.findIndex(rmi => rmi === message.message_id);
                    if (stream_message_idx > -1) {
                      this.messages.push(message);
                      this.messageIds.push(message.message_id);
                      this.cdRef.detectChanges();
                    }

                    this.scrollToBottom();
                  }
                }
                return curr;
              }).catch((err) => {
                console.log(err);
                return curr;
              })
            }, p);
            if (finalPromise) {
              finalPromise.then((res) => {
                if (res && res.message_id) {
                  const idx = this.messageIds.findIndex(id => id === res.message_id);

                  if (idx === -1) {
                    const message = this.transformTags(res);
                    // now we check if the user has loaded a new stream or not by check the list of streams returned
                    // from the pull requests
                    const stream_message_idx = this.returnedMessageIds.findIndex(rmi => rmi === message.message_id);
                    if (stream_message_idx > -1) {
                      this.messages.push(message);
                      this.messageIds.push(message.message_id);
                      this.cdRef.detectChanges();
                    }
                  }
                }

                if (this.messages && this.messages.length > 1) {
                  // since we are in the finally block we can sort the messages here
                  // just in case they are out of order
                  this.messages
                    .sort((left: Message, right: Message) => {
                      let diff: number;
                      const left_date = new Date(left.create_date);
                      const right_date = new Date(right.create_date);
                      diff = left_date < right_date ? -1 : left_date > right_date ? 1 : 0;
                      return diff;
                    })
                  // .reverse();
                }
                // add the messages to the historyL
                this.updateFullHistory()
                this.loading = false;
                this.gettingMessage = false;
                this.stream_updated = false;
                this.scrollToBottom();
              })
                .catch((err) => {
                  console.log(err);
                  this.loading = false;
                  this.gettingMessage = false;
                  this.stream_updated = false;
                });
            } else {
              this.gettingMessage = false;
              this.stream_updated = false;
            }
          }
          this.loading = false;
        })
      .catch(
        err => {
          console.log(err);
          this.gettingMessage = false;
          this.loading = false;
          this.stream_updated = false;
          throw err;
        }
      );
  }

  updateRoomContacts(event) {
    this.rooms = event
  }

  private getMessageHistory(): void {
    if (this.contact || this.contactNumber) {
      const resource_ident = this.contactNumber || this.contact.channel_subscription.resource_identifier;
      this.messageService.getByParam(resource_ident)
        .then(
          (res) => {
            // this.messageHistory = res;
            this.forOutboundMessage = res
            if (res && res.length > 0) {
              for (let i = 0; i < res.length; i++) {
                // get user for message if we have it, otherwise use current user
                if (res[i] && !res[i].posted_by) {
                  res[i].posted_by = this.user.id.toString();
                }
                res[i] = this.transformTags(res[i]);
              }
              res = res.sort((left: Message, right: Message) => {
                let diff: number;
                const left_date = moment(left.create_date);
                const right_date = moment(right.create_date);
                // diff = left_date.isBefore(right_date) ? -1 : left_date.isAfter(right_date) ? 1 : 0;
                diff = left_date.isBefore(right_date) ? -1 : left_date.isAfter(right_date) ? 1 : 0;
                return diff;
              })
              // we get all the messages that are not in the current conversation for the 'fullMessageHistory'
              this.fullMessageHistory = [...res.filter((r) => this.messageIds.indexOf(r.message_id) < 0)]
              this.currentAndPastMessageHistory = [...res]
              let messageStart = this.fullMessageHistory.length - this.historyPageSize
              if (messageStart < 0) messageStart = 0
              this.messageHistory = [...res.slice(messageStart)]
              this.cdRef.detectChanges();

              this.scrollToBottom();
            }
          }).catch(
            (err) => {
              console.log("err",err);
            });
    }
  }

  loadPreviousMessages(event) {
    if (event.target.scrollTop === 0) {
      const scrollHeight = event.target.scrollHeight;
      // there should be a modified function getMessageHistory
      // this.getMessageHistory(paginationCount)

      if (this.messageHistory.length < this.fullMessageHistory.length) {
        let messageStart = this.fullMessageHistory.length - this.messageHistory.length - this.historyPageSize
        if (messageStart < 0) messageStart = 0
        let messageStop = this.fullMessageHistory.length - this.messageHistory.length
        if (messageStop <= 0) messageStop = 0
        if (!messageStop) return
        this.messageHistory = [
          ...this.fullMessageHistory.slice(messageStart, messageStop),
          ...this.messageHistory
        ];
      } else {
        // exiting function since we don't do anything if we get to the top and all the history is now in the view
        return
      }

      this.cdRef.detectChanges();
      event.target.scrollTop = event.target.scrollHeight - scrollHeight;
    }
  }

  // delete after creating the proper function
  populateMessage(response: SiteBulkMessageTemplate): void {
    this.message.content[0].data = response.message.data;
    // console.log(this.templateDropdown);
    // this.templateDropdownOpen = false;

    // can't think of a better way to do this
    $(this.templateDropdown.nativeElement).hide();
  }

  // subscribeToSiteDefaults(): void {
  getSiteDefaults(): void {
    const login_details = this.operatorService.getChannelTokens();
    // the site id should exist if it doedsn't then something is very wrong
    this.siteService.setSiteId(login_details.site_id);
    if (this.siteService.siteDefaults) {
      this.operatorResponses = this.siteService.siteDefaults.site_official_responses;
      this.searchTemplateData = this.operatorResponses;
    } else {
      this.siteService.getSiteById()
        .then((res) => {
          this.operatorResponses = res.site_official_responses;
          this.searchTemplateData = this.operatorResponses;
        });
    }
  }

  getUsers(): void {
    if (this.userService.users) {
      this.users = this.userService.users;
    } else {
      this.userService.getAll()
        .then((users) => {
          this.users = users;
        });
    }
  }

  getTags(): void {
    this.defaultTags = this.tagService.getTagList();
    this.tags = _.cloneDeep(this.defaultTags);
  }

  handleTagFilter(value: any) {
    this.tags = this.defaultTags.filter((s) => s.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  scrollToBottom(): void {
    const el = this.myScrollContainer.nativeElement;
    if (el.scrollHeight != el.clientHeight) {
      el.scrollTop = el.scrollHeight - el.clientHeight;
    }
  }

  getMaskContact(): void{
    this.subscriptions.push(
      this.hotelService.hotel$
        .subscribe(hotel => {
          this.currentOrganization = hotel;
          const getOrganizationPhone = JSON.parse(JSON.stringify(this.currentOrganization));
          const shortcode = getOrganizationPhone.phone;
            const Url = this.apiUrl+"/mask";
            const options = this.getOptions();
            const payload = {
              "shortcode": shortcode
            };

            return this.http.post(Url, payload, options)
              .toPromise()
              .then((maskContactObject) => {
              this.maskContactObject = JSON.parse(JSON.stringify(maskContactObject));  
            });
        }, err => {
          console.log(err);
          console.log('user is not logged in, redirecting...');
          this.router.navigate(['login']);
        })
    );
  }

  getCurrentOrganization(): void {
    this.subscriptions.push(
      this.hotelService.hotel$
        .subscribe(hotel => {
          this.currentOrganization = hotel;
          const getOrganizationPhone = JSON.parse(JSON.stringify(this.currentOrganization));
          const shortcode = getOrganizationPhone.phone;
            // const Url = this.apiUrl+"/shopify";
            const Url = this.apiUrl+"/textspeech";
            const options = this.getOptions();
            const payload = {
              "shortcode": shortcode
            };
            return this.http.post(Url, payload, options)
              .toPromise()
              .then((result) => {
                this.textToSpeech = JSON.parse(JSON.stringify(result));
            });
        }, err => {
          console.log(err);
          console.log('user is not logged in, redirecting...');
          this.router.navigate(['login']);
        })
    );
  }

  ngOnInit() {
    this.getLanguages();
    this.loading = false;

    this.subscribeToMessageId();
    this.subscribeToCurrentContact();
    this.subscribeToStreamUpdated();
    this.subscribeToUserUpdate();
    this.subscribeToCurrentRoomAssignment();
    this.subscribeToShowMessageUpdated();
    this.subscribeToCurrentHotel();
    this.getSiteDefaults();
    this.getCurrentOrganization();
    if (this.siteService.siteDefaults) {
      this.operatorResponses = this.siteService.siteDefaults.site_official_responses;
      this.searchTemplateData = this.operatorResponses;
    }
    this.getUsers();
    this.getTags();
    this.getMaskContact();
    this.message = new Message();
    this.autoTranslate = true;
  }

}
