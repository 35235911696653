import { Stream } from './stream.model';

export class StreamsUpdated {
  streams: Stream[];
  hasUpdated: boolean;
  statusImpFlag : boolean;

  constructor(
    streams?: Stream[],
    hasUpdated?: boolean,
    statusImpFlag?:boolean) {
    this.streams = streams;
    this.hasUpdated = hasUpdated;
    this.statusImpFlag = statusImpFlag 
  }
}
