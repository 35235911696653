import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'contains'
})
export class ContainsPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!args || typeof args !== 'string' || !value || (value !== Object(value) && typeof value != 'string') || !value.hasOwnProperty('length')) {
      return;
    }

    return value.indexOf(args) > -1;
  }

}
