import { ContactForAllReq } from "./contactAllReq.model";

export class SourceChannelSubscriber {
  resource_identifier?: string;
  channel_identifier?: string;
}

export class AllReqStream {
  stream_id?: string;
  version?: string;
  parent_endpoint_id?: string;
  route_instance_key?: string;
  source_channel_subscriber?: SourceChannelSubscriber;
  message_id_list_length?: number;
  // all the 'dates' below are time since epoc in milliseconds
  create_date?: number;
  last_update_date?: number;
  last_message_in_date?: number;
  last_message_out_date?: number;
  contact?: ContactForAllReq;
  importantFlag?:boolean;
  static dateObjectList: string[] = ['checkin_date', 'last_update_date', 'last_message_in_date'];

  static toStream(obj: any): AllReqStream {
    const keys = Object.keys(obj);
    const stream = new AllReqStream();
    for (const key of keys) {
      if ((stream[key] instanceof Date && typeof obj[key] === 'string') ||
        this.dateObjectList.indexOf(key) > -1) {
        stream[key] = new Date(obj[key]);
      } else {
        stream[key] = obj[key];
      }
    }
    return stream;
  }
}
