import { Component, OnInit } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { Subscription } from 'rxjs'
import { Organization } from '../../core/models/hotel.model'
import { HotelService } from '../../core/services/hotel.service'
import { HelperService } from '../../shared/helper.service'
import { switchMap } from 'rxjs/operators'
import { ActivatedRoute, ParamMap } from '@angular/router'
import { environment as env } from '../../../environments/environment'
import { StylePicker } from '../../shared/style-picker'

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.css']
})
export class ThankYouComponent implements OnInit {
  DEFAULT_THANK_YOU_TEXT = `
            Thank you for registering. CLIENT_NAME are working to resolve the language barrier
            and make sure you can communicate with all your customers.<br />
            Text us with any requests that you have.<br/>
  `
  clientName = ''
  logoPath = ''
  org_type: number = null
  org_no: string = null
  subscriptions: Subscription[]
  orgName: string
  organization: Organization
  thankYouText: SafeHtml

  constructor(
    private helperService: HelperService,
    private hotelService: HotelService,
    private route: ActivatedRoute,
    private stylePicker: StylePicker,
    private sanitizer: DomSanitizer
  ) {
  }

  subscribeToOrganization(): void {
    this.subscriptions.push(
      this.hotelService.getOrganisationByOrgNo(Number(this.org_no))
        .subscribe((organization: Organization) => {
          this.organization = organization

          if (this.organization.logo) this.logoPath = this.organization.logo
          else if (this.organization.company_code) this.logoPath = this.helperService.getLogoIconPath(this.organization.company_code)

          this.thankYouText = this.sanitizer.bypassSecurityTrustHtml(
            this.organization.thank_you_text || this.DEFAULT_THANK_YOU_TEXT.replace(/CLIENT_NAME/g, this.clientName)
          )
        })
    )
  }

  ngOnInit() {
    this.subscriptions = []

    this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        const org_type = params.get('org_type')
        this.org_no = params.get('org_no') || params.get('org_No')
        let org_name = ''
        if (this.org_no != null) {
          const orgName = parseInt(this.org_no || '')
          org_name = env.clientNumbers[orgName]
          this.orgName = org_name
        }
        if (org_type && typeof org_type == 'string') {
          this.org_type = parseInt(org_type)
        }

        const retVal = this.helperService.getClientMapping(org_name)
        this.clientName = retVal.clientName
        this.logoPath = retVal.logoPath
        // we reset the company code to whatever comes back from the mapping, since we might have a blank one or one that doesn't exist
        const companyCode = retVal.companyCode
        this.hotelService.icon$.next(retVal.faviconPath)
        this.stylePicker.loadStyle(companyCode + '.css');
        this.subscribeToOrganization()

        // const test = this.decrypt(param);
        return Promise.resolve([])
      }))
      .subscribe(contact => {
        // just a dummy return function since it doesn't need to really do anything
        return contact
      })
  }

}
