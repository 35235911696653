import { Component, OnInit } from '@angular/core';
import { Message } from "../message.model";
import { SelectOption } from "../../core/models/select-option.model";
import { User } from "../user.model";
import { Contact, Tag } from "../../core/models/contact.model";
import { Room, RoomAssignment, RoomContact } from "../../core/models/room-assignment.model";
import { ContactService } from "../../core/services/contact.service";
import { ToastOptions } from "ng2-toasty";

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.css']
})
export class BaseComponent implements OnInit {
  tagProperties = ['tags', 'priority', 'opera_validated'];
  choices: object;
  user: User;
  managerRoles: string[] = ['admin', 'manager'];
  room_contacts: RoomContact[] = [];
  contactService: ContactService;
  private DEFAULT_TITLE: string = 'Error';

  constructor(contactService: ContactService) {
    this.contactService = contactService;
  }

  getToastOptions(message: string, title: string): ToastOptions {
    title = title != null ? title : this.DEFAULT_TITLE;

    return {
      title: title,
      msg: message,
      showClose: true,
      timeout: 5000,
      theme: 'default'
    };
  }

  isManager(): boolean {
    if (!this.user) {
      return false;
    }
    return this.managerRoles.findIndex(mr => mr === this.user.role) !== -1;
  }

  transformTags(message: Message, is_saving?: boolean): Message {
    if (message.tag && message.tag.length > 0 && !is_saving) {
      // the tag on a message should be a list of strings
      for (const tag of message.tag) {
        // const split_tag = tag.split(':');

        // we should have a label at the front for the property, if we don't then something is very wrong
        // or we have a bug
        if (tag) {
          const prop = tag.tag_label;
          const value = tag.tag_value;
          if (!message[prop]) {
            message[prop] = [];
          }
          if (prop === 'tags') {
            message[prop].push(value);
          } else {
            if (this.choices[prop]) {
              const idx = this.choices[prop].findIndex(c => c.value === value);
              message[prop].push(this.choices[prop][idx]);
            } else if (prop === 'priority') {
              message[prop] = value;
            }

          }
        }
      }
    } else {
      // need to set the tags on the message but have to do it after the message has been sent
      // there should only be 1 priority
      message.tag = []; // clearing the backend tag object
      for (const prop of this.tagProperties) {
        // priority is a string
        if (message[prop] && typeof message[prop] === 'string') {
          message.tag.push(new Tag(prop, message[prop]));
        } else if (message[prop] instanceof Array && message[prop].length > 0) {
          // we assume we have an array
          for (const obj of message[prop]) {
            let value: string;
            if (obj instanceof SelectOption) {
              value = obj.value;
            } else if (typeof obj === 'string') {
              value = obj;
            }
            message.tag.push(new Tag(prop, value));
          }
        }
      }
    }
    return message;
  }

  saveRoomContacts(): Promise<boolean> {
    // if there are no extra contacts then skip
    if (!this.room_contacts || this.room_contacts.length === 0) {
      return Promise.resolve(true);
    }
    const promises: Promise<Contact>[] = [];
    const indexes: number[] = [];

    for (let i = 0; i < this.room_contacts.length; i++) {
      if (
        this.room_contacts[i].contact &&
        this.room_contacts[i].contact.channel_subscription.resource_identifier != null
      ) {
        if (!this.contactService.contacts) {
          // we push the room contact as we should be on the registration page
          promises.push(this.contactService.save(this.room_contacts[i].contact));
          indexes.push(i);
        } else {
          const idx = this.contactService.contacts.findIndex(
            c =>
              c.channel_subscription.resource_identifier === this.room_contacts[i].contact.channel_subscription.resource_identifier
          );
          if (idx === -1 || this.room_contacts[i].edit) {
            promises.push(this.contactService.save(this.room_contacts[i].contact));
            indexes.push(i);
          } else {
            this.room_contacts[i].contact_id = this.contactService.contacts[idx].contact_id;
          }
        }
      }
    }
    return Promise.all(promises)
      .then((results) => {
        if (results && results.hasOwnProperty('length') && results.length > 0) {
          for (let i = 0; i < results.length; i++) {
            // in theory, the indexes array should be the same length as the results returned
            const idx = indexes[i];
            this.room_contacts[idx].contact = results[i];
            this.room_contacts[idx].contact_id = results[i].contact_id;
          }
        }
        return true;
      });
  }

  addContactsToRooms(roomAssignment: RoomAssignment): RoomAssignment {

    // splice out everything else
    roomAssignment.rooms.splice(1);
    // remove all the contacts from the first room as well
    // we should have one in there already
    if (roomAssignment.rooms[0].room_contacts && roomAssignment.rooms[0].room_contacts.length > 0) {
      roomAssignment.rooms[0].room_contacts.splice(1);
    }

    for (const room_contact of this.room_contacts) {
      // if the room_contact doesn't have a number then don't save
      if (room_contact.contact.channel_subscription.resource_identifier) {

        // if room_contact doesn't have a room, add to the first one
        if (!room_contact.room_number) {
          const idx = roomAssignment.rooms[0].room_contacts.findIndex(
            rc =>
              rc.contact.first_name === room_contact.contact.first_name &&
              rc.contact.last_name === room_contact.contact.last_name
          )

          if (idx === -1) {
            roomAssignment.rooms[0].room_contacts.push(room_contact);
          } else {
            roomAssignment.rooms[0].room_contacts[idx] = room_contact;
          }
        } else {
          const idx = roomAssignment.rooms.findIndex(
            ra =>
              ra.room_number === room_contact.room_number
          )
          if (idx > -1) {
            const contact_idx = roomAssignment.rooms[idx].room_contacts.findIndex(
              rc =>
                rc.contact.contact_id === room_contact.contact.contact_id
                || rc.contact_id === room_contact.contact.contact_id
            )
            if (contact_idx > -1) {
              roomAssignment.rooms[idx].room_contacts[contact_idx] = room_contact;
              room_contact.contact_id = room_contact.contact.contact_id;
              roomAssignment.rooms[idx].room_contacts[contact_idx].contact_id = room_contact.contact_id;
            } else {
              room_contact.contact_id = room_contact.contact.contact_id
              roomAssignment.rooms[idx].room_contacts.push(room_contact)
            }
          } else {
            // let's find the first contact by their phone number
            // lets check of we have the contact before we push it on again
            const contactIdx = roomAssignment.rooms.findIndex(
              r =>
                r.room_contacts.findIndex(
                  rc =>
                    rc.contact != null && rc.contact.contact_id == room_contact.contact.contact_id
                ) > -1
            )
            if (contactIdx == -1) {
              const newRoom = new Room(room_contact.room_number, [room_contact]);
              roomAssignment.rooms.push(newRoom);
            }
          }
        }
      }
    }

    return roomAssignment
  }

  ngOnInit() {
  }

}
