import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})

export class StylePicker {
  title = 'style-picker';

  constructor(@Inject(DOCUMENT) private document: Document) { }

  loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];
    const style = this.document.createElement('link');
    style.id = 'client-theme';
    style.rel = 'stylesheet';
    style.href = `${styleName}`;

    head.appendChild(style);
  }
}
