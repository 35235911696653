import { Component, OnInit} from '@angular/core';
import { BaseComponent } from '../../shared/base/base.component';
import { ContactService } from '../../core/services/contact.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'sync-active-campaign',
  templateUrl: 'sync-active-campaign.component.html'
})

export class SyncActiveCampaign extends BaseComponent implements OnInit {
  constructor(
    public contactService: ContactService,
    public dialogRef: MatDialogRef<SyncActiveCampaign>,
  ){
    super(contactService);
  }

  synchronize(){
    this.dialogRef.close(true);
  }

  cancel(){
    this.dialogRef.close(false);
  }
}
