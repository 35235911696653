import { Pipe, PipeTransform } from '@angular/core'
import { environment as env } from '../../../environments/environment'

@Pipe({
  name: 'haveChatIcon'
})
export class HaveChatIconPipe implements PipeTransform {
  // each message has a contant object that has a Pi value with information about the message
  // This has the original channel it came from - this is the Jeeves source, as in the sms source or facebook source
  // And the MSISDN, this is the clients source, so the person sending the sms or the facebook message
  transform(contentPiValue: any): any {
    if (typeof contentPiValue !== 'string') return false

    const piValue = JSON.parse(contentPiValue)
    let haveIcon = false
    if (piValue && piValue['origJeevesMSISDN']) {
      let channelData
      if (typeof piValue['origJeevesMSISDN'] === 'object') channelData = piValue['origJeevesMSISDN']
      else channelData = JSON.parse(piValue['origJeevesMSISDN'])

      const channel = channelData['Channel']
      haveIcon = env.messageIconTypes.indexOf(channel) > -1
    }

    return haveIcon
  }

}

@Pipe({
  name: 'chatIcon'
})
export class ChatIconPipe implements PipeTransform {
  // each message has a contant object that has a Pi value with information about the message
  // This has the original channel it came from - this is the Jeeves source, as in the sms source or facebook source
  // And the MSISDN, this is the clients source, so the person sending the sms or the facebook message
  transform(contentPiValue: any): any {
    if (typeof contentPiValue !== 'string') return false

    const piValue = JSON.parse(contentPiValue)
    let imagePath = ''
    if (piValue && piValue['origJeevesMSISDN']) {
      let channelData
      if (typeof piValue['origJeevesMSISDN'] === 'object') channelData = piValue['origJeevesMSISDN']
      else channelData = JSON.parse(piValue['origJeevesMSISDN'])

      const channel = channelData['Channel']
      if (env.messageIconTypes.indexOf(channel) > -1) imagePath = env.socialIconPath.replace('{0}', channel.toLowerCase())
    }

    return imagePath ? imagePath : ''
  }

}

@Pipe({
  name: 'chatIconAlt'
})
export class ChatIconAltPipe implements PipeTransform {
  // each message has a contant object that has a Pi value with information about the message
  // This has the original channel it came from - this is the Jeeves source, as in the sms source or facebook source
  // And the MSISDN, this is the clients source, so the person sending the sms or the facebook message
  transform(contentPiValue: any): any {
    if (typeof contentPiValue != 'string') {
      return false
    }
    const piValue = JSON.parse(contentPiValue)
    let altText = ''
    if (piValue && piValue['origJeevesMSISDN']) {
      let channelData
      if (typeof piValue['origJeevesMSISDN'] === 'object') channelData = piValue['origJeevesMSISDN']
      else channelData = JSON.parse(piValue['origJeevesMSISDN'])

      const channel = channelData['Channel']
      if (env.messageIconTypes.indexOf(channel) > -1) altText = env.messageIconTypesAlt[channel]
    }

    return altText
  }

}
