import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GuestPanelComponent } from './guest-panel.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { IntlModule } from '@progress/kendo-angular-intl';
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from '../core/core.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  imports: [
    CommonModule,
    DropDownsModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    IntlModule,
    HttpClientModule,
    DateInputsModule,
    CoreModule,
    NgbModule
  ],
  declarations: [
    GuestPanelComponent
  ],
  exports: [
    GuestPanelComponent
  ]
})
export class GuestPanelModule { }
