import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageQueueComponent } from "./message-queue.component";
import { CoreModule } from "../core/core.module";
import { FormsModule } from "@angular/forms";
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    CoreModule
  ],
  declarations: [
    MessageQueueComponent
  ],
  exports: [
    MessageQueueComponent
  ]
})
export class MessageQueueModule { }
