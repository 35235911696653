import { Note } from '../../shared/note.model';
import { SelectOption } from "./select-option.model";

export class Tag {
  tag_label: string;
  tag_value: string;

  constructor(
    tag_label?: string,
    tag_value?: string
  ) {
    this.tag_label = tag_label;
    this.tag_value = tag_value;
  }
}

export class Contact {
  list_id: number;
  contact_id: string;
  last_name: string;
  first_name: string;
  active: boolean;
  last_update_date: Date;
  channel_subscription: ChannelSubscription;
  notes: Note[];
  contact_level: string;
  create_date: Date;
  site_id: string;
  tags: Tag[];
  languages: SelectOption[];
  attributes: string[];
  AgeCheck: ageCheck;
  ActiveCampaign: activeCampaign;
  email_id : string

  public static toContact(obj: any): Contact {
    const keys = Object.keys(obj);
    const contact = new Contact();
    for (const key of keys) {
      if (contact[key] instanceof Date && typeof obj[key] === 'string') {
        contact[key] = new Date(obj[key]);
      } else if (typeof obj[key] === 'string') {
        contact[key] = obj[key].trim();
      } else {
        contact[key] = obj[key];
      }
    }
    contact.initialize();
    return contact;
  }

  private initialize(): void {
    if (this instanceof Contact) {
      this.tags = this.tags || [];
      this.languages = this.languages || [];
      this.attributes = this.attributes || [];

    }
  }

  constructor(
    list_id?: number,
    contact_id?: string,
    last_name?: string,
    first_name?: string,
    active?: boolean,
    last_update_date?: Date,
    channel_subscription?: ChannelSubscription,
    notes?: Note[],
    contact_level?: string,
    create_date?: Date,
    site_id?: string,
    tags?: Tag[],
    languages?: SelectOption[],
    attributes?: string[],
    AgeCheck?: ageCheck,
    ActiveCampaign?: activeCampaign,
    email_id?: string
  ) {
    this.list_id = list_id;
    this.contact_id = contact_id;
    this.last_name = last_name;
    this.first_name = first_name;
    this.active = active || true;
    this.last_update_date = last_update_date;
    this.channel_subscription = channel_subscription || new ChannelSubscription();
    this.notes = notes || [];
    this.contact_level = contact_level;
    this.create_date = create_date;
    this.site_id = site_id;
    this.tags = tags;
    this.languages = languages || [];
    this.attributes = attributes || [];
    this.AgeCheck = AgeCheck || new ageCheck();
    this.ActiveCampaign = ActiveCampaign || new activeCampaign();
    this.email_id = email_id
  }
}

export class ChannelSubscription {
  channel_identifier: string;
  resource_identifier: string;
  shopifyVerified: Boolean;
  shopify: Boolean;

  constructor(
    channel_identifier?: string,
    resource_identifier?: string
  ) {
    this.channel_identifier = channel_identifier;
    this.resource_identifier = resource_identifier;
  }
}

export class ageCheck {
  CheckStatus: string;
  constructor(CheckStatus?: string) {
    this.CheckStatus = CheckStatus;
  }
}

export class activeCampaign {
  id: number;
  constructor(id?: number) {
    this.id = id;
  }
}