import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class ShowNavService {

  showNav: boolean;
  showNavUpdated: EventEmitter<boolean> = new EventEmitter();

  constructor() {
    // this.showNav = true;

  }

  set(value: boolean) {
    this.showNav = value;
    this.showNavUpdated.emit(this.showNav);
  }

  get(): boolean {
    return this.showNav;
  }

}
