import { DirectiveDecorator, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from './services/auth.service';
import { MessageService } from './services/message.service';
import { MomentModule } from 'angular2-moment';
import { OperatorService } from './services/operator.service';
import { AuthenticationService } from './services/authentication.service';
import { TokenService } from './services/token.service';
import { ClickStopPropagationDirective } from './directives/click-stop-propagation.directive';
import { JqDropdownDirective } from './directives/jq-dropdown.directive';
import { ConstantsService } from './services/constants.service';
import { QueueModule } from './queue/queue.module';
import { SiteService } from './services/site.service';
import { RoomAssignmentService } from './services/room-assignment.service';
import { ContactService } from './services/contact.service';
import { StorageService } from './services/storage.service';
import { LanguagePipe } from './pipes/language.pipe';
import { AccessGuard } from './shared/access.guard';
import { UserService } from './services/user.service';
import { LoggerService } from './services/logger.service';
import { HumanReadablePipe } from './pipes/human-readable.pipe';
import { CompareToDirective } from './directives/compare-to.directive';
import { FormatPhonePipe } from './pipes/phone.pipe';
import { DateDiffPipe } from './pipes/date-diff.pipe';
import { RegistrationService } from './services/registration.service';
import { FilterByPipe } from './pipes/filter-by.pipe';
import { ImageSrcPipe } from './pipes/image-src.pipe';
import { DateFormatCustomPipe } from './pipes/date-format-custom.pipe';
import { TagService } from './services/tag.service';
import { PhoneIsUniqueDirective } from './validators/phone-is-unique.directive';
import { IsEdittingPipe } from './pipes/is-editting.pipe';
import { ContainsPipe } from './pipes/contains.pipe';
import { HotelService } from './services/hotel.service';
import { HttpClientModule } from "@angular/common/http";
import { TimeInQueuePipe } from './pipes/time-in-queue.pipe';
import { HaveChatIconPipe, ChatIconPipe, ChatIconAltPipe } from './pipes/chat-icon.pipe';
import { PhoneIsValidDirective } from './validators/phone-is-valid.directive';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    MomentModule,
    QueueModule,
  ],
  declarations: [
    ClickStopPropagationDirective,
    JqDropdownDirective,
    LanguagePipe,
    HumanReadablePipe,
    CompareToDirective,
    FormatPhonePipe,
    DateDiffPipe,
    FilterByPipe,
    ImageSrcPipe,
    DateFormatCustomPipe,
    PhoneIsUniqueDirective,
    IsEdittingPipe,
    ContainsPipe,
    TimeInQueuePipe,
    HaveChatIconPipe,
    ChatIconPipe,
    ChatIconAltPipe,
    PhoneIsValidDirective,
  ],
  providers: [
    AuthService,
    MessageService,
    OperatorService,
    AuthenticationService,
    TokenService,
    ConstantsService,
    SiteService,
    RoomAssignmentService,
    ContactService,
    StorageService,
    UserService,
    LoggerService,
    RegistrationService,
    AccessGuard,
    HumanReadablePipe,
    TagService,
    HotelService,
    FilterByPipe
  ],
  exports: [
    ClickStopPropagationDirective,
    JqDropdownDirective,
    LanguagePipe,
    HumanReadablePipe,
    DateDiffPipe,
    FilterByPipe,
    ImageSrcPipe,
    DateFormatCustomPipe,
    PhoneIsUniqueDirective,
    IsEdittingPipe,
    FormatPhonePipe,
    ContainsPipe,
    TimeInQueuePipe,
    HaveChatIconPipe,
    ChatIconPipe,
    ChatIconAltPipe,
    PhoneIsValidDirective
  ]
})
export class CoreModule { }
