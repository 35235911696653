import { Pipe, PipeTransform } from '@angular/core';
import { SelectOption } from "../models/select-option.model";

@Pipe({
  name: 'language'
})
export class LanguagePipe implements PipeTransform {

  transform(code: string, languages?: SelectOption[]): any {
    if (!code) {
      return;
    }
    if (code === 'en-us') {
      code = 'en';
    }

    if (code === 'zh-CN') {
      code = 'zh';
    }

    if (!languages || languages.length === 0) {
      return code;
    }

    const lang = languages.find(l => l.value === code);
    return lang == null ? '' : lang.text;
  }

}
