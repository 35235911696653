import { Pipe, PipeTransform } from '@angular/core';
import { User } from "../../shared/user.model";

@Pipe({
  name: 'imageSrc'
})
export class ImageSrcPipe implements PipeTransform {

  defaultImage = 'assets/images/user_pic_1.png';
  transform(id: any, args?: User[]): any {
    if (!id || !args || args.length === 0) {
      return this.defaultImage;
    }

    let ret_val: any;

    if (args[0] === Object(args[0])) {
      ret_val = args.find(a => a.id == id);
    } else {
      ret_val = args.find(a => a === id);
    }
    return !ret_val || !ret_val.profile_image ? this.defaultImage : ret_val.profile_image;
  }

}
