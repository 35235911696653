import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Auth } from '../models/auth.model';
import { environment as env } from '../../../environments/environment'
import { ExpiryModel } from '../models/expiry.model';

@Injectable()
export class AuthenticationService {

  constructor() { }

  setAccessToken(token: Auth): void {
    if (!token) return;
    token.date_added = moment().toDate();
    localStorage.setItem(env.storage_accessor, JSON.stringify(token));
  }

  clearToken(): void {
    localStorage.removeItem(env.storage_accessor);
  }

  getToken(): Auth {
    let access_token = localStorage.getItem(env.storage_accessor);
    if (access_token != null) {
      const idx = access_token.indexOf('{');
      if (idx === -1) {
        this.clearToken();
        access_token = undefined;
      }
    }
    return access_token == null ? undefined : JSON.parse(access_token);
  }

  getExpiry(): ExpiryModel {
    const token = this.getToken();
    if (token != null) {
      return new ExpiryModel(token.date_added, token.expires_in);
    }
    return null;
  }

  expiresWithin(seconds: number): boolean {
    const expiry: ExpiryModel = this.getExpiry();
    if (expiry.date_added == null) {
      return true;
    }
    const expireDateTime = moment(expiry.date_added).add(expiry.expires_in, 'seconds');
    return expireDateTime.isBefore(moment().add(seconds, 'seconds'));
  }

  accessExpiresWithin(seconds: number): boolean {
    const expiry: ExpiryModel = this.getExpiry();
    if (expiry.date_added == null) {
      return true;
    }
    let expireDateTime = moment(expiry.date_added).add(env.refresh_token_timeout, 'seconds');
    return expireDateTime.isBefore(moment().add(seconds, 'seconds'));
  }

  isAuthenticated(): boolean {
    const token = this.getToken();
    return token != null && !this.isExpired(token);
  }

  isExpired(token: Auth): boolean {
    if (!token || !token.date_added) return true;
    const now = moment();
    const token_datetime = moment(token.date_added);
    return !token.date_added && now.diff(token_datetime, 'seconds') >= token.expires_in;
  }

  isRefreshExpired(token: Auth): boolean {
    if (!token) return true;
    const now = moment();
    const refresh_added = moment(token.date_added);
    return !token.date_added && now.diff(refresh_added, 'seconds') >= env.refresh_token_timeout;
  }
}
