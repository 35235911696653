import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatPhone'
})
export class FormatPhonePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value || typeof value !== 'string' || value.indexOf(' ') > -1) {
      return value;
    }

    if (value[0] === '0') {
      // if the first digit is a 0
      return value.replace(/(\d{4})(\d{3})(\d{3})/, '$1 $2 $3');
    }

    return null;
  }

}
