import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { StartupService } from '../startup/startup.service';
import { environment as env } from '../../../environments/environment';
import { OperatorService } from '../services/operator.service';
import { catchError, map } from "rxjs/operators";

@Injectable()
export class AccessGuard implements CanActivate {
  defaultExpiry = env.expires_within;
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private startupService: StartupService,
    private operatorService: OperatorService
  ) {

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | Promise<boolean> | boolean {
    const requiresLogin = next.data.requiresLogin || false;
    const token = this.authenticationService.getToken();
    const keepLogin = this.operatorService.keepLogin;

    if (requiresLogin && !keepLogin) {
      if (token && (!this.authenticationService.isExpired(token) ||
        !this.authenticationService.expiresWithin(this.defaultExpiry))) {
        return this.startupService.load().then(() => {
          // this.router.navigate(['operator']);
          return true;
        })
          .catch(
            (err) => {
              // log the error
              // just incase there is a different kind of error
              return this.operatorService.logout()
                .then((res) => {
                  return this.router.navigate(['login']);
                  // return true;
                  // return false;
                })
                .catch((sub_err) => {
                  console.log(sub_err);
                  return this.router.navigate(['login']);
                  // return false;
                });
            });
      } else {
        // logout regardless if we successfully logour of the api or not
        // the logout service should take care of deleting the key
        return this.operatorService.logout()
          .then((res) => {
            return this.router.navigate(['login']);
            // return false;
          })
          .catch((err) => {
            console.log(err);
            return this.router.navigate(['login']);
            // return false;
          });
      }
    } else {
      return true;

    }
  }
}
