
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseDjangoService } from './base-django.service';
import { AuthenticationService } from './authentication.service';
import { IBaseService } from '../interfaces/base-interface';
import { Registration, RoomDetail } from '../models/registration.model';
import { RoomAssignment } from '../models/room-assignment.model';
import { environment as env } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable()
export class RegistrationService extends BaseDjangoService implements IBaseService {

  SIMPLE_FORM_TYPE = env.simple_form_type;

  constructor(
    authenticationService: AuthenticationService,
    private http: HttpClient
  ) {
    super(authenticationService);
    this.apiUrl += '/registration';
  }

  getByParam(value: string, key?: string, useFilter?: boolean) {
  }


  save(registration: Registration, registration_id: string, guest_type: string): Promise<RoomAssignment> {
    let url = this.apiUrl;
    let payload: string;
    const regPayload = { ...registration }
    regPayload.primary_contact = this.packTags(registration.primary_contact);
    regPayload.secondary_contact = this.packTags(registration.secondary_contact);
    regPayload.room_details = RoomDetail.fromRoomDetail(registration.room_details);
    const options = this.getOptions();

    url += '/' + registration_id;
    let params: HttpParams;
    if (guest_type) {
      // have to do it this way because the resulting HttpParams is immutable, so have to chain it directly after creation
      params = new HttpParams()
        .set('type', guest_type);
      // url += '?type=' + guest_type;
      if (guest_type === this.SIMPLE_FORM_TYPE) {
        delete registration.primary_contact
      }
    }
    if (params) {
      options['params'] = params;
    }
    payload = JSON.stringify(registration);

    return this.http.post(url, payload, options)
      .toPromise()
      .then((res) => {
        let result = res;
        if (result.hasOwnProperty('length')) {
          // for some stupid reason Put returns a list of objects
          // whereas post returns one
          result = result[0] as RoomAssignment;
        } else {
          result = result as RoomAssignment;
        }
        let ret_contact: RoomAssignment;
        if (result) {
          ret_contact = this.unpackTags(result);
        }
        return ret_contact;
      })
      .catch((err) => observableThrowError(err).toPromise());
  }


}
