import { Injectable } from '@angular/core';
import { environment as env } from '../../../environments/environment'
import { Tag } from '../models/contact.model';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class BaseService {
  apiUrl: string;
  subRoute: string;
  options: object;
  defaultSearchParam: string;
  tagProperties: string[] = ['languages', 'opera_validated', 'attributes'];
  singleProp: string[] = ['opera_validated'];

  constructor() {
    this.apiUrl = env.api;
    const headers = this.getHeaders();
    this.options = { headers: headers };
    this.subRoute = '';
  }

  private getHeaders(): HttpHeaders {
    // return new Headers(env.jeeves_headers);
    const headers = new HttpHeaders();
    headers.append('Authorization', env.jeeves_headers.Authorization);
    headers.append('Accept', 'application/json');
    // headers.append('Content-Type', 'application/json');

    return headers;
  }

  packTags(contact: any): any {
    contact.tags = [];
    for (const prop of this.tagProperties) {
      if (contact[prop] && typeof contact[prop] === 'object') {
        // lets assume string only properties for now
        for (const value of contact[prop]) {
          contact.tags.push(
            new Tag(prop, JSON.stringify(value))
          );
        }
      }
    }
    return contact;
  }

  unpackTags(contact: any): any {
    for (const prop of this.tagProperties) {
      if (this.singleProp.indexOf(prop) === -1) {
        contact[prop] = [];
      }
    }
    if (contact.tags) {
      for (const tag of contact.tags) {
        if (this.singleProp.indexOf(tag.tag_label) === -1) {
          if (!contact[tag.tag_label]) {
            contact[tag.tag_label] = [];
          }
          contact[tag.tag_label].push(JSON.parse(tag.tag_value));
        } else {
          contact[tag.tag_label] = JSON.parse(tag.tag_value);
        }

        // lets assume string only properties for now
      }
    }

    contact.tags = [];
    return contact;
  }

  // get(): Promise<any> {
  //
  //   return this.http.get(this.apiUrl + '/roles/')
  //     .toPromise()
  //     .then((res) => res)
  //     .catch((error: any) => Observable.throw(error.error || 'Server Error'));
  // }
  //
  // put(): Promise<any> {
  //   return;
  // }
  //
  // post(): Promise<any> {
  //   return;
  // }
  //
  // delete(): void {
  //   return;
  // }
  //
  // list(): Promise<any[]> {
  //   return;
  // }

}
