import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { BaseService } from "../services/base.service";
import { BaseDjangoService } from "../services/base-django.service";
import { AuthenticationService } from "../services/authentication.service";

@Injectable()
export abstract class QueueService extends BaseDjangoService {
  stopPolling: Subject<void> = new Subject();
  retrying: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  pendingRequest: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  retryTimerValue: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  maxRetries = 3;
  DEFAULT_MAX_RETRIES = 3;
  retries: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  forceLogout: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(authenticationService: AuthenticationService) {

    super(authenticationService);
  }

  resetMaxRetries() {
    this.maxRetries = this.DEFAULT_MAX_RETRIES;
  }

  resetRetries() {
    this.retries.next(0);
  }

  abstract pull(url: string): Observable<any>;
  abstract push(obj: any);

}
