
import { interval as observableInterval, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment as env } from '../../../environments/environment'
import { Auth } from '../models/auth.model';
import { AuthenticationService } from './authentication.service';
import { BaseDjangoService } from './base-django.service';
import { UserService } from './user.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable()
export class TokenService extends BaseDjangoService {
  auth_api: string = env.auth_api;
  payload: object;
  getToken = false;
  defaultExpiry: number = env.default_expiry;
  // allow the refresh to happen 30 seconds before the actual timeout of the token
  private intevalTime = env.refresh_token_timeout - 30;

  constructor(private http: HttpClient,
    private authenticationService: AuthenticationService,
    private userService: UserService) {
    super(authenticationService);
  }

  remove(): void {
    const t = this.authenticationService.getToken();
    console.log(t);
    
    if (t) {
      this.authenticationService.clearToken();
    }
  }

  obtain(username: string, password: string): Promise<Auth> {
    this.payload = {
      username: username,
      password: password,
      grant_type: 'password'
    };
    this.getToken = true;
    const body = new URLSearchParams();
    body.append('username', username);
    body.append('password', password);
    body.append('grant_type', 'password');
    // const serializedParams = new HttpParamSerializerJQLike
    const headers = new HttpHeaders(env.auth_headers);
    const options = { headers: headers };
    let ret_token: Auth;
    return this.http.post(this.auth_api, body.toString(), options)
      .toPromise()
      .then((res) => {
        const token = res as Auth;
        let promise;
        ret_token = token;
        if (token) {
          this.authenticationService.setAccessToken(token);
          promise = this.userService.getCurrentUser();
        } else {
          promise = Promise.resolve();
        }
        this.getToken = false;
        return promise;
      }).then(
        (user) => {
          this.getToken = false;
          if (user) {
            // do something with user
          }
          return ret_token;
        })
      .catch(
        (error: any) => {
          this.getToken = false;
          return Promise.reject(error)
        });
  }

  refresh(): Promise<Auth> {
    const t = this.authenticationService.getToken();
    if (!t) {
      return Promise.reject('no token found');
    } else if (this.authenticationService.isRefreshExpired(t)) {
      return Promise.reject('refresh token has expired');
    }
    this.getToken = true;

    let ret_token: Auth;
    const body = new URLSearchParams();
    body.append('client_id', env.client_id);
    body.append('client_secret', env.client_secret);
    body.append('grant_type', 'refresh_token');
    body.append('refresh_token', t.refresh_token);
    const headers = new HttpHeaders(env.auth_headers);
    const options = { headers: headers };
    return this.http.post(this.auth_api, body.toString(), options)
      .toPromise()
      .then((res) => {
        const token = res as Auth;
        let promise;
        ret_token = token;
        if (token) {
          this.authenticationService.setAccessToken(token);
          promise = this.userService.getCurrentUser();
        } else {
          promise = Promise.resolve();
        }
        this.getToken = false;
        return promise;
      }).then(
        (user) => {
          if (user) {
            // do something with user
          }
          this.getToken = false;
          return ret_token;
        })
      .catch(
        (error: any) => {
          this.getToken = false;
          return Promise.reject(error.error || 'Server Error');
        });
  }

  autoRefresh(): Observable<object> {
    return observableInterval(this.intevalTime).pipe(
      switchMap(() => {
        const t = this.authenticationService.getToken();
        if (!t) {
          return Promise.reject('no token found');
        } else if (this.authenticationService.isRefreshExpired(t)) {
          return Promise.reject('refresh token has expired');
        } else if (!this.authenticationService.accessExpiresWithin(this.defaultExpiry)) {
          return Promise.resolve(t);
        }
        this.getToken = true;
        let ret_token: Auth;
        const body = new URLSearchParams();
        body.append('client_id', env.client_id);
        body.append('client_secret', env.client_secret);
        body.append('grant_type', 'refresh_token');
        body.append('refresh_token', t.refresh_token);
        const headers = new HttpHeaders(env.auth_headers);
        const options = { headers: headers };
        return this.http.post(this.auth_api, body.toString(), options)
          .toPromise()
          .then((res) => {
            const token = res as Auth;
            let promise;
            ret_token = token;
            if (token) {
              this.authenticationService.setAccessToken(token);
              promise = this.userService.getCurrentUser();
            } else {
              promise = Promise.resolve();
            }
            this.getToken = false;
            return promise;
          }).then(
            (user) => {
              if (user) {
                // do something with user
              }
              this.getToken = false;
              return ret_token;
            })
          .catch(
            (error: any) => {
              this.getToken = false;
              return Promise.reject(error.error || 'Server Error')
            });
      }));
  }


}
