import { Injectable } from '@angular/core'
import * as _ from 'lodash'
import { environment as env } from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  defaultNameKey = 'defaultClient'

  constructor() {
  }

  toCamelCase(str: string): string {
    if (!str) return ''

    return str.toLowerCase()
      .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
  }

  getCompanyCodeFromUrl(url: string): string {
    if (!url) return ''
    const code = url.split('.')[0]
    return _.kebabCase(code)
  }

  getLogoPath(companyCode: string): string {
    let logoPath = ''

    if (companyCode in env.client_mappings) {
      logoPath = env.logoPath.replace('{0}', companyCode)
    }
    return logoPath
  }

  getLogoIconPath(companyCode: string): string {
    let logoPath = ''

    if (companyCode in env.client_mappings) {
      logoPath = env.logoIconPath.replace('{0}', companyCode)
    }
    return logoPath
  }

  getClientMapping(companyCode: string) {
    // if we don't have a hostname, the main app, staging ot dev then we return a blank string
    if (!companyCode) return { clientName: '', logoPath: '', companyCode: 'jeeves' }
    const clientNameKey = companyCode
    const clientNameMappings = env.client_mappings
    if (!(companyCode in clientNameMappings)) companyCode = 'jeeves'

    const client = clientNameKey in clientNameMappings ?
      clientNameMappings[clientNameKey] :
      clientNameMappings[this.defaultNameKey]
    return {
      clientName: client.name,
      logoPath: this.getLogoPath(companyCode),
      faviconPath: this.getLogoIconPath(companyCode),
      orgType: client.orgType,
      textString: client.textString,
      phone: client.phone,
      companyCode: companyCode
    }
  }

}
