export class SiteRemoteAsset {
  asset_link: string;
  asset_tag: string;
}

export class SiteGuestAttributeTemplate {
  attribute_has_value: boolean;
  attribute_name: string;
  attribute_value?: boolean;
}

export class Content {
  static DEFAULT_PI_VAL = "{\"origDataType\":\"0\",\"origLang\":\"en\"}";
  data: string;
  encoding: string;
  lang: string;
  pi: string;
  mime_type: string;

  constructor(
    data?: string,
    encoding?: string,
    lang?: string,
    pi?: string,
    mime_type?: string
  ) {
    this.data = data;
    this.encoding = encoding;
    this.lang = lang;
    this.pi = pi || Content.DEFAULT_PI_VAL;
    this.mime_type = mime_type;
  }

}

export class ParentMessageTemplate {
  category: string;
  message: Content;
}

export class SiteBulkMessageTemplate extends ParentMessageTemplate {
  label: string;
}

export class SiteAutoResponderMessage extends ParentMessageTemplate {
  keyword: string;
  enabled: boolean;
}

export class SiteOfficialLink {
  link: string;
  label: string;
}

export class Site {
  last_update_date: Date;
  site_remote_assets: SiteRemoteAsset[];
  site_guest_attribute_template: SiteGuestAttributeTemplate[];
  site_bulk_message_templates: SiteBulkMessageTemplate[];
  site_official_responses: SiteBulkMessageTemplate[];
  site_auto_responder: SiteAutoResponderMessage[];
  create_date: Date;
  site_id: string;
  site_official_links: SiteOfficialLink[];
  site_name: string;
}
