import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module'
import { GuestComponent } from './guest/guest.component';
import { RegisterComponent } from './register.component';
import { FormsModule } from '@angular/forms';
import { GuestViewService } from './guest/guest-view.service';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { IntlModule } from '@progress/kendo-angular-intl';
import { IntlInputPhoneModule } from 'intl-input-phone';
import { HttpClientModule } from '@angular/common/http';
import { ElModule } from 'element-angular';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CoreModule,
    IntlModule,
    HttpClientModule,
    DateInputsModule,
    IntlInputPhoneModule,
    ElModule.forRoot()
  ],
  providers: [
    GuestViewService
  ],
  declarations: [
    RegisterComponent,
    GuestComponent,
    ThankYouComponent
  ]
})
export class RegisterModule { }
