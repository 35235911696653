import { Directive, OnInit, AfterViewInit, ViewChild, ElementRef, Input, NgZone } from '@angular/core';
import * as $ from 'jquery';
// import 'jquery.dropdown';

// require('jquery.dropdown');
window["$"] = $;
window["jQuery"] = $;

@Directive({
  selector: '[appJqDropdown]'
})
export class JqDropdownDirective implements OnInit, AfterViewInit {

  @Input('appJqDropdown') jqTarget;

  constructor(private el: ElementRef,
    private zone: NgZone) {
  }

  // $jqDropdown: JQuery | any;

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.zone.runOutsideAngular(() => {
      console.log(this.jqTarget);

      let obj = $(this.el.nativeElement);
      // TODO: need to figure out how to use jquery plugins here
      // obj.jqDropdown('attach', [this.jqTarget]);
      obj.jqDropdown('attach');
    })

    //console.log(jQuery.fn.jquery);
  }
}
