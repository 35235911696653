import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShowNavService } from './show-nav.service';
import { BaseComponent } from './base/base.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [BaseComponent],
  providers: [
    ShowNavService
  ]
})
export class SharedModule { }
