import { Directive, Inject, Injectable, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, NgModel, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { ContactService } from "../services/contact.service";
import { Contact } from "../models/contact.model";

@Directive({
  selector: '[appPhoneIsUnique]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: PhoneIsUniqueDirective,
      multi: true
    }
  ]
})

export class PhoneIsUniqueDirective implements Validator {
  @Input() appPhoneIsUnique: string;

  constructor() {
  }

  validate(c: AbstractControl): { [key: string]: any } {
    return this.appPhoneIsUnique ? CompareToValidator(this.appPhoneIsUnique)(c)
      : null;
  }

  registerOnValidatorChange(fn: () => void): void {
    console.log(Error("Method not implemented."));
  }


}


export function CompareToValidator(value: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const is_equal = value === control.value;
    console.log(value, control.value);
    return is_equal ? { 'appPhoneIsUnique': { value: control.value } } : null;
  };
}
