import { User } from "./user.model";
import { post } from "selenium-webdriver/http";

export class Note {
  id: number;
  note_status: boolean;
  note_text: NoteText;
  note_date: Date;
  user: User;
  posted_by: string;
  edit: boolean;

  constructor(
    id?: number,
    note_text?: NoteText,
    note_status?: boolean,
    note_date?: Date,
    user?: User,
    posted_by?: string,
    edit?: boolean
  ) {
    this.id = id;
    this.note_text = note_text || new NoteText();
    this.note_status = note_status || true;
    this.note_date = note_date;
    this.user = user;
    this.posted_by = posted_by;
    this.edit = edit;
  }
}


export class NoteText {
  mime_type: string;
  data: string;
  encoding: string;
  lang: string;
  constructor(
    mime_type?: string,
    data?: string,
    encoding?: string,
    lang?: string
  ) {
    this.mime_type = mime_type;
    this.data = data;
    this.encoding = encoding;
    this.lang = lang;
  }
}
