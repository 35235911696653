import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isEditting'
})
export class IsEdittingPipe implements PipeTransform {

  transform(list: any): any {
    if (!list || !list.hasOwnProperty('length') || list.length === 0) {
      return false;
    }
    let isEdit: boolean;
    for (const item of list) {
      isEdit = item && item.edit;
      if (isEdit) {
        break;
      }
    }
    return isEdit;
  }

}
