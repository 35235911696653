import { Component, Inject, OnInit } from '@angular/core';
import { OperatorService } from '../core/services/operator.service';
import { TokenService } from '../core/services/token.service';
import { Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
  timeRemaining: number;
  showCounterDown: true;
}

@Component({
  selector: 'app-operator-login-session-popup',
  templateUrl: './operator-login-session-popup.component.html',
  styleUrls: ['./operator-login-session-popup.component.css']
})
export class OperatorLoginSessionPopupComponent {

  constructor(
    private operatorService: OperatorService,
    private tokenService: TokenService,
    private router: Router,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<OperatorLoginSessionPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

//   stopTimer(){
//     this.dialog.closeAll()
//   }

//   logout(event?: any): void {
//     if (event) {
//       event.preventDefault()
//     }
//     this.operatorService.logout$()
//       .subscribe(
//         (res) => {
//           if (res) {
//             this.tokenService.remove()
//             // this.router.navigate(['login'])
//             location.href="/login"
//           }
//       })
//   }
}
