import { SiteGuestAttributeTemplate } from "./site.model";
import { Contact } from "./contact.model";
import { Note } from "../../shared/note.model";
import { SelectOption } from "./select-option.model";
import * as _ from "lodash";

export enum RoomAssignmentStatus {
  checkedin = 'checked-in' as any,
  checkedout = 'checked-out' as any
}

export class GuestAttributes extends SiteGuestAttributeTemplate {
  display_name?: string;

  constructor(
    hotel_type?: string,
    attribute_name?: string,
    attribute_value?: boolean,
    attribute_has_value?: boolean,
    display_name?: string
  ) {
    super();
    // this.attribute_name = attribute_name || (hotel_type == null || hotel_type !== 'hotel' ? 'CRM' : 'OperaVerified');
    this.attribute_name = 'CRM';
    this.attribute_value = attribute_value || false;
    this.attribute_has_value = attribute_has_value || true;
    // this.display_name = display_name || (hotel_type == null || hotel_type !== 'hotel' ? 'CRM' : 'Opera Verified');
    this.display_name = 'CRM';
  }

  private static toCapitalizedWords(name: string): string {
    const words = name.match(/[A-Za-z][a-z]*/g);

    return words.map(this.capitalize).join(' ');
  }

  private static capitalize(word: string): string {
    return word.charAt(0).toUpperCase() + word.substring(1);
  }

  public static toGuestAttributes(obj: any): GuestAttributes {
    const keys = Object.keys(obj);
    const guestAttributes = new GuestAttributes();
    for (const key of keys) {
      if (key === 'attribute_name') {
        if (obj[key] == 'Opera Verified' || obj[key] == 'OperaVerified') {
          obj[key] = 'CRM';
        }

        guestAttributes['display_name'] = this.toCapitalizedWords(obj[key]);
        if (guestAttributes['display_name'] == 'Opera Verified') {
          guestAttributes['display_name'] = 'CRM';
        }
      }

      guestAttributes[key] = obj[key];
    }
    return guestAttributes;
  }
}

export class RoomContact {
  contact_id: string;
  contact: Contact;
  special_instructions: string;
  guest_attributes: GuestAttributes[];
  room_number: string;
  edit: boolean;
  // languages: SelectOption[];
  constructor(
    contact_id?: string,
    contact?: Contact,
    special_instructions?: string,
    guest_attributes?: GuestAttributes[]
  ) {
    this.contact_id = contact_id;
    this.contact = contact || new Contact();
    this.special_instructions = special_instructions;
    this.guest_attributes = guest_attributes || [new GuestAttributes()];
    this.edit = true;
  }

}

export class Room {
  room_number: string;
  room_contacts: RoomContact[];
  constructor(
    room_number?: string,
    room_contacts?: RoomContact[]
  ) {
    this.room_number = room_number;
    this.room_contacts = room_contacts || [new RoomContact()];
  }
}

export class RoomAssignment {
  parent: RoomAssignment;
  roomassignment_id: string;
  roomassignment_status: RoomAssignmentStatus;
  primary_contact_hint: string;
  checkin_date: Date;
  checkout_date: Date;
  create_date: Date;
  rooms: Room[];
  notes: Note[] = [];
  languages: SelectOption[];
  opera_validated: boolean;
  active: boolean;
  is_primary: boolean;
  last_update_date: Date;
  static dateObjectList: string[] = ['checkin_date', 'checkout_date'];

  public static toRoomAssignment(obj: any): RoomAssignment {
    // const keys = Object.keys(obj);
    const roomAssignment = new RoomAssignment();
    const keys = Object.keys(roomAssignment);

    for (const key of keys) {
      if ((roomAssignment[key] instanceof Date && typeof obj[key] === 'string') ||
        this.dateObjectList.indexOf(key) > -1) {
        if (obj[key] === 0) {
          roomAssignment[key] = null;
        } else {
          roomAssignment[key] = new Date(obj[key]);
        }
      } else {
        if (key === 'is_primary') {
          if (obj[key] == null) {
            roomAssignment[key] = true;
          } else {
            roomAssignment[key] = obj[key];
          }
        } else {
          roomAssignment[key] = obj[key];
        }
      }
    }
    if (roomAssignment.rooms && roomAssignment.rooms.length > 0) {
      // now we update the guest_attributes as well
      for (const room of roomAssignment.rooms) {
        for (const room_contact of room.room_contacts) {
          for (const idx in room_contact.guest_attributes) {
            room_contact.guest_attributes[idx] = GuestAttributes.toGuestAttributes(room_contact.guest_attributes[idx]);
          }
        }
      }
    }

    return roomAssignment;
  }

  public static fromRoomAssignment(obj: any): RoomAssignment {
    const keys = Object.keys(obj);
    const roomAssignment = new RoomAssignment();
    for (const key of keys) {

      if ((obj[key] === 'string') ||
        this.dateObjectList.indexOf(key) > -1) {
        roomAssignment[key] = new Date(obj[key]).getTime();
      } else {
        if (key === 'active') {
          if (obj[key] == null) {
            roomAssignment[key] = true;
          } else {
            roomAssignment[key] = obj[key];
          }
        } else {
          roomAssignment[key] = obj[key];
        }
      }
    }
    return roomAssignment;
  }

  public static toSecondaryRoomAssignment(roomAssignment: RoomAssignment, contact: Contact): RoomAssignment {
    if (!contact || contact == null) {
      return roomAssignment;
    }
    // so we get all the details of the room assignments - i.e. checkin and checkout dates
    const ret_val = _.cloneDeep(roomAssignment);
    ret_val.parent = _.cloneDeep(roomAssignment);
    // first we find the room our contact is in
    const new_room = roomAssignment.rooms.find(room => {
      let found: boolean;
      for (const room_contact of room.room_contacts) {
        found = room_contact.contact_id === contact.contact_id;
        if (found) {
          break;
        }
      }
      return found;
    });
    const idx = new_room.room_contacts.findIndex(rc => rc.contact_id === contact.contact_id);
    if (idx > -1) {
      new_room.room_contacts = [new_room.room_contacts[idx]];
    }
    ret_val.rooms = [new_room];
    ret_val.is_primary = false;
    // and we set the primary hint
    ret_val.primary_contact_hint = contact.contact_id;
    return ret_val;
  }

  public static fromSecondaryRoomAssignment(rm: RoomAssignment, contact: Contact): RoomAssignment {
    if (!contact || contact == null || rm.is_primary || !rm.parent) {
      return rm;
    }
    const roomAssignment = _.cloneDeep(rm);
    const ret_val = _.cloneDeep(roomAssignment.parent);
    delete roomAssignment.parent;
    // first we find the room our contact is in
    const idx = ret_val.rooms.findIndex(room => {
      let found: boolean;
      for (const room_contact of room.room_contacts) {
        found = room_contact.contact_id === contact.contact_id;
        if (found) {
          break;
        }
      }
      return found;
    });
    // so idx is the room he was in before, now we kick them out
    if (idx > -1) {
      const room_contact_idx = ret_val.rooms[idx].room_contacts.findIndex(rc => rc.contact_id === contact.contact_id);
      if (room_contact_idx === 0 && ret_val.rooms[idx].room_contacts.length === 1) {
        ret_val.rooms = ret_val.rooms.slice(0, idx).concat(ret_val.rooms.slice(idx + 1));
      } else if (room_contact_idx > -1) {
        ret_val.rooms[idx].room_contacts = ret_val.rooms[idx].room_contacts.slice(0, room_contact_idx)
          .concat(ret_val.rooms[idx].room_contacts.slice(room_contact_idx + 1));
      }
    }

    const room_idx = ret_val.rooms.findIndex(r => r.room_number === roomAssignment.rooms[0].room_number);
    if (room_idx > -1) {
      const room_contact_idx = ret_val.rooms[room_idx].room_contacts.findIndex(rc => rc.contact_id === contact.contact_id);
      if (room_contact_idx > -1) {
        ret_val.rooms[room_idx].room_contacts = ret_val.rooms[room_idx].room_contacts
          .slice(0, room_contact_idx)
          .concat(ret_val.rooms[room_idx].room_contacts.slice(room_contact_idx + 1))
          .concat(roomAssignment.rooms[0].room_contacts);
      } else {
        ret_val.rooms[room_idx].room_contacts = ret_val.rooms[room_idx].room_contacts
          .concat(roomAssignment.rooms[0].room_contacts);
      }
    } else {
      ret_val.rooms = ret_val.rooms.concat(roomAssignment.rooms);
    }

    ret_val.is_primary = true;
    return ret_val;
  }

  constructor(
    parent?: RoomAssignment,
    checkout_date?: Date,
    last_update_date?: Date,
    roomassignment_id?: string,
    primary_contact_hint?: string,
    checkin_date?: Date,
    create_date?: Date,
    rooms?: Room[],
    notes?: Note[],
    languages?: SelectOption[],
    roomassignment_status?: RoomAssignmentStatus,
    opera_validated?: boolean,
    active?: boolean,
    is_primary?: boolean
  ) {
    this.parent = parent;
    this.checkin_date = checkin_date;
    this.checkout_date = checkout_date;
    this.last_update_date = last_update_date;
    this.roomassignment_id = roomassignment_id;
    this.primary_contact_hint = primary_contact_hint;
    this.create_date = create_date;
    this.rooms = rooms || [new Room()];
    this.notes = notes || [];
    this.roomassignment_status = roomassignment_status || RoomAssignmentStatus.checkedin;
    this.languages = languages || [];
    this.opera_validated = opera_validated;
    this.active = active == null ? true : active;
    this.is_primary = is_primary != null ? is_primary : true;
    if (this.rooms && this.rooms.length === 0) {
      this.rooms.push(new Room());
    }
  }
}
