import { Component, Inject, OnInit } from '@angular/core';
import { OperatorService } from '../core/services/operator.service';
import { TokenService } from '../core/services/token.service';
import { Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserIdleService } from 'angular-user-idle';

export interface DialogData {
  timeRemaining: number;
  showCounterDown: true;
}

@Component({
  selector: 'app-inactivity-logout-popup',
  templateUrl: './inactivity-logout-popup.component.html',
  styleUrls: ['./inactivity-logout-popup.component.css']
})
export class InactivityLogoutPopupComponent {

  constructor(
    private operatorService: OperatorService,
    private tokenService: TokenService,
    private router: Router,
    private userIdle: UserIdleService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<InactivityLogoutPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  stopTimer(){
    this.dialog.closeAll()
    this.userIdle.stopTimer()
  }

  logout(event?: any): void {
    if (event) {
      event.preventDefault()
    }
    this.operatorService.logout$()
      .subscribe(
        (res) => {
          if (res) {
            this.tokenService.remove()
            // this.router.navigate(['login'])
            location.href="/login"
          }
      })
  }
}
