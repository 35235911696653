import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import * as _ from 'lodash'
import { switchMap } from 'rxjs/operators'
import { Component, OnInit, ViewChild } from '@angular/core'
import { Contact } from '../../core/models/contact.model'
import { GuestAttributes, Room, RoomAssignment, RoomContact } from '../../core/models/room-assignment.model'
import { HotelService } from '../../core/services/hotel.service'
import { OperatorViewService } from '../../operator/operator-view.service'
import { HelperService } from '../../shared/helper.service'
import { StylePicker } from '../../shared/style-picker'
import { GuestViewService } from './guest-view.service'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { ContactService } from '../../core/services/contact.service'
import { BaseComponent } from '../../shared/base/base.component'
import { Registration } from '../../core/models/registration.model'
import { RegistrationService } from '../../core/services/registration.service'
import { environment as env } from '../../../environments/environment'
import * as moment from 'moment'
import { Hotel, Organization } from '../../core/models/hotel.model'
import { Subscription } from 'rxjs'
import { OrgType } from 'app/core/shared/enums'

@Component({
  selector: 'app-guest',
  templateUrl: './guest.component.html',
  styleUrls: ['./guest.component.css']
})
export class GuestComponent extends BaseComponent implements OnInit {
  OrgTypeEnum = OrgType
  SIMPLE_FORM_TYPE = env.simple_form_type
  @ViewChild('checkInDate') public checkInDate: any
  authoriseAdditional = false
  room: Room = new Room
  loading = false
  error: string
  success: string
  unique_id = 1
  registration_id: string
  guest_type: string
  contact_id: string
  registration: Registration
  guestDetails: RoomAssignment = new RoomAssignment()
  jeeves_number: string
  currentOrganization: Hotel
  subscriptions: Subscription[]
  clientName = ''
  logo = ''
  orgType: number = 0
  roomNumberLabelText = 'Room number'
  textString = ''
  org_no: string = null
  orgName: string
  organization: Organization
  termsAndCondsText: SafeHtml
  defaultTermsAndCondsText = `
    All registration data is for the sole use of customers communicating with CLIENT_NAME <br/>
    and will not be shared with any third party. All data collected falls under the <br/>
    Customer Privacy Protection Policy of CLIENT_NAME.<br/>
  `
  fullSignUpFormText: SafeHtml
  defaultFullSignUpFormText = `
    Please confirm your details so as we can assist in further communications.
    Please note we do not share your details with any third party
  `
  simpleSignUpFormText: SafeHtml
  defaultSimpleSignUpFormText = `
    Great to see you back – Please update your details so as we can assist in further communications.
  `

  public min: Date = new Date(1917, 0, 1)
  public max: Date = new Date(2050, 11, 31)
  defaultNameKey = 'defaultClient'
  logoPath = ''

  constructor(
    private guestViewService: GuestViewService,
    private route: ActivatedRoute,
    contactService: ContactService,
    private router: Router,
    private registrationService: RegistrationService,
    private hotelService: HotelService,
    private helperService: HelperService,
    private stylePicker: StylePicker,
    private sanitizer: DomSanitizer,
    private operatorViewService: OperatorViewService
  ) {
    super(contactService)
  }

  // setting the room assignment to guestDetails
  createRoomAssignment(primaryContact, secondaryContact): void {
    this.guestDetails = new RoomAssignment()

    // adding primary and secondary contacts to the room assignments
    if (secondaryContact) this.guestDetails = _.cloneDeep(secondaryContact)
    this.guestDetails = RoomAssignment.toSecondaryRoomAssignment(this.guestDetails, primaryContact)

    if (
      this.guestDetails.checkin_date &&
      this.guestDetails.checkin_date.getFullYear() == 1970
    ) {
      this.guestDetails.checkin_date = null
    }
    if (
      this.guestDetails.checkout_date &&
      this.guestDetails.checkout_date.getFullYear() == 1970
    ) {
      this.guestDetails.checkout_date = null
    }
  }

  register(): void {
    // TODO: need to add contact
    this.loading = true
    this.error = ''
    this.success = ''

    // need to remove empty additional guests
    const newSecondary: Contact[] = []
    const newRoomContacts: RoomContact[] = []
    for (const roomContact of this.room_contacts) {
      if (
        roomContact.contact.channel_subscription &&
        roomContact.contact.channel_subscription.resource_identifier
      ) {
        newSecondary.push(roomContact.contact)
        newRoomContacts.push(roomContact)
      }
    }
    this.room_contacts = newRoomContacts.slice()
    this.registration.secondary_contact = newSecondary
    const today = moment()
    if (!this.registration.room_details.checkin_date) {
      this.registration.room_details.checkin_date = today.valueOf().toString()
    }

    if (!this.registration.room_details.checkout_date) {
      today.add(1, 'days')
      this.registration.room_details.checkout_date = today.valueOf().toString()
    }


    // saving contacts
    this.registrationService.save(this.registration, this.registration_id, this.guest_type)
      .then(
        (roomAssignment) => {
          if (roomAssignment && Object.keys(roomAssignment).length) this.guestDetails = roomAssignment

          console.log('roomAssignment', roomAssignment)
          console.log('this.guestDetails', this.guestDetails)
          const routeParams = { org_type: this.orgType }
          if (this.org_no) {
            routeParams['org_no'] = this.org_no
          }
          this.router.navigate(['register/thankyou', routeParams])
          this.success = 'Successfully saved guest details'
        })
      .catch(
        (err) => {
          this.loading = false
          this.error = 'Could not save guest details, please try again later...'
        })
      .finally(() => {
        this.loading = false
      })
  }

  addAnotherGuest(): void {
    if (!this.room_contacts) {
      this.room_contacts = []
    }
    // const contact = new Contact(this.unique_id++)
    this.room_contacts.push(new RoomContact())
  }

  removeAdditional(index: number): void {
    this.room_contacts.splice(index, 1)
  }

  subscribeToGuestSave(): void {

    this.guestViewService.guestContactsUpdated.subscribe(
      (is_done: boolean) => {
        if (!is_done) {
          // it errored
          this.loading = !is_done
          this.error = 'Could not save your details, please try again...'
        } else {
          this.loading = is_done
          this.success = 'Successfully saved your details'
          this.router.navigate(['register/thankyou'])
        }

      },
      (err) => {
        this.loading = false
        this.error = 'Could not save your details, please try again...'
      })
  }

  // subscribeToOrganization(): void {
  //   this.subscriptions.push(
  //     this.hotelService.hotel$
  //       .subscribe(hotel => {
  //         this.currentOrganization = hotel;
  //         this.jeeves_number = hotel.phone;
  //       })
  //   );
  // }

  subscribeToOrganization(): void {
    this.subscriptions.push(
      this.hotelService.getOrganisationByOrgNo(Number(this.org_no))
        .subscribe((organization: Organization) => {
          this.organization = organization

          if (this.organization.logo) this.logoPath = this.organization.logo
          else if (this.organization.company_code) this.logoPath = this.helperService.getLogoIconPath(this.organization.company_code)

          // setting the terms and conditions text from the backend or the default text if we find a null
          this.termsAndCondsText = this.sanitizer.bypassSecurityTrustHtml(
            this.organization.terms_and_conds_text || this.defaultTermsAndCondsText.replace(/CLIENT_NAME/g, this.clientName)
          )
          // setting the terms and conditions text from the backend or the default text if we find a null
          this.fullSignUpFormText = this.sanitizer.bypassSecurityTrustHtml(
            this.organization.full_sign_up_form_text || this.defaultFullSignUpFormText
          )
          // setting the terms and conditions text from the backend or the default text if we find a null
          this.simpleSignUpFormText = this.sanitizer.bypassSecurityTrustHtml(
            this.organization.simple_sign_up_form_text || this.defaultSimpleSignUpFormText
          )
        })
    )
  }

  ngOnInit() {
    this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        this.registration_id = params.get('registration_id')
        this.guest_type = params.get('type')
        let orgType = params.get('org_type')
        this.org_no = params.get('org_no') || params.get('org_No')

        let org_name = ''
        if (this.org_no != null) {
          const orgName = parseInt(this.org_no || '')
          org_name = env.clientNumbers[orgName]

          this.orgName = org_name
        }

        const retVal = this.helperService.getClientMapping(org_name)
        // we reset the company code to whatever comes back from the mapping, since we might have a blank one or one that doesn't exist
        const companyCode = retVal.companyCode
        this.clientName = retVal.clientName
        this.logoPath = retVal.logoPath
        this.orgType = retVal.orgType || orgType
        this.textString = retVal.textString
        this.jeeves_number = retVal.phone
        // orgType = retVal.orgType

        this.logoPath = this.helperService.getLogoPath(companyCode)
        // until I fix the decipher
        this.registration = new Registration()
        this.subscriptions = []
        // first we need to get the organisation
        // this.subscribeToOrganization();
        this.stylePicker.loadStyle(companyCode + '.css');
        this.hotelService.icon$.next(retVal.faviconPath)

        if (orgType && (typeof orgType == 'string' || typeof orgType == 'number')) {
          this.orgType = parseInt(orgType)
          this.roomNumberLabelText = this.orgType === this.OrgTypeEnum.Hotel ? 'Room number' : 'Email address'
        }

        // const test = this.decrypt(param);
        this.registration.primary_contact = new Contact()

        return Promise.resolve([])
      }))
      .subscribe(contact => {
        // just a dummy return function since it doesn't need to really do anything
        return contact
      })

    this.subscribeToGuestSave()
    this.subscribeToOrganization()
  }

}
