
import { of as observableOf, throwError as observableThrowError, Observable } from 'rxjs';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Message } from '../../shared/message.model';
import { QueueItem } from '../../shared/queue-item.model';
import { Contact, Tag } from '../models/contact.model';
import { AuthenticationService } from './authentication.service';
import { BaseDjangoService } from './base-django.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class MessageService extends BaseDjangoService {
  currentMessages: Message[];
  current_message_id: string;
  incomingMessages: QueueItem[];
  showMessageBoxChange: EventEmitter<boolean> = new EventEmitter();
  // incomingMessagesChange: EventEmitter<QueueItem[]> = new EventEmitter();

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) {
    super(authenticationService);
    this.apiUrl += '/message/';
    // // start polling
    // this.getIncoming();
    this.subRoute = '/tag';
    this.defaultSearchParam = 'subscriber';
  }

  getById(id?: string): Promise<Message | {}> {
    // checking for null or undefined
    if (id == null) {
      return Promise.resolve({});
    }

    const options = this.getOptions();

    let ret_message: Message;
    const url = this.apiUrl + id;
    return this.http.get(url, options)
      .toPromise()
      .then(
        (res) => {
          const result = res as Message[];
          // const tag_url = url + this.subRoute;

          if (result && result.length > 0) {
            ret_message = result[0];
          }
          // else {
          //   // we don't have a message
          //   return Observable.throw('no message found for id').toPromise();
          // }

          // now we need to get the tags for the message;
          // var data= this.http.get(tag_url, this.options).toPromise().then((tagdata)=>{
          //     console.log(tagdata,"dattt");
          //     if (Array.isArray(tagdata) && tagdata.length > 0) {
          //       ret_message.tag = tagdata as Tag[];
          //       ret_message.tags = tagdata;
          //   }
          //   });
            
          // }).then(
          //   (res) => {
          //   const result = res;
          //   if (result && result.hasOwnProperty('length') && result.length > 0) {
          //     ret_message.tag = result as Tag[];
          //   }
          //   ret_message = this.unpackTags(ret_message);
          return ret_message;
        })
      .catch((err) => observableThrowError(err).toPromise());
  }
  getByParam(value: string, key?: string, limit?: number): Promise<Message[]> {
    if (!value) {
      return Promise.resolve([]);
    }
    key = key == null ? this.defaultSearchParam : key;
    let url = this.apiUrl + '?' + key + '=' + value; // + '&limit=1';

    if (limit != null) {
      url += '&limit=' + limit;
    }
    const options = this.getOptions();

    return this.http.get(url, options)
      .toPromise()
      .then((res) => {
        const temp = res as Message[];
        let result: Message[];
        // should probably use a different object
        // let result = res;
        if (temp.hasOwnProperty('length')) {
          // for some stupid reason Put returns a list of objects
          // whereas post returns one
          result = temp;
        }
        return result;
      })
      .catch((err) => observableThrowError(err).toPromise());
  }


  saveTags(tags: Tag[], message_id: string): Promise<boolean> {
    if (!message_id || !tags || (tags.hasOwnProperty('length') && tags.length === 0)) {
      // need to raise an error for
      return observableThrowError('no message or tags were supplied').toPromise();
    }
    const url = this.apiUrl + message_id + this.subRoute;
    const options = this.getOptions();

    return this.http.post(url, tags, options)
      .toPromise()
      .then(
        (res) => {
          // res should be returned as an empty object at this point
          return true;
        })
      .catch(err => observableOf(err).toPromise());
  }

  saveTag(tag: Tag, message_id: string): Promise<boolean> {
    if (!message_id || !tag) {
      // need to raise an error for
      return observableThrowError('no message or tag were supplied').toPromise();
    }
    const url = this.apiUrl + message_id + this.subRoute;
    const options = this.getOptions();

    return this.http.post(url, tag, options)
      .toPromise()
      .then(
        (res) => {
          // res should be returned as an empty object at this point
          return true;
        })
      .catch(err => observableOf(err).toPromise());
  }

  addMessage(message: Message): void {
    this.currentMessages.push(message);
  }

  getMessages(ids: string[]): Message[] {
    if (ids.length === 0) {
      return [];
    }
    return this.currentMessages;
  }

  // getIncoming(): any {
  //   this.queueService.pull('').subscribe((res) => {
  //     console.log(res);
  //
  //     this.incomingMessages = res as QueueItem[];
  //     this.incomingMessagesChange.emit(this.incomingMessages);
  //   });
  // }


}
