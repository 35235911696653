import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class PaginationService {
    tabKeySubject: BehaviorSubject<{ limit: number; pageSize: number }>;

    constructor() {
        // Initialize tab key with default value
        this.tabKeySubject = new BehaviorSubject<{ limit: number; pageSize: number }>({ limit: 2, pageSize: 1 });
        // Initialize page size with default value
    }

    // Tab Key Methods
    setPagination(pagination: any): void {
        this.tabKeySubject.next(pagination);
    }

    getPagination(): Observable<any> {        
        return this.tabKeySubject.asObservable();
    }

}
