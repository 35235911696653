import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { User } from '../shared/user.model';
import { Location } from "@angular/common";
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '../core/services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  avatar: File;
  @ViewChild('changePassword') public changePassword: NgForm;
  user: User;
  error: string = '';
  success: string = '';
  updatingPassword = '';
  subscriptions: Subscription[] = [];
  _authenticationService: AuthenticationService;

  constructor(
    private location: Location,
    authenticationService: AuthenticationService,
    private http: HttpClient,
    private router: Router
  ){
    this._authenticationService = authenticationService;
  }

  private getHeaders(): HttpHeaders {
    const headers = {
      Accept: 'application/json'
    };
    const token = this._authenticationService.getToken();
    if (token) {
      headers['Authorization'] = token.token_type + ' ' + token.access_token;
    }
    return new HttpHeaders(headers);
  }

  getOptions(): object {
    const headers = this.getHeaders();
    return {
      headers: headers
    };
  }

  newPassword(): Promise<boolean> {
    this.updatingPassword = 'Please wait ...';
    const newPasswordValue = this.changePassword.value.new_password;
    if(newPasswordValue == '' || newPasswordValue == undefined){
      console.log('VALUE => '+newPasswordValue);
      this.success = '';
      this.error = 'Please enter new password';
      this.updatingPassword = '';
    }else if(newPasswordValue.length < 8){
      this.success = '';
      this.error = 'Password length should be 8 characters';
      this.updatingPassword = '';
    }else{
      const base_url = 'https://users.jeeves.plus';
      const path = '/api/web.user/change_password/';
      const url = base_url+path;
      const params = {
        'new_password': newPasswordValue
      };
      const options = this.getOptions();
      return this.http.post(url, params, options)
        .toPromise()
        .then(
          (res) => {
            if (res) {
              this.updatingPassword = '';
              this.error = '';
              this.success = 'Password has been changed successfully';
              setTimeout(() => {
                this.router.navigate(['/operator']);
              }, 2000);
            }
            return true;
          })
        .catch(
          (err) => {
            this.updatingPassword = '';
            this.success = '';
            this.error = 'Something went wrong. Please try again after sometime.';
            return Promise.reject(err);
        });
    }
  }

  goBack(): void {
    this.location.back();
  }

  ngOnInit() {
    this.user = new User();
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      for (const sub of this.subscriptions) {
        sub.unsubscribe();
      }
    }
  }

}
