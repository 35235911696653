// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

const client_id_local = '7Vv7YtZX6BYKrkF2PFSZUuv7TSlPPd4CeA4YaKnP'; // this is the client_id on Andrew's local
const client_id_dev = 'jLJEbBx8eDvnXBKrQ32F72d7Y0Aar04cLX2xgkQH';
// client secret on Andrews local
const client_secret_local = 'k4vGPNeHQnwloT4kQIrjPNlcdlAX5HuWgMb3YG4j3lkfSWaA4Y7ltcYOmnVNWnN7O91zuewgeNjMp3KTY4yRSCn4PAOYaLUiHzaQvbxU7AZe6LQ0GaZKcGU0ltQZylEu';
const client_secret_dev = 'zEMYG5BnmxbJ5sTdeRXGKd1bklQyJ2iXbzol52u6pRjurR0R6QMbl6u4At61JAasuBc9BD6uA79sDXhK7ncqNtWEztPxM' +
  '6oLhMcPmbm78PO5YLX0OHHtaYVwftHMmQeh';
const env = 'Dev';

// const client_id = client_id_local;
const client_id = client_id_dev;
// const client_secret = client_secret_local;
const client_secret = client_secret_dev;

// // const endpoint = 'http://192.168.0.136:8077/';
// // const endpoint = 'https://jeeves-users.codesource.com.au/';
// const endpoint = 'https://users.jeeves.plus/';
// // const endpoint = 'http://127.0.0.1:8000/'; // for local dev
// const apiDev2 = 'https://68z13bq18g.execute-api.ap-southeast-2.amazonaws.com/dev2';
//const api = 'https://dev-webapi.jeeves.plus';
// const apiWs = 'ws://dev-webapi.jeeves.plus';

const endpoint = 'https://users.jeeves.plus/'
// const api = 'https://stage-webapi.jeeves.plus';
const api = 'https://webapi.jeeves.plus'

const sentry_env = 'local';
const sentry_dsn = 'https://38754d09a6bd428b90c5147973a136f1@sentry.io/1855662';

export const environment = {
  app: 'Jeeves pty ltd',
  simple_form_type: 'returning_guest',
  // jeeves_number: '0459123456',
  dateDiffVal: 300,
  production: false,
  sentry_environment: sentry_env,
  sentry_dsn: sentry_dsn,
  version: 'Dev',
  default_expiry: 3600, //86400,
  refresh_token_timeout: 3600000, // need to be in milli seconds for call to interval()
  expires_within: 120, // if token expires within 2 mins
  storage_accessor: 'jeeves_token',
  api: api,
  auth_api: endpoint + 'auth/token/',
  users_api: endpoint + 'api',
  envName: env,
  client_id: client_id,
  client_secret: client_secret,
  site_id: '441b376814c347f3907fee93020eae9d',
  logoPath: 'assets/images/{0}-logo.png',
  logoIconPath: 'assets/images/{0}-logo-icon.png',
  socialIconPath: 'assets/images/icon_{0}.png', // the {0} replaces to be the social media messaging app
  messageIconTypes: [
    'FACEBOOK',
    'WHATSAPP',
    'WECHAT',
    'GOOGLE',
    'TELEGRAM',
    'WEBCHAT',
    'SINCH',
    'DISCORD'
  ],
  messageIconTypesAlt: {
    'FACEBOOK': 'Facebook',
    'WHATSAPP': 'Whatsapp',
    'WECHAT': 'Wechat',
    'GOOGLE': 'Google',
    'TELEGRAM': 'Telegram',
    'SYNCH': 'Synch',
    'WELCORP': 'Welcorp',
    'WEBCHAT': 'WebChat',
    'SINCH': 'SMS',
    'DISCORD': 'Discord'
  },
  clientNumbers: {
    '8': 'computer_and_network_support',
    '19': 'healthdirect',
    '21': 'covid-19'
  },
  client_mappings: {
    'healthdirect': {
      name: 'Health Direct',
      orgType: 1,
      textString: 'Care',
      phone: '0413 575 575',
    },
    'covid-19': {
      name: 'Covid-19',
      orgType: 1,
      textString: 'Covid',
      phone: '61457000000',
    },
    defaultClient: {
      name: 'Jeeves',
      orgType: 0,
      textString: 'Jeeves',
      phone: '',
    },
  },
  auth_headers: {
    Authorization: 'Basic ' + btoa(client_id + ':' + client_secret),
    'Content-Type': 'application/x-www-form-urlencoded'
  },
  jeeves_headers: {
    Authorization: 'tony-admin-441b376814c347f3907fee93020eae9d',
    'Content-Type': 'application/json'
  },
  logger: {
    enabled_default: true,
    headers: {
      Authorization: '9~VF~7XJ+rL#{ay0L(%I{opW}op0G',
      'Content-Type': 'application/json'
    },
    api: location.protocol + '//' + 'logging.codesource.com.au/api/report/',
    payload: {
      source: 'Jeeves-' + env,
      data: {
        appType: 'Jeeves',
        appVersion: '1.4.0',
        environment: env,
        osVersion: 'web',
        deviceType: 'browser',
        deviceID: navigator.userAgent,
        eventType: '',
        currentState: '',
        currentParams: '',
        userID: '',
        tenancy: '',
        trace: []
      }
    }
  }
};
