
import { of as observableOf, throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseDjangoService } from './base-django.service';
import { AuthenticationService } from './authentication.service';
import { SelectOption } from '../models/select-option.model';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ConstantsService extends BaseDjangoService {

  // apiKeys: string[] = [];
  choices: object;
  constructor(
    private http: HttpClient,
    public authenticationService: AuthenticationService
  ) {
    super(authenticationService);
    this.subRoute = '/static.choices/';
    this.apiUrl = this.usersUrl;

    // this.apiKeys = [
    //   'user.languages',
    //   'message.priorities'
    // ];
  }

  get(): Promise<any> {
    if (!this.choices) {
      const options = this.getOptions();
      return this.http.get(this.apiUrl + this.subRoute, options)
        .toPromise()
        .then((res) => {
          if (res != null && typeof res === 'object') {
            const ret_choices = {};
            const choices = res;
            const keys = Object.keys(choices);
            for (const key of keys) {
              const new_key = key.split('.')[1];
              ret_choices[new_key] = [];
              for (const val of choices[key]) {
                ret_choices[new_key].push(new SelectOption(
                  val[0],
                  val[1]
                ));
              }
            }
            this.choices = ret_choices;
          }
          return this.choices;
        })
        .catch((error: any) => observableThrowError(error.error || 'Server Error'));
    } else {
      return observableOf(this.choices).toPromise();
    }
  }

  put(): Promise<any> {
    return;
  }

  post(): Promise<any> {
    return;
  }

  delete(): void {
    return;
  }

  list(): Promise<any[]> {
    return;
  }


}
