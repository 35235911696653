
import { throwError as observableThrowError, Subscription, Observable, of, from } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { SiteService } from "../services/site.service";
import { TokenService } from "../services/token.service";
import { AuthenticationService } from "../services/authentication.service";
import { Router } from "@angular/router";
import { ConstantsService } from "../services/constants.service";
import { ContactService } from "../services/contact.service";
import { OperatorService } from "../services/operator.service";
import { RoomAssignmentService } from "../services/room-assignment.service";
import { UserService } from "../services/user.service";
import { environment as env } from "../../../environments/environment";
import { HttpErrorCodes } from "../shared/enum";
import { HotelService } from "../services/hotel.service";
import { Hotel } from "../models/hotel.model";
import { delay } from 'rxjs/operators';

@Injectable()
export class StartupService {

  ignoreRoutes = ['/restpassword', '/guest/register'];
  defaultSiteName = 'test_site_1';
  defaultExpiry = env.expires_within; // if token expiry is less than 15 seconds, renew
  private subscriptions: Array<Subscription> = [];

  constructor(
    private injector: Injector,
    private siteService: SiteService,
    private tokenService: TokenService,
    private authenticationService: AuthenticationService,
    private constantsService: ConstantsService,
    private contactService: ContactService,
    private operatorService: OperatorService,
    private roomassignmentService: RoomAssignmentService,
    private userService: UserService,
    private hotelService: HotelService
  ) {

  }

  public get router(): Router {
    return this.injector.get(Router);
  }

  async load(): Promise<any> {

    const token = this.authenticationService.getToken();
    const operator_info = this.operatorService.getChannelTokens();
    const keepLogin = this.operatorService.keepLogin;
    // const failedLogin = this.authenticationService.
    // if we have the operator login from a previous session, let's set it now
    if (operator_info) {
      this.siteService.setSiteId(operator_info.site_id);
    }
    let promise: Promise<any>;
    const url = this.router.url;
    if (this.ignoreRoutes.indexOf(url) === -1 && token
      && (this.authenticationService.isExpired(token) ||
      this.authenticationService.expiresWithin(this.defaultExpiry))
    ) {
      // if at any poitn below we have the 'keepLogin' set, we skip and delete th stream.
      if (this.authenticationService.isRefreshExpired(token) && !keepLogin) {
        // else we delete the token entry and go back to login
        this.authenticationService.clearToken();
        await this.router.navigate(['/login']);
      } else if (
        !this.tokenService.getToken &&
        this.authenticationService.accessExpiresWithin(this.defaultExpiry) && !keepLogin
      ) {
        promise = this.tokenService.refresh();
      } else if (keepLogin) {
        return this.operatorService.logout()
          .then(val => {
            this.authenticationService.clearToken();
            this.operatorService.keepLogin = false;
          }).catch(err => {
            console.log(err);
            throw err;
          });
      } else {
        promise = of({}).toPromise();
      }
    } else {
      // this.router.navigate(['/login']);
      if (!this.userService.current_user) {
        promise = of({}).toPromise();
      } else {
        promise = of(this.userService.current_user).toPromise();
      }
    }

    return promise.then(
      (t) => {
        let innerPromise: any;
        // let's get the user first
        if (!this.userService.current_user)
          innerPromise = this.userService.getCurrentUser();
        else
          innerPromise = of(this.userService.current_user).toPromise();
        return innerPromise;
      }).then(
        res => {
          let innerPromise: any;
          if (!this.constantsService.choices)
            innerPromise = this.constantsService.get();
          else
            innerPromise = of(this.constantsService.choices).toPromise();
          return innerPromise;
        }).then(
          () => {
            return this.userService.getAll();
          })
      .then(
        (user) => {
          let innerPromise: Promise<Hotel[]>;
          // let's get the user first
          if (!this.hotelService.hotels)
            innerPromise = this.hotelService.getAll();
          else
            innerPromise = of(this.hotelService.hotels).toPromise();

          this.hotelService.hotels$.next(this.hotelService.hotels);
          return innerPromise;
        })
      .then((hotels) => {
        // we should have the current user by this time
        const user = this.userService.current_user;
        if (user && user.id != null && user.hotel != null) {
          this.hotelService.hotel$.next(hotels.find(h => h.id == user.hotel));
        }
        return this.siteService.getSiteById();
      })
      .then(
        () => {
          // now we subscribe to the message streams instead of above since we need the site id for other things
          return this.contactService.getAll();
        })
      .then(
        choices => {
          // TODO: remove this when we have a definitive way to get a room assignment by guest id
          return this.roomassignmentService.getAll();
        }).catch(
          async (err: any) => {
            // need to log the error here
            if (err && (err.status === HttpErrorCodes.SERVER_ERROR ||
              err.status === HttpErrorCodes.UNAUTHORIZED)) {
              this.tokenService.remove();
            } else {
              console.log(err);
            }

            // if everything goes wrong, we force a logout of everything

            await this.operatorService.logout();
            this.authenticationService.clearToken()
            await this.router.navigate(['/login']);
            console.log('catch');

          });
  }
}
