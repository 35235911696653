import { Component, OnDestroy, OnInit } from '@angular/core'
import { UserIdleService } from 'angular-user-idle';
import { Hotel } from '../core/models/hotel.model'
import { TokenService } from '../core/services/token.service'
import { HotelService } from '../core/services/hotel.service'
import { HelperService } from '../shared/helper.service'
import { ShowNavService } from '../shared/show-nav.service'
import { OperatorService } from '../core/services/operator.service'
import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { StylePicker } from '../shared/style-picker'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { InactivityLogoutPopupComponent } from '../inactivity-logout-popup/inactivity-logout-popup.component'
import { AuthenticationService } from "../core/services/authentication.service";

@Component({
  selector: 'app-operator',
  templateUrl: './operator.component.html',
  styleUrls: ['./operator.component.css']
})
export class OperatorComponent implements OnInit, OnDestroy {
  loading = true
  subscriptions: Subscription[] = []
  currentHotel: Hotel
  inactivityLogoutPopup: any
  timeRemaining = 10
  interval

  constructor(
    private showNavService: ShowNavService,
    private operatorService: OperatorService,
    private router: Router,
    private stylePicker: StylePicker,
    private hotelService: HotelService,
    private helperService: HelperService,
    private userIdle: UserIdleService,
    private tokenService: TokenService,
    private authenticationService: AuthenticationService,

    private dialog: MatDialog,
  ) {
  }

  subscribeToRouteEvents(): void {
    this.subscriptions.push(
      this.router.events.subscribe((event: Event) => {
        switch (true) {
          case event instanceof NavigationStart: {
            this.loading = true
            break
          }
          case event instanceof NavigationEnd:
          case event instanceof NavigationCancel:
          case event instanceof NavigationError: {
            this.loading = false
          }
          default: {
            break
          }
        }
      })
    )
  }

  subscribeToCurrentHotel(): void {
    this.subscriptions.push(
      this.hotelService.hotel$
        .subscribe(hotel => {
          this.currentHotel = hotel
        }, err => {
          console.warn(err)
          console.log('could not get the list of organisations')
        })
    )
  }

  operatorInactivity(): void {
    this.inactivityLogoutPopup = this.dialog.open(InactivityLogoutPopupComponent, {
      disableClose: true,
      width: '350px',
      data: {
        timeRemaining: this.timeRemaining,
        showCounterDown: true,
      },
    })  
  }

  counter(): void {
    setInterval(() => {
        if (this.timeRemaining > 0) {
          this.timeRemaining--
          if (this.inactivityLogoutPopup) {
            this.inactivityLogoutPopup.componentInstance.data = {
              timeRemaining: this.timeRemaining,
            }
          }
        }
      }, 1000)
  }

  logout(event?: any): void {
    if (event) {
      event.preventDefault()
    }
    this.operatorService.logout$()
      .subscribe(
        (res) => {
          if (res) {
            this.tokenService.remove()
            this.dialog.closeAll()
            // this.router.navigate(['login'])
            location.href="/login"
            return
          }
      })
  }

  ngOnInit() {
    //  since we are skipping the login now and going straight to this route,
    // we now need to set the login here
    this.operatorService.loggedInEvent.emit(true)
    this.subscribeToRouteEvents()
    this.subscribeToCurrentHotel()
    // this.counter()

    this.stylePicker.loadStyle(this.currentHotel.company_code + '.css')
    const retVal = this.helperService.getClientMapping(this.currentHotel.company_code);
    this.hotelService.icon$.next(retVal.faviconPath)

    this.showNavService.set(true)
    this.loading = false

    // if(this.authenticationService.isAuthenticated()){
      this.userIdle.startWatching();
      this.userIdle.onTimerStart().subscribe( (count) => {
        let openDialoagSelector = false
        if(count < 11){
          if(count === 1){
            openDialoagSelector = true;
            if(openDialoagSelector){
              this.operatorInactivity()
              this.counter()
            }
          } 
          console.log(count)
        }
      });

      this.userIdle.onTimeout().subscribe(
        () => {
          this.logout()
        })
      // }
    }

    ngOnDestroy(): void {
      if (this.subscriptions && this.subscriptions.length > 0) {
        for (const sub of this.subscriptions) {
          sub.unsubscribe()
        }
      }
    }
  }
