import { Injectable } from '@angular/core';

@Injectable()
export class TagService {

  defaultTags: string[] = [
    'Compliments',
    'Issues',
    'Feedback',
    'Room service',
    'Information',
    'Requests',
    'Bookings Room',
    'Booking Restaurants',
    'Taxi',
    'Hire Cars'
  ];
  constructor() { }

  getTagList(): string[] {
    return this.defaultTags;
  }

}
