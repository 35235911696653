export class SearchResult {
  value: string;
  text: string;
  endpoint: string;

  constructor(
    value?: string,
    text?: string,
    endpoint?: string
  ) {
    this.value = value;
    this.text = text;
    this.endpoint = endpoint;
  }
}
