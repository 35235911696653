
import { of as observableOf, throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Role } from '../models/role.model';


import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class AuthService extends BaseService {

  roles: Role[];
  constructor(private http: HttpClient) {
    super();
  }

  getRoles(): Promise<Role[] | Observable<never>> {
    if (!this.roles) {
      return this.http.get(this.apiUrl + '/roles/')
        .toPromise()
        .then((res) => this.roles = res as Role[])
        .catch((error: any) => observableThrowError(error.error || 'Server Error'));
    } else {
      return observableOf(this.roles).toPromise();
    }

  }

}
