
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable, EventEmitter } from '@angular/core';
import { ContactService } from "../../core/services/contact.service";
import { Contact } from "../../core/models/contact.model";
import { Room, RoomAssignment, RoomContact } from "../../core/models/room-assignment.model";
import { RoomAssignmentService } from "../../core/services/room-assignment.service";
import { SiteService } from "../../core/services/site.service";
import { SiteGuestAttributeTemplate } from "../../core/models/site.model";
import { Router } from "@angular/router";

@Injectable()
export class GuestViewService {


  guestContactsUpdated: EventEmitter<boolean> = new EventEmitter();

  constructor(private contactService: ContactService,
    private roomAssignmentService: RoomAssignmentService,
    private siteService: SiteService,
    private router: Router) {
  }


  // save(contacts: Contact[], roomAssignment: RoomAssignment): void {
  //   const promises = [];
  //   for (const contact of contacts) {
  //     promises.push(this.contactService.save(contact));
  //   }
  //   if (promises && promises.length > 0) {
  //     promises.forEach((values, key) => {
  //       if (values && values.length > 0) {
  //         const p = Promise.resolve([]);
  //         const newContacts: Contact[] = [];
  //         const final_promise = values.reduce((prev, curr) => {
  //           // promises.reduce((prev, curr) => {
  //           return prev.then((res) => {
  //             if (res) {
  //               newContacts.push(res);
  //             }
  //             return curr;
  //           }).catch((err) => {
  //             console.log(err);
  //             return curr;
  //           });
  //         }, p);
  //         if (final_promise) {
  //           // should get here as we need to save the contacts
  //           final_promise.then((res) => {
  //             if (res) {
  //               newContacts.push(res);
  //             }
  //             if (newContacts && newContacts.length > 0) {
  //               for (let i = 0; i < newContacts.length; i++) {
  //                 if (i === 0) {
  //                   // if i is 0 then it's the primary contact
  //                   roomAssignment.primary_contact_hint = newContacts[i].contact_id;
  //                 }
  //                 // in theory we should only have one room at the moment
  //                 const room_contact = new RoomContact();
  //                 room_contact.contact_id = newContacts[i].contact_id;
  //                 if (!roomAssignment.rooms || roomAssignment.rooms.length === 0) {
  //                   roomAssignment.rooms.push(new Room());
  //                 }
  //                 if (this.siteService.siteDefaults) {
  //                   room_contact.guest_attributes = this.siteService.siteDefaults.site_guest_attribute_template;
  //                 }
  //                 roomAssignment.rooms[0].room_contacts.push(room_contact);
  //               }
  //             }
  //             return this.roomAssignmentService.save(roomAssignment)
  //           })
  //             .then(
  //               (res) => {
  //                 if (res && res.length > 0) {
  //                   // TODO: do something when everything has saved successfully
  //                 }
  //                 this.guestContactsUpdated.emit(true);
  //                 this.router.navigate(['register/thankyou']);
  //               })
  //             .catch((err) => {
  //               console.log(err);
  //             });
  //         } else {
  //           this.guestContactsUpdated.emit(true);
  //         }
  //       }
  //     });
  //   }
  // }

  save(contacts: Contact[], roomAssignment: RoomAssignment): Promise<boolean> {
    const promises: Promise<Contact>[] = [];
    for (const contact of contacts) {
      promises.push(this.contactService.save(contact));
    }
    return Promise.all(promises).then((res) => {
      // should be a list of contacts
      if (res && res.length > 0) {
        // should get here as we need to save the contacts
        if (res && res.length > 0) {
          for (let i = 0; i < res.length; i++) {
            if (i === 0) {
              // if i is 0 then it's the primary contact
              contacts[i].contact_id = res[i].contact_id;
              roomAssignment.primary_contact_hint = res[i].contact_id;
            }
            // in theory we should only have one room at the moment
            const room_contact = new RoomContact();
            room_contact.contact_id = res[i].contact_id;
            if (!roomAssignment.rooms || roomAssignment.rooms.length === 0) {
              roomAssignment.rooms.push(new Room());
            }
            if (this.siteService.siteDefaults) {
              room_contact.guest_attributes = this.siteService.siteDefaults.site_guest_attribute_template;
            }
            roomAssignment.rooms[0].room_contacts.push(room_contact);
          }
        }
        return this.roomAssignmentService.save(roomAssignment)
      } else {
        return Promise.resolve({});
      }
    })
      .then(
        (res) => {
          if (res) {
            // TODO: do something when everything has saved successfully
          }
          // this.guestContactsUpdated.emit(true);
          return true;
          // this.router.navigate(['register/thankyou']);
        })
      .catch((err) => {
        return observableThrowError(err).toPromise();
      });
  }
}
