import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { OperatorComponent } from '../operator/operator.component';
import { LoginComponent } from '../login/login.component';
import { GuestComponent } from '../register/guest/guest.component';
import { AccessGuard } from '../core/shared/access.guard';
import { ProfileComponent } from '../profile/profile.component';
import { ThankYouComponent } from '../register/thank-you/thank-you.component';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';
import { ChangePasswordComponent } from '../change-password/change-password.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  }, {
    path: 'operator',
    component: OperatorComponent,
    data: {
      requiresLogin: true
    },
    canActivate: [AccessGuard]
  }, {
    path: 'login',
    component: LoginComponent,
    data: {
      requiresLogin: false
    },
    canActivate: [AccessGuard]
  }, {
    path: 'guest/register',
    component: GuestComponent,
    data: {
      requiresLogin: false
    },
    canActivate: [AccessGuard]
  }, {
    path: 'register/thankyou',
    component: ThankYouComponent,
    data: {
      requiresLogin: false
    },
    canActivate: [AccessGuard]
  }, {
    path: 'account',
    component: ProfileComponent,
    data: {
      requiresLogin: true
    },
    canActivate: [AccessGuard]
  }, {
    path: 'changepassword',
    component: ChangePasswordComponent,
    data: {
      requiresLogin: true
    },
    canActivate: [AccessGuard]
  }, {
    path: 'resetpassword',
    component: ResetPasswordComponent,
    data: {
      requiresLogin: false
    },
    canActivate: [AccessGuard]
  }, {
    path: 'resetpassword/:uidb36:token',
    component: ResetPasswordComponent,
    data: {
      requiresLogin: false
    },
    canActivate: [AccessGuard]
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
