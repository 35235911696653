import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';


@Pipe({
  name: 'dateDiff',
  pure: false
})

export class DateDiffPipe implements PipeTransform {

  transform(value: any, messageOut?: any): any {
    let timeToCheck;
    // first we check which one we need to check (if messageOut is greater, we use that)
    timeToCheck = value > messageOut ? value : messageOut;
    if (!timeToCheck) {
      return 0;
    }
    // let today = Moment();
    const now = moment();
    const message_date = moment(timeToCheck);
    const diff = now.diff(message_date, 'seconds', true);
    // console.log(diff);
    return diff;
  }

}
