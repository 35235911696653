
import { of as observableOf } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { UserService } from './user.service';
import { Router } from '@angular/router';
import { environment as env } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class LoggerService {
  options: {};
  constructor(
    private injector: Injector,
    private userService: UserService
  ) { }

  public get router(): Router {
    return this.injector.get(Router);
  }

  public get http(): HttpClient {
    return this.injector.get(HttpClient);
  }

  getHeaders(): HttpHeaders {
    const headers = new HttpHeaders();
    headers.append('Authorization', env.logger.headers.Authorization);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return headers;
  }


  logEvent(trace: any, eventType: string, currentState: any, currentParams: any): void {
    const payload: any = env.logger.payload;
    const url = env.logger.api;
    const headers = this.getHeaders();
    this.options = { headers: headers };
    payload.data.environemnt = env.envName;
    payload.data.trace = [trace];
    payload.data.eventType = eventType;
    payload.data.currentState = '';
    payload.data.currentParams = '';
    payload.data.userID = this.userService.current_user.id || '';
    this.http.post(url, payload, this.options)
      .toPromise()
      .then(res => {
        // do something on resolve
      })
      .catch(err => observableOf(err).toPromise());
  }

}
