import { of as observableOf, throwError as observableThrowError, Observable } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { EventEmitter, Injectable } from '@angular/core';
import { RoomAssignment } from '../models/room-assignment.model';
import { IBaseService } from '../interfaces/base-interface'
import { BaseDjangoService } from './base-django.service';
import { AuthenticationService } from './authentication.service';
import { HttpSuccessCodes } from '../shared/enum';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class RoomAssignmentService extends BaseDjangoService implements IBaseService {

  roomAssignments: RoomAssignment[];
  roomAssignment: RoomAssignment;
  roomAssignmentUpdated: EventEmitter<RoomAssignment> = new EventEmitter();
  roomAssignmentsUpdated: EventEmitter<RoomAssignment[]> = new EventEmitter();

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) {
    super(authenticationService);
    this.apiUrl += '/roomassignment';
    this.defaultSearchParam = 'room-number';

  }

  getAll(): Promise<RoomAssignment[]> {
    // if (!this.roomAssignments) {
    const options = this.getOptions();

    return this.http.get(this.apiUrl, options)
      .toPromise()
      .then((res) => {
        const assignments = res as RoomAssignment[];
        this.roomAssignments = [];
        for (const assignment of assignments) {
          const room_assignment = RoomAssignment.toRoomAssignment(assignment);
          this.roomAssignments.push(room_assignment);
        }
        this.roomAssignmentsUpdated.emit(this.roomAssignments);
        return this.roomAssignments;
      })
      .catch(
        (err) => {
          return observableThrowError(err).toPromise();
        });
    // } else {
    //   return Observable.of(this.roomAssignments).toPromise();
    // }
  }

  getById(id: string): Promise<RoomAssignment> {
    const url = this.apiUrl + '/' + id;
    const options = this.getOptions();

    return this.http.get(url, options)
      .toPromise()
      .then((res) => {
        const roomAssigment = res;
        if (roomAssigment) {
          this.roomAssignment = RoomAssignment.toRoomAssignment(roomAssigment);
        }
        this.roomAssignmentUpdated.emit(this.roomAssignment);
        return this.roomAssignment;
      })
      .catch((err) => observableThrowError(err).toPromise());
  }

  getByParam(value: string, key?: string): Observable<RoomAssignment[]> {
    if (!value) {
      return observableOf([]);
    }
    key = key == null ? this.defaultSearchParam : key;
    const url = this.apiUrl + '?' + key + '=' + value;
    const options = this.getOptions();

    return this.http.get(url, options).pipe(
      map((res) => {
        const roomAssignments = res as RoomAssignment[];
        const ret_obj: RoomAssignment[] = [];
        if (roomAssignments && roomAssignments.length > 0) {
          for (const roomAssignment of roomAssignments) {

            ret_obj.push(RoomAssignment.toRoomAssignment(roomAssignment));
          }
        }
        return ret_obj;
      }),
      catchError((err) => observableThrowError(err))
    );
  }

  getByParamPromise(value: string, key?: string): Promise<RoomAssignment[]> {
    if (!value) {
      return observableOf([]).toPromise();
    }
    key = key == null ? this.defaultSearchParam : key;
    const url = this.apiUrl + '?' + key + '=' + value;
    const options = this.getOptions();

    return this.http.get(url, options)
      .toPromise()
      .then((res) => {
        const roomAssignments = res as RoomAssignment[];
        const ret_obj: RoomAssignment[] = [];
        if (roomAssignments && roomAssignments.length > 0) {
          for (const roomAssignment of roomAssignments) {

            ret_obj.push(RoomAssignment.toRoomAssignment(roomAssignment));
          }
        }
        return ret_obj;
      })
      .catch((err) => observableThrowError(err).toPromise());
  }


  save(roomassignment: RoomAssignment): Promise<RoomAssignment> {
    let callback: any;
    let url = this.apiUrl;
    let payload: string;
    const options = this.getOptions();
    roomassignment = RoomAssignment.fromRoomAssignment(roomassignment);

    if (!!roomassignment.roomassignment_id) {
      url += '/' + roomassignment.roomassignment_id;
      delete roomassignment.roomassignment_id;
      payload = JSON.stringify(roomassignment);
      callback = this.http.put(url, payload, options);
    } else {
      payload = JSON.stringify(roomassignment);
      callback = this.http.post(url, payload, options);
    }
    return callback
      .toPromise()
      .then((res) => {
        let roomAssignment: RoomAssignment;
        let ret_obj: RoomAssignment;
        if (res != null) {
          roomAssignment = res as RoomAssignment;
          ret_obj = new RoomAssignment();
          if (roomAssignment) {
            ret_obj = RoomAssignment.toRoomAssignment(roomAssignment);
          }
        }

        return ret_obj;
      })
      .catch((err) => observableThrowError(err).toPromise());
  }

}
