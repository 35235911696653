import { Observable, of, throwError, timer } from 'rxjs';
import { delay, delayWhen, mergeMap, retryWhen, tap } from 'rxjs/operators';

const getErrorMessage = (status: number, maxRetry: number) => {
  return {
    status: status,
    msg: `Tried to load Resource over XHR for ${maxRetry} times without success. Giving up...`
  }
};

const DEFAULT_MAX_RETRIES = 5;

export function delayedRetry(
  delayMs: number,
  maxRetry = DEFAULT_MAX_RETRIES,
  delayWhenFn = () => { return timer(0) },
  tapFn = () => { }) {
  let retries = maxRetry;
  return (src: Observable<any>) =>
    src.pipe(
      tap({
        next: (x) => {
          retries = DEFAULT_MAX_RETRIES;
        }
      }),
      retryWhen(
        (errors: Observable<any>) => errors.pipe(
          // delay(delayMs),
          delayWhen(delayWhenFn),
          tap(tapFn),
          mergeMap(error => retries-- > 0 ?
            of(error) :
            throwError(getErrorMessage(error.status, maxRetry)))
        )
      )
    );
}
