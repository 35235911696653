export class Hotel {
  id: number
  created: string
  updated: string
  name: string
  org_type: string
  phone: string
  default_email: string
  has_in_out_dates: boolean
  has_linked_customers: boolean
  logo: any
  company_code: string
  primary_colour: string
  thank_you_text: string
  terms_and_conds_text: string
  simple_sign_up_form_text: string
  full_sign_up_form_text: string

  constructor(
    id?: number,
    created?: string,
    updated?: string,
    name?: string,
    default_email?: string
  ) {
    this.id = id
    this.created = created
    this.updated = updated
    this.name = name
    this.default_email = default_email
  }
}

export class Organization {
  id: number
  created: string
  updated: string
  name: string
  org_type: string
  phone: string
  default_email: string
  has_in_out_dates: boolean
  has_linked_customers: boolean
  logo: any
  company_code: string
  primary_colour: string
  thank_you_text: string
  terms_and_conds_text: string
  simple_sign_up_form_text: string
  full_sign_up_form_text: string
}
