import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {

  constructor() { }

  set(id: string, value: any): void {
    localStorage.setItem(id, JSON.stringify(value));
  }

  get(id: string): any {
    const item = localStorage.getItem(id);
    return item == null ? undefined : JSON.parse(item);
  }

  delete(id): void {
    if (localStorage.getItem(id)) {
      localStorage.removeItem(id);
    }
  }
}
