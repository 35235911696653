import { Directive, Input } from '@angular/core'
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms'

const UK_REGEX: RegExp = /((\+*44(\s\(0\)\s|\s0\s|\s)?)|0)7\d{3}(\s)?\d{6}/g
const AU_REGEX: RegExp = /(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/g


@Directive({
  selector: '[appPhoneIsValid]',
  providers: [{ provide: NG_VALIDATORS, useExisting: PhoneIsValidDirective, multi: true }]
})
export class PhoneIsValidDirective implements Validator {
  @Input('appPhoneIsValid') phoneIsValid: string

  constructor() { }

  validate(control: AbstractControl): ValidationErrors | null {
    return phoneIsValidValidator()(control)
  }

}

// TODO: move this to a validation service
export function phoneIsValidValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    // resetting any errors before we continue
    control.setErrors({ invalidPhone: false })
    const ukMatch = UK_REGEX.test(control.value)
    if (ukMatch) return null
    const auMatch = AU_REGEX.test(control.value)
    if (auMatch) return null

    control.setErrors({ invalidPhone: true })
    return { invalidPhone: true }
  };
}
