import { AllReqStream } from "./all-req-stream.model";

export class StreamsUpdatedAllReq {
  allRequestStreams: AllReqStream[];
  hasUpdated: boolean;
  statusImpFlag : boolean;

  constructor(
    streams?: AllReqStream[],
    hasUpdated?: boolean,
    statusImpFlag?:boolean) {
    this.allRequestStreams = streams;
    this.hasUpdated = hasUpdated;
    this.statusImpFlag = statusImpFlag 
  }
}
