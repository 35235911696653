import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterBy'
})
export class FilterByPipe implements PipeTransform {

  // we need a prop to search by and the args to search in
  // we also assume that if we don't have 'prop' then value must be a primitive type
  transform(value: any, args?: any[], prop?: string, get_hotel?: boolean): any {
    if (!value || !args || args.length === 0) {
      return;
    }

    let ret_val: any;
    if (value === Object(value)) {
      // if passed value is an object, use prop
      ret_val = args.find(a => a[prop] == value[prop]);
      // doing == cause we might have a string and need to check against a number
    } else {
      // else look for value directly - we might be looking for a string
      // check the first value of args to see if it's primitives or not
      if (args[0] === Object(args[0])) {
        ret_val = args.find(a => a[prop] == value);
      } else {
        ret_val = args.find(a => a === value);
      }
    }
    if (!get_hotel) {
      return !ret_val ? 'Operator' : ret_val.first_name + ' ' + ret_val.last_name;
    } else {
      return !ret_val ? '' : ret_val.name;
    }

    // return ret_val;
  }

}
