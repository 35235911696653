import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateFormatCustom'
})
export class DateFormatCustomPipe implements PipeTransform {

  transform(value: any, format?: any): any {
    if (!format) {
      // args should be a string
      format = '';
    }
    const momentDate = moment(value);

    // If moment didn't understand the value, return it unformatted.
    if (!momentDate.isValid()) return value;

    // Otherwise, return the date formatted as requested.
    return momentDate.format(format);
  }

}
