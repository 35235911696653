import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timeInQueue',
  pure: false
})
export class TimeInQueuePipe implements PipeTransform {

  transform(messageIn: any, messageOut?: any): any {
    if (!messageIn && !messageOut) {
      return '';
    }
    let timeToCheck;
    // first we check which one we need to check (if messageOut is greater, we use that)
    if (messageOut) {
      timeToCheck = messageIn > messageOut ? messageIn : messageOut;
    } else {
      timeToCheck = messageIn;
    }

    if (timeToCheck == null) {
      return '-s';
    } else if (timeToCheck == 0) {
      return '<1 min'
    }

    // let today = Moment();
    const now = moment();
    const message_date = moment(timeToCheck);
    let dur;
    if (message_date.isValid()) {
      dur = moment.duration(now.diff(message_date, 'milliseconds', true));
    } else {
      // we check if the message_date time is valid, if it's not then we just use the date of 'now' to show at the top
      dur = moment.duration(now.diff(now, 'milliseconds', true));
    }
    // console.log(diff);
    if (!dur.isValid()) {
      return '';
    }
    let val = '';
    if (dur.asSeconds() < 60) {
      val = '<1 min';
    } else if (dur.asHours() >= 8) {
      val = '>8 hrs';
    } else {
      const hours = Math.floor(dur.asHours());
      const minutes = Math.floor(dur.asMinutes()) - hours * 60;
      if (hours > 0) {
        val += Math.floor(hours) + (Math.floor(minutes) !== 0 ? 'h ' : Math.floor(hours) == 1 ? ' hr' : ' hrs');
      }
      if (minutes > 0) {
        val += Math.floor(minutes) + (Math.floor(hours) !== 0 ? 'm' : Math.floor(minutes) == 1 ? ' min' : ' mins');
      }
    }
    return val;
  }

}
