export class User {
  id: number;
  username: string;
  email: string;
  groups: string[];
  profile_image: any;
  location: string;
  description: string;
  default_language: string;
  languages: string[];
  role: string;
  first_name: string;
  last_name: string;
  password: string;
  phone: string;
  hotel: number;
  company_name: string;
  playing_notification_sound: boolean = true;
  enter_to_send_message: boolean = false;
  new_password: string;
  constructor(
    id?: number,
    username?: string,
    email?: string,
    groups?: string[],
    profile_image?: string,
    location?: string,
    description?: string,
    default_language?: string,
    languages?: string[],
    role?: string,
    first_name?: string,
    last_name?: string,
    password?: string,
    phone?: string,
    hotel?: number,
    company_name?: string,
    playing_notification_sound?: boolean,
    enter_to_send_message?: boolean,
    new_password?: string
  ) {
    this.id = id;
    this.first_name = first_name;
    this.last_name = last_name;
    this.username = username;
    this.email = email;
    this.groups = groups;
    this.profile_image = profile_image;
    this.location = location;
    this.description = description;
    this.default_language = default_language;
    this.languages = languages;
    this.role = role;
    this.password = password;
    this.phone = phone;
    this.hotel = hotel;
    this.company_name = company_name;
    this.playing_notification_sound = playing_notification_sound;
    this.enter_to_send_message = enter_to_send_message;
    this.new_password = new_password;
  }
}
