
import { throwError as observableThrowError, Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseDjangoService } from './base-django.service';
import { User } from '../../shared/user.model';
import { AuthenticationService } from './authentication.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class UserService extends BaseDjangoService {

  users: User[];
  current_user: User;
  currentUserUpdated: Subject<User> = new Subject();
  gettingUser = false;

  constructor(
    private http: HttpClient,
    authenticationService: AuthenticationService
  ) {
    super(authenticationService);
    this.apiUrl = this.usersUrl;
    this.apiUrl += '/web.user/';
    this.subRoute = 'current_user/';
  }

  getAll(): Promise<User[]> {
    const options = this.getOptions();

    return this.http.get(this.apiUrl, options)
      .toPromise()
      .then((res) => {
        this.users = res as User[];
        return this.users;
      })
      .catch((err) => observableThrowError(err).toPromise());

    // .map((res) => res as Contact[]);
  }

  getCurrentUserUpdated(): Observable<User> {
    return this.currentUserUpdated.asObservable();
  }

  resetPassword(username: string, callback_url: string): Promise<boolean> {
    const params = {
      username: username,
      callback: callback_url
    };
    const url = this.apiUrl + 'reset_password/';
    return this.http.post(url, params)
      .toPromise()
      .then(
        (res) => {
          if (res) {
            // do something if we actually get something back from the api
            console.log(res);
          }
          return true;
        })
      .catch(
        (err) => {
          return Promise.reject(err);
        });
  }

  setPassword(password: string): Promise<boolean> {
    const params = {
      new_password: password
    };
    const url = this.apiUrl + 'change_password/';
    const options = this.getOptions();

    return this.http.post(url, params, options)
      .toPromise()
      .then(
        (res) => {
          if (res) {
            // do something if we actually get something back from the api
            console.log(res);
          }
          return true;
        })
      .catch(
        (err) => {
          return Promise.reject(err);
        });
  }

  save(user: User): Promise<User> {
    // need to move this into the base class
    let data: any;
    let url;
    if (user.id != null) {
      url = this.apiUrl + user.id + '/'; // need to put stupid slash on the end
    } else {
      url = this.apiUrl
    }

    data = user;
    // const options = this.options;
    const options = this.getOptions();

    if (typeof user.profile_image === 'string') {
      delete data.profile_image;
    } else if (user.profile_image instanceof File) {
      delete options['headers']['Content-Type'];
      data = this.getFormDataObject(user);
    }

    return this.http.put(url, data, options)
      .toPromise()
      .then(
        (res) => {
          const ret = res as User;
          this.current_user = ret;
          this.currentUserUpdated.next(this.current_user);
          return ret;
        }).catch(
          (err) => {
            // do something with error
            return observableThrowError(err).toPromise();
          }
        );
  }

  getCurrentUser(): Promise<User> {
    if (this.gettingUser) {
      return Promise.resolve(new User);
    }
    // need to move this into the base class
    const url = this.apiUrl + this.subRoute;
    this.gettingUser = true;
    const options = this.getOptions();

    return this.http.get(url, options)
      .toPromise()
      .then(
        (res) => {
          const user = res as User;
          this.current_user = user;
          this.currentUserUpdated.next(this.current_user);
          this.gettingUser = false;
          return user;
        }).catch(
          (err) => {
            // do something with error
            this.gettingUser = false;
            return observableThrowError(err).toPromise();
          }
        );
  }

}
