import { delay } from 'rxjs/operators'
import { Component, NgZone, OnDestroy, OnInit } from '@angular/core'
import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router'
import { ShowNavService } from './shared/show-nav.service'
import { OperatorService } from './core/services/operator.service'
import { TokenService } from 'app/core/services/token.service'
import { UserService } from './core/services/user.service'
import { User } from './shared/user.model'
import { ContactService } from './core/services/contact.service'
import { RoomAssignmentService } from './core/services/room-assignment.service'
import { SearchResult } from './core/models/search-result.model'
import { BehaviorSubject, Subscription } from 'rxjs'
import { BaseComponent } from './shared/base/base.component'
import { Contact } from './core/models/contact.model'
import { ToastyService, ToastyConfig } from 'ng2-toasty'
import { OperatorViewService } from './operator/operator-view.service'
import { Stream } from './core/models/stream.model'
import { StreamsUpdated } from './core/models/streams-updated.model'
import { HotelService } from './core/services/hotel.service'
import { Hotel } from './core/models/hotel.model'
import { QueueService } from './core/queue/queue.service'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { OfflinePopupComponent } from './offline-popup/offline-popup.component'
import { AuthenticationService } from './core/services/authentication.service'
import { StorageService } from './core/services/storage.service'

import { StylePicker } from './shared/style-picker'
import { HelperService } from './shared/helper.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent extends BaseComponent implements OnInit, OnDestroy {
  title = 'app'
  timeLeft = 3
  count = 0
  interval
  showNav: boolean
  offlineMode = false
  offlinePopup: any // is of type MatDialogRef
  showLoader: boolean = true
  show: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  selector = '#WelcomeUser'
  loginShowSubscription: Subscription
  retryTimerSubscription: Subscription
  contacts: Contact[]
  streams: Stream[]
  currentOrganization: Hotel

  private subscriptions: Array<Subscription> = []
  favIcon: HTMLLinkElement = document.querySelector('#appIcon')

  constructor(
    private showNavService: ShowNavService,
    private operatorService: OperatorService,
    private tokenService: TokenService,
    private userService: UserService,
    private router: Router,
    contactService: ContactService,
    private roomAssignmentService: RoomAssignmentService,
    private toastyService: ToastyService,
    private toastyConfig: ToastyConfig,
    private operatorViewService: OperatorViewService,
    private hotelService: HotelService,
    private queueService: QueueService,
    private dialog: MatDialog,
    private storageService: StorageService,
    public authenticationService: AuthenticationService,
  ) {
    super(contactService)
    this.toastyConfig.theme = 'material'
  }

  private resetDisconnectVariables() {
    this.timeLeft = 3
    this.count = 0
  }

  logout(event?: any): void {
    if (event) {
      event.preventDefault()
    }
    this.operatorService.logout$()
      .subscribe(
        (res) => {
          if (res) {
            this.tokenService.remove()
            this.router.navigate(['login'])
            return
          }
        },
        (err) => {
          let error_text: string
          try {
            error_text = err
          } catch (e) {
            error_text = 'Something went wrong'
          }
          if ((typeof error_text === 'string' && error_text.toLowerCase().indexOf('server') > -1)
            || err.status === 404 || (err.message && err.message.toLowerCase() === 'unauthorized')) {
            // something happened in the backend so let's force a logout
            this.operatorService.keepLogin = false
          } else {
            this.operatorService.keepLogin = false
          }
          // if we errored from the logout here then we want to keep it so that we can try later
          this.resetDisconnectVariables()
          this.router.navigate(['login'])
        })
  }

  subscribeToUserUpdate(): void {
    this.userService.getCurrentUserUpdated().subscribe(
      (user: User) => {
        this.user = user
      }, err => console.log(err))
  }

  /**
   * function to subscribe to the login/show subscription, we put in a delay as this observable runs
   * immediately after ini (it seems) and causes the 'Expression has changed after it was checked' error
   *
   * This is only run once
   */
  subscribeToLoginShow(): void {
    // this function should only be called once for now
    if (this.loginShowSubscription) return
    this.loginShowSubscription =
      this.operatorService.loggedInEvent
        .pipe(
          delay(0)
        )
        .subscribe(res => {
          this.show.next(res)
          // after we know to show the rest of the page, we should be good
          this.loginShowSubscription.unsubscribe()
          this.loginShowSubscription = null
        }, err => {
          console.log(err)
          throw err
        })
  }

  /**
   * Subscribe to the retrying of the queue service so we can update the UI and let the user
   * know about changes with the connection.
   *
   */
  subscribeToRetrying(): void {
    this.subscriptions.push(
      this.queueService.retrying.subscribe(res => {
        this.offlineMode = !!res
        if (this.offlineMode) {
          let retries = this.queueService.retries.getValue()
          if (retries == null || retries === 0) {
            retries = 1
          }
          this.offlinePopup = this.dialog.open(OfflinePopupComponent, {
            disableClose: true,
            width: '350px',
            data: {
              timeLeft: this.timeLeft,
              showCountDown: true,
              maxRetries: this.queueService.maxRetries,
              count: retries
            },
          })
          this.offlinePopup.afterClosed().subscribe(result => {
            console.log('The dialog was closed')
            if (result) {
              const forceLogout = this.queueService.forceLogout.getValue()
              if (forceLogout) {
                this.operatorService.keepLogin = false
                // now we set the expiry to null so we log the user out
                const auth = this.authenticationService.getToken()
                if (auth && auth.date_added) {
                  auth.date_added = null
                }
                this.authenticationService.setAccessToken(auth)
                this.storageService.delete(this.operatorService.storageId)
                this.operatorService.operatorLogin = null
                // if the operator gets kicked out of the backend, logout them out completely
                this.tokenService.remove()

                // after kicking the current operator out, reset the forceLogout flag
                this.queueService.forceLogout.next(false)
              }

              this.logout()
              this.offlineMode = false
            }
          })
        } else if (!this.offlineMode) {
          this.queueService.resetMaxRetries()
          this.queueService.resetRetries()
          this.resetDisconnectVariables()
          if (this.offlinePopup) {
            this.offlinePopup.close()
          }
          // this.offlinePopup = null;

        }
        // this.show.next(!res);
      }, err => {
        console.log(err)
        // TODO: show an error message or let the user retry manually
        throw err
      })
    )
  }

  /**
   * TODO: remove if not used
   * @deprecated
   */
  subscribeToRetries(): void {
    this.subscriptions.push(
      this.queueService.retries.subscribe(res => {
        if (res >= this.queueService.maxRetries) {
        }
      }, err => {
        console.log(err)
        //TODO: show an error message or let the user retry manually
        throw err
      })
    )
  }

  subscribeToRetryTimer(): void {
    this.retryTimerSubscription =
      this.queueService.retryTimerValue.subscribe(res => {
        if (res != null) {
          this.count++
          let retries = this.queueService.retries.getValue()
          // we need to check if retries is null aso we don't error;
          // we also need to add 1 to the retries since it starts at 0 and
          // we want to show a 1 instead of a 0 as the starting value
          console.log(retries)
          retries = retries == null ? 1 : retries + 1
          this.timeLeft = res / 1000

          if (this.count > this.queueService.maxRetries && this.offlinePopup) {
            this.offlinePopup.componentInstance.data = {
              showCountDown: false
            }
          } else {
            this.interval = setInterval(() => {
              if (this.timeLeft > 0) {
                this.timeLeft--
                if (this.offlinePopup) {
                  this.offlinePopup.componentInstance.data = {
                    timeLeft: this.timeLeft,
                    showCountDown: true,
                    maxRetries: this.queueService.maxRetries,
                    count: retries
                  }
                }
              } else {
                clearInterval(this.interval)
              }
            }, 1000)
          }
        }
      }, err => {
        throw err
      })
  }

  subscribeToRouteEvents(): void {
    this.subscriptions.push(
      this.router.events.subscribe((event: Event) => {
        switch (true) {
          case event instanceof NavigationStart: {
            this.showLoader = true
            break
          }
          case event instanceof NavigationEnd:
          case event instanceof NavigationCancel:
          case event instanceof NavigationError: {
            this.showLoader = false
          }
          default: {
            break
          }
        }
      })
    )
  }

  subscribeToIconChange(): void {
    this.subscriptions.push(
      this.hotelService.icon$.subscribe((icon) => {
        this.favIcon.href = icon
      }, err => {
        console.log(err)
        //TODO: show an error message or let the user retry manually
        throw err
      })
    )
  }

  ngOnInit() {
    this.user = new User()
    this.currentOrganization = new Hotel()
    this.subscribeToRouteEvents()
    this.subscribeToUserUpdate()
    this.subscribeToLoginShow()
    this.subscribeToRetrying()
    this.subscribeToRetryTimer()
    this.subscribeToRetries()
    this.subscribeToIconChange()

    // first we check the interval and clear it (if for some reason it exists still)
    if (this.interval) {
      clearInterval(this.interval)
    }
    // this.showNav = this.showNavService.showNav;
    this.subscriptions.push(this.showNavService.showNavUpdated.subscribe(
      (showNav) => {
        this.showNav = showNav
      }
    ))

    this.subscriptions.push(this.operatorService.streamsUpdated.subscribe(
      (streamsUpdated: StreamsUpdated) => {
        if (streamsUpdated) {
          this.streams = streamsUpdated.streams
        }
      }))
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      for (const sub of this.subscriptions) {
        sub.unsubscribe()
      }
    }
  }

}
