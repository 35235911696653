import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Hotel } from '../core/models/hotel.model'
import { HotelService } from '../core/services/hotel.service'
import { UserService } from '../core/services/user.service';
import { StylePicker } from '../shared/style-picker'
import { User } from '../shared/user.model';
import { ConstantsService } from "../core/services/constants.service";
import { OperatorService } from '../core/services/operator.service';
import { SelectOption } from "../core/models/select-option.model";
import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { ShowNavService } from "../shared/show-nav.service";
import { Location } from "@angular/common";
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { HelperService } from "../shared/helper.service";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit, OnDestroy {
  avatar: File;
  @ViewChild('profileForm') public profileForm: NgForm;
  @ViewChild('phone') public phone;
  @ViewChild('fileInput') fileInput;
  user: User;
  loaded: boolean;
  loading: boolean;
  languages: SelectOption[];
  selectedLanguage: string;
  avatarSrc: string;
  error: string = '';
  success: string = '';
  redirectMessage = '';
  public data: SelectOption[];
  subscriptions: Subscription[] = [];
  clientName = '';
  logoPath = '';
  currentHotel: Hotel;
  errorMessage = '';

  constructor(
    private userService: UserService,
    private constantsService: ConstantsService,
    private router: Router,
    private showNavService: ShowNavService,
    private location: Location,
    private helperService: HelperService,
    private hotelService: HotelService,
    private stylePicker: StylePicker,
    private OperatorService: OperatorService,

  ) {
  }

  goBack(): void {
    // TODO: change this route based on who is logged in
    this.location.back();
    // this.router.navigate(['/operator']);
  }

  handleLanguageFilter(value: any) {
    this.data = this.languages.filter((s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  handleValueChange(value: any) {
    let code: string;
    if (this.user.languages == null) {
      this.user.languages = [];
    }
    let idx = this.languages.findIndex(l => l.text === value);
    if (idx === -1) {
      // now we check the code
      idx = this.languages.findIndex(l => l.value === value);
      if (idx === -1) {
        return;
      } else {
        code = this.languages[idx].value;
      }
    } else {
      code = this.languages[idx].value;
    }
    this.user.languages.push(code);
  }

  removeLanguage(idx: number): void {
    if (idx != null && this.user.languages.length > idx) {
      this.user.languages.splice(idx, 1);
    }
  }

  updateProfileImage(event: any): void {
    const avatar = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];

    const pattern = /image-*/;
    const reader = new FileReader();

    if (!avatar.type.match(pattern)) {
      return;
    }
    this.loaded = false;

    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(avatar);
    if (this.avatar != null) {
      this.user.profile_image = this.avatar;
    }
  }

  _handleReaderLoaded(e): void {
    const reader = e.target;
    this.avatarSrc = reader.result;
    this.loaded = true;
  }

  enterToSendMessageHandler(event): void {
    this.user.enter_to_send_message = event;
  }

  playNotificationHandler(event): void {
    this.user.playing_notification_sound = event
  }

  checkNumber(event):void {
    this.OperatorService.checkPhoneNumber(this.profileForm.value.phone)
      .then((res) => {
        this.errorMessage = '';
        // do something on message sent
        if (res) {
         // this.dialogRef.close();
        //  const ops = (h: string, t: string): ToastOptions => super.getToastOptions(h, t);
          //this.toastyService.success(ops("Successfully", "Message Sent"));
          //this.messageContent = '';
          
        }
      })
      .catch((err) => {
        this.errorMessage = "Please enter correct mobile number including country code eg 61419371555";
        throw err;
      });




  }

  saveProfile(): void {

    if (!this.profileForm.valid) {
      // should loop over all required fields, do this later
      if (this.profileForm.controls && this.profileForm.controls.phone && !this.profileForm.controls.phone.valid) {
        this.profileForm.controls.phone.markAsTouched()
      }
      return;
    }

    this.loading = true;
    if (this.fileInput && this.fileInput.nativeElement && this.fileInput.nativeElement.files) {
      this.user.profile_image = this.fileInput.nativeElement.files[0];
    }
    this.error = '';
    this.success = '';
    this.userService.save(this.user).then(
      (res) => {
        console.log(res);
        this.user = res;
        this.avatarSrc = res.profile_image;
        this.loading = false;
        this.success = 'Successfully saved profile';
        this.redirectMessage = 'Please wait...';
        setTimeout(() => {
          this.redirectMessage = '';
          this.router.navigate(['/operator']);
        }, 3000);
        // if (this.profileForm.controls && this.profileForm.controls.phone && !this.profileForm.controls.phone.valid) {
        //   this.profileForm.controls.phone.reset()
        // }
      })
      .catch(
        (err) => {
          let errors: any;
          try {
            errors = err.error;
          } catch (e) {
            console.log(e);
          }
          if (errors && typeof errors === 'object') {
            const keys = Object.keys(errors);
            // there should be only one key - if there's more... then something went wrong
            for (const key of keys) {
              this.error = errors[key];
            }
          } else {
            this.error = 'An error occurred when resetting your password, please try again..';
          }
          this.loading = false;
        });
  }

  getCurrentUser(): void {
    this.userService.getCurrentUser().then(
      (res: User) => {
        console.log('user that was found', res)
        this.user = res;
        this.avatarSrc = res.profile_image;
      })
      .catch(
        (err) => {
          console.log(err);
        });
  }

  getLanguages(): void {
    this.constantsService.get().then((choices) => {
      this.languages = choices.languages;
    }).catch((error) => {
      this.languages = [];
      console.log(error);
    });
  }

  subscribeToRouteEvents(): void {
    this.subscriptions.push(
      this.router.events.subscribe((event: Event) => {
        switch (true) {
          case event instanceof NavigationStart: {
            this.loading = true;
            break;
          }
          case event instanceof NavigationEnd:
          case event instanceof NavigationCancel:
          case event instanceof NavigationError: {
            this.loading = false;
          }
          default: {
            break;
          }
        }
      })
    )
  }

  subscribeToCurrentHotel(): void {
    this.subscriptions.push(
      this.hotelService.hotel$
        .subscribe(hotel => {
          this.currentHotel = hotel
        }, err => {
          console.warn(err)
          console.log('could not get the list of organisations')
        })
    )
  }

  ngOnInit() {
    this.user = new User();
    this.getLanguages();
    this.getCurrentUser();
    this.subscribeToRouteEvents();
    this.subscribeToCurrentHotel()

    this.stylePicker.loadStyle(this.currentHotel.company_code + '.css')
    const retVal = this.helperService.getClientMapping(this.currentHotel.company_code);
    this.hotelService.icon$.next(retVal.faviconPath)

    this.showNavService.set(true);
    this.loading = false;
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      for (const sub of this.subscriptions) {
        sub.unsubscribe();
      }
    }
  }

}
