import { ChannelSubscription } from './contact.model';
import { ChannelEndpoint } from './channel-endpoint.model';

export class RoutingInformation {

  current_hop: number;
  direction: string;
  retries: number;
  route_id: string;
  route_instance_key: string;
  source_channel_subscriber: ChannelSubscription;
  state: string;
  via: ChannelEndpoint[];

}
