import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorRoutingModule } from './routing/errors-routing.module';
import { RouterModule } from '@angular/router';
import { ErrorsComponent } from './component/errors.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { init } from '@sentry/browser';
import { INITIALIZER, ModuleOptions, OPTIONS } from './tokens';
import { SentryErrorInterceptor } from '../interceptor/sentry.interceptor';

/**
 * Initializer function to setup sentry logging.
 *
 * @param - The module options
 * @returns - A promise for waiting to be resolved
 */
export function initializer(options: ModuleOptions): void {
  // configure sentry's browser library
  if (options.enabled) {
    // show report dialog
    if (options.dialog) {
      options.sentry.beforeSend = event => {
        if (event.exception) {
          console.log(event.exception);
          // showReportDialog(
          //   typeof options.dialog === 'object' ? options.dialog : undefined,
          // )
        }
        return event
      }
    }

    init(options.sentry)
  }
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ErrorRoutingModule,
  ],
  declarations: [
    ErrorsComponent
  ],
  providers: [
    // TODO: need to add in the ServersInterceptor, need to figure out why this isn't loading
    // {
    //   provide: ErrorHandler,
    //   useClass: ErrorsHandler,
    // },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: ServerErrorsInterceptor,
    //   multi: true
    // },{
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: AuthInterceptor,
    //   multi: true,
    // }
  ]
})
export class ErrorsModule {
  public static forRoot(options: ModuleOptions): ModuleWithProviders {
    return {
      ngModule: ErrorsModule,
      providers: [
        {
          provide: OPTIONS,
          useValue: options,
        },
        {
          provide: INITIALIZER,
          useFactory: initializer,
          deps: [OPTIONS],
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: SentryErrorInterceptor,
          deps: [OPTIONS, INITIALIZER],
          multi: true,
        },
      ],
    }
  }
}
