import { Injectable } from '@angular/core';
import { environment as env } from '../../../environments/environment'
import { Tag } from '../models/contact.model';
import { AuthenticationService } from './authentication.service';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class BaseDjangoService {
  apiUrl: string;
  usersUrl: string;
  sendEmailUrl: string;
  subRoute: string;
  options: object;
  defaultSearchParam: string;
  tagProperties: string[] = ['languages', 'opera_validated', 'attributes'];
  singleProp: string[] = ['opera_validated'];
  _authenticationService: AuthenticationService;
  filter_key = 'filter';
  filter_by = 'filter_value';

  constructor(authenticationService: AuthenticationService) {
    this._authenticationService = authenticationService;
    this.apiUrl = env.api;
    this.usersUrl = env.users_api;
    this.subRoute = '';
    this.sendEmailUrl = '';
    this.options = this.getOptions();
  }

  getOptions(): object {
    const headers = this.getHeaders();
    return {
      headers: headers
    };
  }

  private getHeaders(): HttpHeaders {
    const headers = {
      Accept: 'application/json'
    };
    const token = this._authenticationService.getToken();
    if (token) {
      headers['Authorization'] = token.token_type + ' ' + token.access_token;
    }
    // headers.append('Accept', 'application/json');
    // headers.append('Content-Type', 'application/json');
    return new HttpHeaders(headers);
  }

  getFormDataObject(json_obj: any): FormData {
    if (!json_obj) {
      return null;
    }
    const fd = new FormData();
    const keys = Object.keys(json_obj);
    let key: string;
    for (let i = 0; i < keys.length; i++) {
      key = keys[i];
      if (json_obj[key] != null && typeof json_obj[key] === 'object' && json_obj[key].constructor.name === 'Array') {
        for (let j = 0; j < json_obj[key].length; j++) {
          fd.append(key, json_obj[key][j]);
        }
      } else {
        fd.append(key, json_obj[key]);
      }
    }
    return fd;
  }

  packTags(contact: any): any {
    contact.tags = [];
    for (const prop of this.tagProperties) {
      if (contact[prop] && typeof contact[prop] === 'object') {
        // lets assume string only properties for now
        for (const value of contact[prop]) {
          contact.tags.push(
            new Tag(prop, JSON.stringify(value))
          );
        }
      }
    }
    return contact;
  }

  unpackTags(contact: any): any {
    for (const prop of this.tagProperties) {
      if (this.singleProp.indexOf(prop) === -1) {
        contact[prop] = [];
      }
    }
    if (contact.tags) {
      for (const tag of contact.tags) {
        if (this.singleProp.indexOf(tag.tag_label) === -1) {
          if (!contact[tag.tag_label]) {
            contact[tag.tag_label] = [];
          }
          contact[tag.tag_label].push(JSON.parse(tag.tag_value));
        } else {
          contact[tag.tag_label] = JSON.parse(tag.tag_value);
        }

        // lets assume string only properties for now
      }
    }

    contact.tags = [];
    return contact;
  }
  unpackTagsForAllReq(contact: any): any {
    for (const prop of this.tagProperties) {
      if (this.singleProp.indexOf(prop) === -1) {
        contact[prop] = [];
      }
    }
    if (contact.tags) {
      for (const tag of contact.tags) {
        if (this.singleProp.indexOf(tag.tag_label) === -1) {
          if (!contact[tag.tag_label]) {
            contact[tag.tag_label] = [];
          }
          contact[tag.tag_label].push(JSON.parse(tag.tag_value));
        } else {
          contact[tag.tag_label] = JSON.parse(tag.tag_value);
        }

        // lets assume string only properties for now
      }
    }

    contact.tags = [];    
    return contact;
  }
  protected isArray(o): boolean {
    return Object.prototype.toString.call(o) === '[object Array]';
  }

}
