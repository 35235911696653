import { Injectable } from "@angular/core";
import { BehaviorSubject , Observable } from "rxjs";

@Injectable(
    {
        providedIn: 'root'
    })

    export class TabService {
        private tabKeySubject: BehaviorSubject<string>;
      
        constructor() {
          // Initialize tab key with default value
          this.tabKeySubject = new BehaviorSubject<string>('My Request');
        }
      
        setTabKey(key: string): void {
          this.tabKeySubject.next(key);
        }
      
        getTabKey(): Observable<string> {
          return this.tabKeySubject.asObservable();
        }
        resetTabKey(): void {
          this.tabKeySubject.next('My Request'); // Resetting the tab key to default value
        }
      }