import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from "@angular/forms";

@Directive({
  selector: '[appCompareTo][ngModel]',
  providers: [{
    provide: NG_VALIDATORS,
    useValue: CompareToDirective,
    multi: true
  }]
})
export class CompareToDirective implements Validator {
  @Input() appCompareTo: string;

  validate(c: AbstractControl): ValidationErrors {
    return this.appCompareTo ? CompareToValidator(this.appCompareTo)(c)
      : null;
  }

  registerOnValidatorChange(fn: () => void): void {
    console.log(Error("Method not implemented."));
  }

  constructor() { }

}

export function CompareToValidator(value: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const is_equal = value === control.value;
    return is_equal ? { 'appCompareTo': { value: control.value } } : null;
  };
}
