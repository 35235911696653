import {ENTER, SHIFT} from '@angular/cdk/keycodes'
import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ViewChildren, Input } from '@angular/core'
import { ConstantsService } from '../core/services/constants.service';
import { ContactService } from '../core/services/contact.service';
import { ContactExport } from '../core/shared/enum';
import { Contact } from '../core/models/contact.model';
import { RoomAssignmentService } from '../core/services/room-assignment.service';
import {
  GuestAttributes, Room, RoomAssignment, RoomAssignmentStatus,
  RoomContact
} from '../core/models/room-assignment.model';
import { SiteService } from "app/core/services/site.service";
import { SelectOption } from "../core/models/select-option.model";
import { Note } from "../shared/note.model";
import { User } from "../shared/user.model";
import { UserService } from "../core/services/user.service";
import { BaseComponent } from "../shared/base/base.component";
import { OperatorViewService } from "../operator/operator-view.service";
import { HumanReadablePipe } from "../core/pipes/human-readable.pipe";
import * as _ from "lodash";
import { SiteGuestAttributeTemplate } from "../core/models/site.model";
import * as moment from 'moment';
import { ToastyService } from "ng2-toasty";
import { Hotel } from "../core/models/hotel.model";
import { HotelService } from "../core/services/hotel.service";
import { Subscription } from "rxjs";
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment as env } from '../../environments/environment';
import { AuthenticationService } from '../core/services/authentication.service';
import { of as observableOf, throwError as observableThrowError, Observable } from 'rxjs';
import { NgForm } from '@angular/forms';
import { SyncActiveCampaign } from '../components/sync-active-campaign/sync-active-campaign.component';
import { MatDialog } from '@angular/material/dialog';
import { OperatorService } from '../core/services/operator.service';
import { Stream } from 'app/core/models/stream.model';
import { StreamsUpdated } from '../core/models/streams-updated.model';
@Component({
  selector: 'app-guest-panel',
  templateUrl: './guest-panel.component.html',
  styleUrls: ['./guest-panel.component.css']
})
export class GuestPanelComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChildren('multiselect') public multiselect: any;
  note: string;
  guestNote: Note = new Note();
  guestNotes: Note[] = [];
  editDetails: boolean;
  languages: SelectOption[];
  contact: Contact = new Contact();
  guestDetails: RoomAssignment = new RoomAssignment();
  roomAssignments: RoomAssignment[] = [];
  roomassignmentAttrs: GuestAttributes[];
  room_contacts: RoomContact[] = [];
  keyCodes = [9, 13];
  user: User;
  users: User[];
  editRoomContactIdx: number;
  contacts: Contact[];
  autosaveIntervalSecs: 10;
  // temp test for kendo ui thingy
  checkin_date: Date;
  newAttribute: string;
  validationIsEditable: boolean = false;
  currentHotel: Hotel;
  currentOrganization: Hotel;
  subscriptions: Subscription[];
  not_auto_checkin_date_values = ['hotel'];
  edittingNotes = false;
  orgType: number = 0;
  roomNumberLabelText = 'Room number';
  apiUrl: string;
  _authenticationService: AuthenticationService;
  shopifyObject: any = { };
  maskContactObject: any = { };
  userPhoneWithMask: string;
  userPhoneWithoutMask: string;
  @ViewChild('shopifyForm') form: NgForm;
  acEnabled: boolean;
  acBaseURL: string;
  acAPIKey: string;
  public min: Date = new Date(1917, 0, 1);
  public max: Date = new Date(2050, 11, 31);
  public data: GuestAttributes[];
  public languageData: SelectOption[];
  currentActivePanel: string = 'static-1';
  @ViewChild('additionalGuestAcc') public additionalGuestAcc: any;
  @Output() roomContactsEmitter = new EventEmitter<Room[]>();
  @Input() pleaseWait: boolean;
  stream_id : string = ""
  importantFlag : boolean = false
  constructor(
    private constantsService: ConstantsService,
    contactService: ContactService,
    private roomassignmentService: RoomAssignmentService,
    private siteService: SiteService,
    private userService: UserService,
    private operatorViewService: OperatorViewService,
    private humanReadablePipe: HumanReadablePipe,
    private toastyService: ToastyService,
    private hotelService: HotelService,
    private router: Router,
    private http: HttpClient,
    authenticationService: AuthenticationService,
    private operatorService: OperatorService,
    public dialog: MatDialog
  ) {
    super(contactService);
    this.apiUrl = env.api;
    this._authenticationService = authenticationService;
  }

  private getHeaders(): HttpHeaders {
    const headers = {
      Accept: 'application/json'
    };

    const token = this._authenticationService.getToken();
    if (token) {
      headers['Authorization'] = token.token_type + ' ' + token.access_token;
    }
    return new HttpHeaders(headers);
  }

  getOptions(): object {
    const headers = this.getHeaders();
    return {
      headers: headers
    };
  }

  handleLanguageFilter(value) {
    this.languageData = this.languages.filter(
      (s) =>
        s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1
    )
  }

  findGuest(idx?: number, phone?: string): void {
    if (!phone || idx === null) return

    const index = this.contactService.contacts.findIndex(c => c.channel_subscription.resource_identifier === phone)
    if (index > -1) {
      this.room_contacts[idx].contact = _.cloneDeep(this.contactService.contacts[index])
      this.room_contacts[idx].contact_id = this.room_contacts[idx].contact.contact_id
    }
    // adding a contact incase we don't have one
    if (!this.room_contacts[idx].contact) this.room_contacts[idx].contact = new Contact()
  }

  addAttribute(event: any): void {
    if (this.keyCodes.indexOf(event.keyCode) > -1) {
      if (this.newAttribute && this.newAttribute.trim()) {
        this.contact.attributes.push(this.newAttribute);
      }
      this.newAttribute = '';
    }
  }

  removeAttribute(idx: number): void {
    if (this.contact && this.contact.attributes && this.contact.attributes.length > idx) {
      this.contact.attributes.splice(idx, 1);
    }
  }

  addAdditionalGuest(): void {
    this.room_contacts.push(new RoomContact);
  }

  removeAdditionalGuest(idx?: number): void {
    if (idx != null) {
      this.room_contacts.splice(idx, 1);
    }
  }

  editNotes(): void {
    // this.note = this.guestNote.note_text && this.guestNote.note_text.data ?
    //   this.guestNote.note_text.data :
    //   '';
    if (this.guestNotes && this.guestNotes.length) {
      this.edittingNotes = !this.edittingNotes;
      for (const note of this.guestNotes) {
        note.edit = !note.edit;
      }
    }
  }

  sortNotes(): void {
    this.guestNotes.sort((left: Note, right: Note) => {
      let diff: number;
      const left_date = new Date(left.note_date);
      const right_date = new Date(right.note_date);
      diff = left_date < right_date ? -1 : left_date > right_date ? 1 : 0;
      return diff;
    })
      .reverse();
  }

  saveNote(note: Note): void {
    console.log("inside notes",this.contact.contact_id, note);
    
    this.contactService.saveNote(this.contact.contact_id, note)
      .then(
        (res) => {
          this.guestNotes.push(res);
          this.sortNotes();
          this.guestNote = this.guestNotes[this.guestNotes.length - 1];
          this.note = '';
          // now let's get the contact so we have the note in the list
          return this.contactService.getById(this.contact.contact_id);
        })
      .then((res) => {
        if (res && res.length === 1) {
          this.contact = Contact.toContact(res[0]);
        }
      })
      .catch(
        (err) => {
          // just push the note anyway, for now
          let new_note = Object.assign({}, note);
          this.guestNotes.push(new_note);
          this.sortNotes();
          // this.guestNote = this.guestNotes[this.guestNotes.length - 1];
          this.note = '';
          throw err;
        });
  }

  checkKeydown(event: any , note:any , i:any): boolean {
    console.log('event passed', event)
 console.log("note",note);
 
    if (event.keyCode === ENTER) {
      if (!event.shiftKey) {
        event.preventDefault();
        this.updateNotes();
        return false;
      }
    }
  }

  updateNotes(): void {
    this.edittingNotes = false;
    this.addNote();
    if (this.guestNotes) {
      for (const note of this.guestNotes) {
        if (note.edit) {
          note.edit = false;
        }
      }
    }
  }

  addNote(): void {
    console.log("inside add note");
    console.log(this.note);
    
    if (this.note && this.note.trim()) {
      const new_note = new Note();
     console.log("inside condition");
     
      new_note.note_text.data = this.note;
      new_note.note_text.encoding = 'text/plain';
      new_note.note_text.lang = 'en-au';
      new_note.note_date = new Date();
      new_note.user = this.user;
      new_note.posted_by = this.user != null && this.user.id != null ? this.user.id.toString() : '';
      this.saveNote(new_note);

    }
  }

  getLanguages(): Promise<SelectOption[]> {
    return this.constantsService.get().then((choices) => {
      return choices.languages;
    });
  }

  saveDetails(firstName, lastName, eMail): void {
    if(eMail){
      this.guestDetails.rooms[0].room_number = eMail
      this.contact.first_name = firstName;
      this.contact.email_id = eMail;
      this.contact.last_name = lastName;
      this.contact.channel_subscription.shopifyVerified = true;
      this.contact.channel_subscription.shopify = true;
    }
    // need to save contact first so we have the id
    if (!this.guestDetails.roomassignment_status) {
      this.guestDetails.roomassignment_status = RoomAssignmentStatus.checkedin;
    }
    
    if (this.guestDetails.is_primary) {
      // if we are on the primary guest details (i.e. we are not editing the additional guest info
      // automatically looks at: this.room_contacts
      this.guestDetails = this.addContactsToRooms(this.guestDetails);
    }

    this.operatorViewService.save(this.contact)
      .then(
        (res) => {
          let promise: Promise<RoomAssignment | {}>;
          if (res) {
            this.contact = res;
            promise = this.operatorViewService.saveRoomAssignment(res, this.guestDetails);
          } else {
            promise = Promise.resolve({});
          }
          return promise;
        })
      .then(
        (res) => {
          // room assignment could be null or undefined since PUT response returns
          // an empty response object
          if (res) {
            this.contactService.setCurrentContact(this.contact);
          }
          this.roomContactsEmitter.emit(this.guestDetails.rooms)
          this.operatorService.callby('callbysave')
          if(eMail){
            this.addToast('Status updated successfully','', 'success')
          }else{
            this.addToast('Successfully saved guest details.',
            'Saving guest details.', 'success')
          }
        })
      .catch(
        (err) => {
          // do something on error
        });
  }

  saveGuestDetails(continueEditing = false): void {
    if (this.editDetails) {
      console.log(this.guestDetails);
      if (this.contact.first_name) {
        var firstName = null;
        var lastName = null;
        var eMail = null;
        this.saveDetails(firstName, lastName, eMail);
      }

    }
    this.editDetails = continueEditing;
  }

  editGuestDetails(): void {
    this.editDetails = !this.editDetails;
  }

  editAdditionalGuestDetails(): void {
    // check to see if the current panel is active, if it's not, we open it
    const idx = this.additionalGuestAcc.activeIds.indexOf(this.currentActivePanel);
    if (idx === -1) {
      this.additionalGuestAcc.toggle('static-1');
    }
    if (this.room_contacts && this.room_contacts.hasOwnProperty('length')) {
      for (const room_contact of this.room_contacts) {
        if (!room_contact.edit) {
          room_contact.edit = !room_contact.edit;
        }
      }
    }
  }

  setValidation(attr: SiteGuestAttributeTemplate): void {
    // if (this.validationIsEditable) {
    attr.attribute_value = !attr.attribute_value;
    var firstName = null;
    var lastName = null;
    var eMail = null;
    this.saveDetails(firstName, lastName, eMail);
    // }
  }

  editValidation(): void {
    this.validationIsEditable = !this.validationIsEditable;
  }

  updateValidation(): void {
    var firstName = null;
    var lastName = null;
    var eMail = null;
    this.saveDetails(firstName, lastName, eMail);
    this.editValidation();
  }

  isEditingGuestDetails(): boolean {
    let isEdit: boolean = false;
    if (!this.room_contacts || this.room_contacts.length === 0) {
      return isEdit;
    }
    for (const room_contact of this.room_contacts) {
      if (room_contact.edit) {
        isEdit = true;
        break;
      }
    }
    return isEdit;
  }

  isAddingNote(): boolean {
    return this.note && this.note.trim().length > 0;
  }

  saveAdditionalGuestDetails(): void {
    this.saveRoomContacts()
      .then((is_done) => {
        if (is_done) {
          // automatically looks at: this.room_contacts
          this.guestDetails = this.addContactsToRooms(this.guestDetails)
        }
        if (this.room_contacts && this.room_contacts.hasOwnProperty('length')) {
          for (const room_contact of this.room_contacts) {
            if (room_contact.edit) {
              room_contact.edit = !room_contact.edit;
            }
          }
        }

        this.roomContactsEmitter.emit(this.guestDetails.rooms)
        return this.operatorViewService.saveRoomAssignment(this.contact, this.guestDetails)
      }).then(
        (res) => {
          if (res && res.roomassignment_id) {
            this.guestDetails.roomassignment_id = res.roomassignment_id;
            // we are only getting the roomassignment id for now, should
          }
          this.roomContactsEmitter.emit(this.guestDetails.rooms)

          this.addToast('Successfully saved additional guest.',
            'Saving additional guest.', 'success')
        })
      .catch(
        (err) => {
          // do something on error
        });
  }

  private getRoomContacts(): void {
    // this.room_contacts = [];
    // const assignment = roomAssignment;
    if (this.guestDetails && this.guestDetails.rooms && this.guestDetails.rooms.length > 0) {
      for (const idx in this.guestDetails.rooms) {
        const room = this.guestDetails.rooms[idx];
        if (room.room_contacts && room.room_contacts.length > 0) {
          // promises[idx] = [];
          for (const room_contact of room.room_contacts) {
            room_contact.room_number = room.room_number;
            if (!room_contact.guest_attributes || room_contact.guest_attributes.length === 0) {
              room_contact.guest_attributes = [new GuestAttributes()];
            }

            // should have all the contacts
            const idx = this.contactService.contacts.findIndex(c => c.contact_id === room_contact.contact_id);
            if (idx > -1) {
              room_contact.contact = _.cloneDeep(this.contactService.contacts[idx]);
            }
            if (room_contact.contact_id !== this.guestDetails.primary_contact_hint) {
              // adding a contact incase we don't have one
              if (!room_contact.contact) room_contact.contact = new Contact()
              this.room_contacts.push(_.cloneDeep(room_contact));
            }
            // promises[idx].push(this.contactService.getById(room_contact.contact_id));
          }
        }
      }
    } else if (!this.guestDetails.rooms || this.guestDetails.rooms.length === 0) {
      this.guestDetails.rooms = [new Room()];
    } else if (!this.guestDetails.rooms[0].room_contacts || this.guestDetails.rooms[0].room_contacts.length === 0) {
      this.guestDetails.rooms[0].room_contacts = [new RoomContact()];
    }
  }

  private getRoomAssignment(contact: Contact): void {
    // if (!contact || !contact.contact_id) {
    // }
    this.guestDetails = new RoomAssignment()

    this.contactService.getAll()
      .then(
        (contacts: Contact[]) => {
          this.contacts = contacts
          return this.roomassignmentService.getAll()
        }).then(
          (roomAssignments: RoomAssignment[]) => {
            if (roomAssignments && roomAssignments.length > 0) {

              const reversedRoomAssignments = _.orderBy(roomAssignments, ['last_update_date'], ['desc'])
              // get the latest item in the list
              const primary_contact = reversedRoomAssignments.find(ra => ra.primary_contact_hint === contact.contact_id)
              // have to loop over the rooms since we could have multiple rooms associated with the room assignment
              const second_contact = reversedRoomAssignments.find(ra => {
                let found: boolean
                for (const room of ra.rooms) {
                  for (const room_contact of room.room_contacts) {
                    found = room_contact.contact_id === contact.contact_id
                    if (found) {
                      break
                    }
                  }
                  if (found) {
                    break
                  }
                }
                return found
              })

              if (!primary_contact && !second_contact) {
                this.guestDetails = new RoomAssignment()
              } else if (primary_contact != null && second_contact != null) {
                const primary_date = moment(primary_contact.last_update_date)
                const secondary_date = moment(second_contact.last_update_date)
                if (primary_date.isSameOrAfter(secondary_date) || primary_contact.primary_contact_hint == this.contact.contact_id) {
                  this.guestDetails = _.cloneDeep(primary_contact)
                } else {
                  this.guestDetails = _.cloneDeep(second_contact)
                  this.guestDetails = RoomAssignment.toSecondaryRoomAssignment(this.guestDetails, contact)
                }
                // now we check if either of the dates has the year set to 1970 and set them to null
                if (this.guestDetails.checkin_date && this.guestDetails.checkin_date.getFullYear() == 1970) {
                  this.guestDetails.checkin_date = null
                }
                if (this.guestDetails.checkout_date && this.guestDetails.checkout_date.getFullYear() == 1970) {
                  this.guestDetails.checkout_date = null
                }
                this.getRoomContacts()
              } else {
                this.guestDetails = primary_contact != null ? _.cloneDeep(primary_contact) : _.cloneDeep(second_contact)
                this.getRoomContacts()
              }
              // if (idx > -1) {
              //   this.guestDetails = _.cloneDeep(reversedRoomAssignments[idx]);
              //
              //   this.getRoomContacts();
              // }
              if (!this.guestDetails.hasOwnProperty('active')) {
                this.guestDetails.active = true
              }
            }
            if (this.contact && this.contact.contact_id != null &&
              this.guestDetails.checkin_date == null && this.currentHotel.org_type != null &&
              this.not_auto_checkin_date_values.indexOf(this.currentHotel.org_type) != -1
            ) {
              this.guestDetails.checkin_date = moment().toDate()
            }
            this.roomContactsEmitter.emit(this.guestDetails.rooms)
          })
  }

  subscribeToCurrentContact(): void {
    this.contactService.currentContactUpdated.subscribe(
      (key: ContactExport) => {

        if (key == null && this.currentHotel.org_type != null &&
          this.not_auto_checkin_date_values.indexOf(this.currentHotel.org_type) != -1) {
          if (this.guestDetails.checkout_date == null) {
            this.guestDetails.checkout_date = moment().toDate();
          }
        }

        this.contact = new Contact();
        this.guestDetails = new RoomAssignment();
        this.guestNotes = [];
        this.room_contacts = [];

        this.contact = this.contactService.currentContact || new Contact();

        if (key != null) {
          if (key === ContactExport.contact) {
            this.contactService.getById(this.contact.contact_id)
              .then((contact: Contact[]) => {
                if (contact && contact.length > 0) {
                  this.contact = contact[0];

                  this.userPhoneWithMask = this.contact.channel_subscription.resource_identifier.replace(/^.{6}/g, '******');
                  this.userPhoneWithoutMask = this.contact.channel_subscription.resource_identifier;

                  this.guestNotes = this.contact.notes;
                  if (this.guestNotes) {
                    this.sortNotes();
                  }
                  this.getRoomAssignment(this.contact);
                  this.roomContactsEmitter.emit(this.guestDetails.rooms)
                }
              })
              .catch((err) => {
                console.log(err);
              });

          } else {
            this.contact.channel_subscription.resource_identifier = this.contactService.currentNumber;
            this.getRoomAssignment(this.contact);
            this.roomContactsEmitter.emit(this.guestDetails.rooms)
          }
        }
      });
  }

  subscribeToUserUpdate(): void {
    if (this.userService.current_user) {
      // get whatever is there as this component might be initialized after we get the user
      this.user = this.userService.current_user;
    }
    this.userService.getCurrentUserUpdated().subscribe(
      (user: User) => {
        this.user = user;
      });
  }

  subscribeToRoomAssignmentUpdate(): void {

    this.roomassignmentService.roomAssignmentsUpdated.subscribe(
      (roomAssignments: RoomAssignment[]) => {
        this.roomAssignments = roomAssignments;
      });
  }

  private getRoomAttributes(): void {
    if (this.siteService.siteDefaults) {
      this.roomassignmentAttrs = this.siteService.siteDefaults.site_guest_attribute_template;
      if (this.roomassignmentAttrs && this.roomassignmentAttrs.length > 0) {
        for (const attr of this.roomassignmentAttrs) {
          attr.display_name = this.humanReadablePipe.transform(attr.attribute_name);
        }
      }
    }

  }

  // private setDate

  private getRoomAssignments(): void {
    if (!this.roomassignmentService.roomAssignments) {
      this.roomassignmentService.getAll()
        .then((roomAssignments) => {
          this.roomAssignments = roomAssignments;
        });
    } else {
      this.roomAssignments = this.roomassignmentService.roomAssignments;
    }
  }

  getContacts(): void {
    if (this.contactService.contacts != null) {
      this.contacts = this.contactService.contacts;
    } else {
      this.contactService.getAll()
        .then(
          (contacts) => {
            this.contacts = contacts;
          })
        .catch(
          (err) => {
            if (!this.contacts) {
              this.contacts = [];
            }
          });
    }
  }

  getUsers(): void {
    if (this.userService.users) {
      this.users = this.userService.users;
    } else {
      this.userService.getAll()
        .then((users) => {
          this.users = users;
        });
    }
  }

  subscribeToCurrentHotel(): void {
    this.subscriptions.push(
      this.hotelService.hotel$.subscribe(hotel => {
        this.currentHotel = hotel;
        this.roomNumberLabelText = hotel.org_type == 'hotel' ? 'Room Number' : 'Email';
      })
    );
  }

  addToast(message: string, title: string, type: string) {
    // Or create the instance of ToastOptions
    const toastOptions = this.getToastOptions(message, title);
    // // Add see all possible types in one shot
    // this.toastyService.info(toastOptions);
    // this.toastyService.success(toastOptions);
    // this.toastyService.wait(toastOptions);
    // this.toastyService.error(toastOptions);
    // this.toastyService.warning(toastOptions);

    switch (type.toLowerCase()) {
      case 'error':
        this.toastyService.error(toastOptions);
        break;
      case 'success':
        this.toastyService.success(toastOptions);
        break;
    }
  }

  preventClose($event: any): void {
    if ($event) {
      $event.stopPropagation();
      $event.preventDefault();
    }
  }

  beforeAccordionChange($event: any): void {
    if ($event) {
      this.currentActivePanel = $event.nextState ? $event.panelId : '';
    }
  }

  getCurrentOrganization(): void {
    this.subscriptions.push(
      this.hotelService.hotel$
        .subscribe(hotel => {
          this.currentOrganization = hotel;
          const getOrganizationPhone = JSON.parse(JSON.stringify(this.currentOrganization));
          const shortcode = getOrganizationPhone.phone;
            const Url = this.apiUrl+"/shopify";
            const options = this.getOptions();
            const payload = {
              "shortcode": shortcode
            };

            return this.http.post(Url, payload, options)
              .toPromise()
              .then((shopifyObject) => {

                // console.log('@@@@ shopify object - '+ JSON.stringify(shopifyObject))

                this.shopifyObject = JSON.parse(JSON.stringify(shopifyObject));
                // this.maskContact = 
            });

        }, err => {
          console.log(err);
          console.log('user is not logged in, redirecting...');
          this.router.navigate(['login']);
        })
    );
  }

  getMaskContact(): void{
    this.subscriptions.push(
      this.hotelService.hotel$
        .subscribe(hotel => {
          this.currentOrganization = hotel;
          const getOrganizationPhone = JSON.parse(JSON.stringify(this.currentOrganization));
          const shortcode = getOrganizationPhone.phone;
            const Url = this.apiUrl+"/mask";
            const options = this.getOptions();
            const payload = {
              "shortcode": shortcode
            };

            return this.http.post(Url, payload, options)
              .toPromise()
              .then((maskContactObject) => {
              this.maskContactObject = JSON.parse(JSON.stringify(maskContactObject));  
            });
        }, err => {
          console.log(err);
          console.log('user is not logged in, redirecting...');
          this.router.navigate(['login']);
        })
    );
  }

  onShopifyCheckboxChange(event) {
    const checkboxValue = event.target.checked;
    if(checkboxValue === true){
      /** */
      if(confirm("Are you sure you want to update your CRM information with Shopify")){
        const status = 'enable';
        const shopifyValues = event.target.value;
        const breakShopifyValues = shopifyValues.split('+');
        const shopifyAccessToken = breakShopifyValues[0];
        const shopifyStoreAddress = breakShopifyValues[1];
        const userPhoneNumber = breakShopifyValues[2];
        const userContactId = breakShopifyValues[3];
        
        const Url = this.apiUrl+"/shopify/verifyuserinshopify";
        const options = this.getOptions();
        const payload = {
          "status": status,
          "shopifyAccessToken": shopifyAccessToken,
          "shopifyStoreAddress": shopifyStoreAddress,
          "userPhoneNumber": userPhoneNumber,
          "userContactId": userContactId
        };

        if(userPhoneNumber === ''){
          this.addToast('Please select user','','error')
          return false;
        }

        return this.http.post(Url, payload, options)
          .toPromise()
          .then((response: any) => {
            if(response.status === 200){
              const shopifyFirstName = response.userFirstName;
              const shopifyLastName = response.userLastName;
              const shopifyEmail = response.userEmail;
              this.saveDetails(shopifyFirstName, shopifyLastName, shopifyEmail);
            }
            if(response.status === 404){
              this.addToast(response.message,'','success')
            }
        })
      }
      /** */
    }else{
      const status = 'disable';
      const shopifyValues = event.target.value;
      const breakShopifyValues = shopifyValues.split('+');
      const shopifyAccessToken = breakShopifyValues[0];
      const shopifyStoreAddress = breakShopifyValues[1];
      const userPhoneNumber = breakShopifyValues[2];
      const userContactId = breakShopifyValues[3];

      const Url = this.apiUrl+"/shopify/verifyuserinshopify";
      const options = this.getOptions();
      const payload = {
        "status": status,
        "shopifyAccessToken": shopifyAccessToken,
        "shopifyStoreAddress": shopifyStoreAddress,
        "userPhoneNumber": userPhoneNumber,
        "userContactId": userContactId
      };

      if(userPhoneNumber === ''){
        this.addToast('Please select user','','error')
        return false;
      }

      return this.http.post(Url, payload, options)
        .toPromise()
        .then((response: any) => {
          console.log('RESPONSE - '+ JSON.stringify(response))
        if(response.status === 200){
          this.addToast(response.message,'','success')
        }
      })
    }
  }

  get08Object(): void{
    this.subscriptions.push(
      this.hotelService.hotel$
        .subscribe(hotel => {
          this.currentOrganization = hotel;
          const getOrganizationPhone = JSON.parse(JSON.stringify(this.currentOrganization));
          const shortcode = getOrganizationPhone.phone;
            const Url = this.apiUrl+"/O8";
            const options = this.getOptions();
            const payload = {
              "shortcode": shortcode
            };
            return this.http.post(Url, payload, options)
              .toPromise()
              .then((result) => {
              const O8Object = JSON.parse(JSON.stringify(result));
              if(O8Object.Items[0].ActiveCampaign){
                this.acEnabled = O8Object.Items[0].ActiveCampaign.enabled;
                this.acBaseURL = O8Object.Items[0].ActiveCampaign.BaseURL;
                this.acAPIKey = O8Object.Items[0].ActiveCampaign.APIKey;
              }
            });
        }, err => {
          console.log(err);
          console.log('user is not logged in, redirecting...');
          this.router.navigate(['login']);
        })
    );
  }

  /**
   * 
   * @param baseURL 
   * @param apiKey 
   */
  syncWithActiveCampaign(baseURL: string, apiKey: string): void{
    this.pleaseWait = true;
    var phone = this.contact.channel_subscription.resource_identifier;
    const contact_id = this.contact.contact_id; /** */
    const options = this.getOptions();
    const URL = this.apiUrl+'/active-campaign/search-contact'
    this.http.post(URL,{
      baseURL: baseURL,
      apiKey: apiKey,
      phone: phone,
      email: null,
      contact_id: contact_id
    }, options).subscribe(
      (response) => {
        const response1 = JSON.parse(JSON.stringify(response));
        if(response1.body.activeCampaignIdFound === false){
          this.pleaseWait = false;
          this.addToast(response1.body.message, '', 'error')
        }else{
          const response2 = JSON.parse(JSON.stringify(response));
          const response3 = JSON.parse(response2.body.contactData);
          const firstName = response3.contacts[0].firstName;
          const lastName = response3.contacts[0].lastName;
          const email = response3.contacts[0].email;
      
          const dialogRef = this.dialog.open(SyncActiveCampaign, {
            hasBackdrop: true,
          });

          dialogRef.afterClosed().subscribe(result => {
            console.log(result);
            if(result == true){
              this.saveDetails(firstName, lastName, email);
              this.pleaseWait = false;
            }
            this.pleaseWait = false;
          });
        }
      },
      (error) => {
        this.pleaseWait = false;
        console.error('Error occurred while making POST request:', error);
      }
    );
  }

  ngOnInit() {
    this.checkin_date = new Date();
    this.editDetails = false;
    this.guestDetails = new RoomAssignment();
    this.subscriptions = [];
    this.subscribeToCurrentContact();
    this.getRoomAttributes();
    this.subscribeToUserUpdate();
    this.subscribeToCurrentHotel();
    this.getLanguages()
      .then((languages) => {
        this.languages = languages;
      }).catch((error) => {
        console.log(error);
      });
    this.getRoomAssignments();
    this.subscribeToRoomAssignmentUpdate();
    this.getContacts();
    this.getUsers();
    this.getCurrentOrganization();
    this.getMaskContact();
    this.get08Object();
    this.operatorViewService.currentStreamIdUpdated.subscribe((stream: Stream) => {
      this.stream_id = stream.stream_id
      this.importantFlag = stream.importantFlag      
    });
  }
 makeImportant() { 
    if (this.importantFlag)
    {
      this.importantFlag  = false;
    }
    else 
    {
      this.importantFlag = true;
    }
  
    const params = {
      "stream_id" :this.stream_id,
      "status" : this.importantFlag
    }
    this.operatorService.updateImportantFlagStatus(params).then( (res : any)=>{
          this.addToast("Successfully updated", '', 'success')
      },
      (err)=>{
        this.addToast("failed to updated", '', 'error')
      }
    )
  }
  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length > 0) {
      for (const sub of this.subscriptions) {
        sub.unsubscribe();
      }
    }
  }

}
