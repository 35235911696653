import { Component, Inject, OnInit } from '@angular/core';
import { OperatorService } from '../core/services/operator.service';
import { TokenService } from '../core/services/token.service';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
  status: boolean;
}

@Component({
  selector: 'app-logout-popup',
  templateUrl: './logout-popup.component.html',
  styleUrls: ['./logout-popup.component.css']
})
export class LogoutPopupComponent {

  constructor(
    private operatorService: OperatorService,
    private tokenService: TokenService,
    private router: Router,
    public dialogRef: MatDialogRef<LogoutPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  // private logout(): void {
  //   event.preventDefault();
  //   this.operatorService.logout()
  //     .then(
  //       (res) => {
  //         if (res) {
  //           this.tokenService.remove();
  //           this.router.navigate(['login']);
  //           return;
  //         }
  //       })
  //     .catch((err) => {
  //       this.router.navigate(['login']);
  //     });
  // }
  //
  // onClick(event: any): void {
  //   this.logout()
  // }

}
